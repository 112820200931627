import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Row } from "../../../Components/UtilComponents.fs.js";
import { equals, int32ToString, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import Modal from "@material-ui/core/Modal";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { PlaceDescriptionAppointmentId, AppointmentStatus, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { ErrorText, AppointmentStatusChip } from "../../../DesignSystems/DesignSystem.fs.js";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { toString } from "../fable_modules/fable-library.3.7.17/Date.js";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";
import Close from "@material-ui/icons/Close";
import { Page } from "../components/Page.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { placeDescriptionAppointments } from "../AppRoutes.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function InfoLink(secondary, primary, url) {
    let elems;
    const children = singleton(createElement(Row, {
        classes: empty(),
        children: singleton(createElement("div", createObj(singleton((elems = [createElement("a", {
            target: "_blank",
            href: `${RouterModule_encodeParts(ofArray(url), 1)}`,
            children: `${primary}`,
        }), MuiHelpers_createElement(ListItemText, [["secondary", secondary]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    }));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function CancellationModal(cancellationModalInputProps) {
    let elems_1;
    const onCancelAppointment = cancellationModalInputProps.onCancelAppointment;
    const setShowCancellationModal = cancellationModalInputProps.setShowCancellationModal;
    const showCancellationModal = cancellationModalInputProps.showCancellationModal;
    const appointmentId = cancellationModalInputProps.appointmentId;
    return MuiHelpers_createElement(Modal, [["open", showCancellationModal], ["onClose", (_arg_1, v) => {
        setShowCancellationModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", {
        className: join(" ", [MarginBottom.M]),
        children: Interop_reactApi.Children.toArray(["Sure to cancel this appointment?"]),
    }), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onCancelAppointment(appointmentId);
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Yes"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowCancellationModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "No"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function DisplayAppointmentInfo(displayAppointmentInfoInputProps) {
    let elems_6, children_2, children_1, children, elems, children_4, children_3, props_9;
    const id = displayAppointmentInfoInputProps.id;
    const auth0User = displayAppointmentInfoInputProps.auth0User;
    const details = displayAppointmentInfoInputProps.details;
    const currentPage = displayAppointmentInfoInputProps.currentPage;
    const api = displayAppointmentInfoInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowCancellationModal = patternInput[1];
    let adiName;
    const firstName = NonEmptyString___UnsafeUnwrap_Z73AD07C(details.AdiFirstName);
    const lastName = NonEmptyString___UnsafeUnwrap_Z73AD07C(details.AdiLastName);
    adiName = (`${firstName} ${lastName}`);
    const appointmentInfo = createElement("div", createObj(singleton((elems_6 = [(children_2 = singleton((children_1 = singleton((children = singleton(createElement(Row, {
        classes: empty(),
        children: singleton(createElement("div", createObj(singleton((elems = [createElement(AppointmentStatusChip, {
            status: details.Appointment.Status,
        }), MuiHelpers_createElement(ListItemText, [["secondary", "Status"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    })), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), createElement("h2", {
        children: ["Gegevens"],
    }), (children_4 = singleton((children_3 = ofArray([createElement(Info, {
        secondary: "Afspraak nummer",
        primary: int32ToString(details.Appointment.Id.Value),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Datum",
        primary: toString(details.Appointment.StartTime, "dd/MM/yyyy"),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Start tijd",
        primary: toString(details.Appointment.StartTime, "HH:mm"),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Eind tijd",
        primary: toString(details.Appointment.EndTime, "HH:mm"),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "ADI",
        primary: adiName,
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Note",
        primary: details.Appointment.Note,
    })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))));
    const cancelAppointmentButton = Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        setShowCancellationModal(true);
    }], ["className", join(" ", [MarginLeft.Xs])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_9 = {}, react.createElement(Close, props_9))], ["children", "Annuleren"], ["disabled", equals(details.Appointment.Status, new AppointmentStatus(3))]]) : null;
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [cancelAppointmentButton],
        children: [appointmentInfo, createElement(CancellationModal, {
            appointmentId: details.Appointment.Id,
            showCancellationModal: patternInput[0],
            setShowCancellationModal: setShowCancellationModal,
            onCancelAppointment: (appointmentId) => {
                startImmediate(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
                    toConsole(printf("cancel %A"))(appointmentId);
                    return singleton_1.Bind(api.CancellPlaceDescriptionAppointment(appointmentId), (_arg) => {
                        const result = _arg;
                        if (result.tag === 0) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Afspraak geannuleerd", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(placeDescriptionAppointments)), 1, 1);
                            return singleton_1.Zero();
                        }
                        else {
                            const err = result.fields[0];
                            console.error(err);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton_1.Zero();
                        }
                    });
                }), (_arg_1) => {
                    const e = _arg_1;
                    console.error(e);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton_1.Zero();
                })));
            },
        })],
    });
}

export function AppointmentDetail(appointmentDetailInputProps) {
    const id = appointmentDetailInputProps.id;
    const auth0User = appointmentDetailInputProps.auth0User;
    const api = appointmentDetailInputProps.api;
    const currentPage = appointmentDetailInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPlaceDescriptionAppointmentDetails(new PlaceDescriptionAppointmentId(id)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (aptDetails) => {
            if (aptDetails == null) {
                return createElement(Page, {
                    currentPage: currentPage,
                    title: "",
                    actions: [],
                    children: [createElement(ErrorText, {
                        text: `No appointments found with ID ${id}`,
                    })],
                });
            }
            else {
                const aptDetails_1 = aptDetails;
                return createElement(DisplayAppointmentInfo, {
                    api: api,
                    currentPage: currentPage,
                    details: aptDetails_1,
                    auth0User: auth0User,
                    id: id,
                });
            }
        },
    });
}

