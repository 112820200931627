import { getSlice, append, choose, reverse, head, item, length, skip as skip_1, take as take_1, empty, tail, isEmpty, allPairs, map, collect, cons as cons_1, singleton as singleton_1 } from "../../fable-library.3.7.17/List.js";
import { map as map_1, toList } from "../../fable-library.3.7.17/Seq.js";
import { replace as replace_1, split as split_1, intersperse as intersperse_1, intercalate as intercalate_1 } from "./Seq.fs.js";
import { toIterator, getEnumerator } from "../../fable-library.3.7.17/Util.js";
import { FindSliceIndex_listImpl } from "../Internals.fs.js";
import { FSharpRef } from "../../fable-library.3.7.17/Types.js";

export function singleton(value) {
    return singleton_1(value);
}

export function cons(value, list) {
    return cons_1(value, list);
}

export function apply(f, x) {
    return collect((f_1) => map(f_1, x), f);
}

export function lift2(f, x1, x2) {
    return map((tupledArg) => f(tupledArg[0], tupledArg[1]), allPairs(x1, x2));
}

export function lift3(f, x1, x2, x3) {
    return map((tupledArg) => f(tupledArg[0], tupledArg[1], tupledArg[2]), map((x) => [x[1][0], x[1][1], x[0]], allPairs(x1, allPairs(x2, x3))));
}

export function tails(list) {
    const loop = (_arg) => {
        if (!isEmpty(_arg)) {
            return cons_1(_arg, loop(tail(_arg)));
        }
        else {
            return empty();
        }
    };
    return loop(list);
}

export function take(count, list) {
    return take_1(count, list);
}

export function skip(count, list) {
    return skip_1(count, list);
}

export function drop(count, source) {
    const loop = (i_mut, lst_mut) => {
        loop:
        while (true) {
            const i = i_mut, lst = lst_mut;
            const matchValue = [lst, i];
            let pattern_matching_result, x;
            if (isEmpty(matchValue[0])) {
                pattern_matching_result = 0;
                x = matchValue[0];
            }
            else if (matchValue[1] === 0) {
                pattern_matching_result = 0;
                x = matchValue[0];
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return x;
                }
                case 1: {
                    i_mut = (matchValue[1] - 1);
                    lst_mut = tail(matchValue[0]);
                    continue loop;
                }
            }
            break;
        }
    };
    if (count > 0) {
        return loop(count, source);
    }
    else {
        return source;
    }
}

export function intercalate(separator, source) {
    return toList(intercalate_1(separator, source));
}

export function intersperse(separator, source) {
    return toList(intersperse_1(separator, source));
}

export function split(separators, source) {
    return map_1(toList, split_1(separators, source));
}

export function replace(oldValue, newValue, source) {
    return toList(replace_1(oldValue, newValue, source));
}

export function toIReadOnlyList(source) {
    return {
        ["System.Collections.Generic.IReadOnlyCollection`1.get_Count"]() {
            return length(source);
        },
        ["System.Collections.Generic.IReadOnlyList`1.get_ItemZ524259A4"](index) {
            return item(index, source);
        },
        GetEnumerator() {
            return getEnumerator(source);
        },
        [Symbol.iterator]() {
            return toIterator(this.GetEnumerator());
        },
        ["System.Collections.IEnumerable.GetEnumerator"]() {
            return getEnumerator(source);
        },
    };
}

export function findSliceIndex(slice, source) {
    const index = FindSliceIndex_listImpl(slice, source) | 0;
    if (index === -1) {
        const exn = new Error("The specified slice was not found in the sequence.");
        throw exn;
    }
    else {
        return index | 0;
    }
}

export function tryFindSliceIndex(slice, source) {
    const index = FindSliceIndex_listImpl(slice, source) | 0;
    if (index === -1) {
        return void 0;
    }
    else {
        return index;
    }
}

export function partitionMap(mapping, source) {
    const loop = (acc) => {
        const acc_1 = acc;
        const acc2 = acc_1[1];
        const acc1 = acc_1[0];
        return (_arg) => {
            if (!isEmpty(_arg)) {
                const xs = tail(_arg);
                const matchValue = mapping(head(_arg));
                return (matchValue.tag === 1) ? loop([acc1, cons_1(matchValue.fields[0], acc2)])(xs) : loop([cons_1(matchValue.fields[0], acc1), acc2])(xs);
            }
            else {
                return acc_1;
            }
        };
    };
    return loop([empty(), empty()])(reverse(source));
}

export function map2Shortest(mapping, list1, list2) {
    const loop = (acc_mut, _arg_mut) => {
        loop:
        while (true) {
            const acc = acc_mut, _arg = _arg_mut;
            let pattern_matching_result, l, ls, r, rs;
            if (!isEmpty(_arg[0])) {
                if (!isEmpty(_arg[1])) {
                    pattern_matching_result = 0;
                    l = head(_arg[0]);
                    ls = tail(_arg[0]);
                    r = head(_arg[1]);
                    rs = tail(_arg[1]);
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    acc_mut = cons_1(mapping(l, r), acc);
                    _arg_mut = [ls, rs];
                    continue loop;
                }
                case 1: {
                    return acc;
                }
            }
            break;
        }
    };
    return reverse(loop(empty(), [list1, list2]));
}

export function zipShortest(list1, list2) {
    const loop = (acc_mut, _arg_mut) => {
        loop:
        while (true) {
            const acc = acc_mut, _arg = _arg_mut;
            let pattern_matching_result, l, ls, r, rs;
            if (!isEmpty(_arg[0])) {
                if (!isEmpty(_arg[1])) {
                    pattern_matching_result = 0;
                    l = head(_arg[0]);
                    ls = tail(_arg[0]);
                    r = head(_arg[1]);
                    rs = tail(_arg[1]);
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    acc_mut = cons_1([l, r], acc);
                    _arg_mut = [ls, rs];
                    continue loop;
                }
                case 1: {
                    return acc;
                }
            }
            break;
        }
    };
    return reverse(loop(empty(), [list1, list2]));
}

export function choosei(mapping, source) {
    let i = new FSharpRef(-1);
    return choose((x) => {
        i.contents = ((i.contents + 1) | 0);
        return mapping(i.contents, x);
    }, source);
}

export function deleteAt(i, lst) {
    if (length(lst) > i) {
        return append(getSlice(0, i - 1, lst), getSlice(i + 1, void 0, lst));
    }
    else {
        return lst;
    }
}

export function removeAt(i, lst) {
    return deleteAt(i, lst);
}

export function setAt(i, x, lst) {
    if ((length(lst) > i) && (i >= 0)) {
        return append(getSlice(0, i - 1, lst), cons_1(x, getSlice(i + 1, void 0, lst)));
    }
    else {
        return lst;
    }
}

