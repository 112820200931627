import { list_type, union_type, class_type } from "../fable-library.3.7.17/Reflection.js";
import { equals, toIterator, compare as compare_1, disposeSafe, getEnumerator, sign } from "../fable-library.3.7.17/Util.js";
import { fromZero, op_Multiply, fromOne, pow, fromInt32, op_Subtraction, abs, compare, op_Division, op_Addition, op_RightShift, toInt32 } from "../fable-library.3.7.17/BigInt.js";
import { FSharpResult$2 } from "../fable-library.3.7.17/Choice.js";
import { FSharpException, Record, Union } from "../fable-library.3.7.17/Types.js";
import { fill, singleton } from "../fable-library.3.7.17/Array.js";
import { Operators_NullArg } from "../fable-library.3.7.17/FSharp.Core.js";
import { rangeDouble } from "../fable-library.3.7.17/Range.js";
import { empty as empty_1, compareWith } from "../fable-library.3.7.17/Seq.js";
import { length as length_1, append, isEmpty, tail, head, reverse, cons, empty } from "../fable-library.3.7.17/List.js";
import { value as value_1, some } from "../fable-library.3.7.17/Option.js";

export class Default6 {
    constructor() {
    }
}

export function Default6$reflection() {
    return class_type("FSharpPlus.Internals.Default6", void 0, Default6);
}

export class Default5 {
    constructor() {
    }
}

export function Default5$reflection() {
    return class_type("FSharpPlus.Internals.Default5", void 0, Default5, Default6$reflection());
}

export class Default4 {
    constructor() {
    }
}

export function Default4$reflection() {
    return class_type("FSharpPlus.Internals.Default4", void 0, Default4, Default5$reflection());
}

export class Default3 {
    constructor() {
    }
}

export function Default3$reflection() {
    return class_type("FSharpPlus.Internals.Default3", void 0, Default3, Default4$reflection());
}

export class Default2 {
    constructor() {
    }
}

export function Default2$reflection() {
    return class_type("FSharpPlus.Internals.Default2", void 0, Default2, Default3$reflection());
}

export class Default1 {
    constructor() {
    }
}

export function Default1$reflection() {
    return class_type("FSharpPlus.Internals.Default1", void 0, Default1, Default2$reflection());
}

export const Errors_exnDivByZero = new Error();

export const Errors_exnNoDivision = new Error("These numbers are not divisible in this domain.");

export const Errors_exnSqrtOfNegative = new Error("Cannot calculate square root of a negative number");

export const Errors_exnNoSqrt = new Error("No square root defined for this value in this domain.");

export const Errors_exnNoSubtraction = new Error("No subtraction defined for these values in this domain.");

export const Errors_exnUnreachable = new Error("This execution path is unreachable.");

export function BigInteger_trySqrtRem(x) {
    if (sign(toInt32(x)) === -1) {
        return new FSharpResult$2(1, Errors_exnSqrtOfNegative);
    }
    else {
        const loop = (previous_mut) => {
            loop:
            while (true) {
                const previous = previous_mut;
                const current = op_RightShift(op_Addition(previous, op_Division(x, previous)), 1);
                if (compare(abs(op_Subtraction(previous, current)), fromInt32(2)) < 0) {
                    return current;
                }
                else {
                    previous_mut = current;
                    continue loop;
                }
                break;
            }
        };
        const r = loop(pow(fromInt32(10), (toInt32(op_Addition(x, fromOne())) + 1) >> 1));
        const r2 = op_Multiply(r, r);
        const matchValue = compare(r2, x) | 0;
        switch (matchValue) {
            case 0: {
                return new FSharpResult$2(0, [r, fromZero()]);
            }
            case 1: {
                const root = op_Subtraction(r, fromOne());
                return new FSharpResult$2(0, [root, op_Subtraction(x, op_Multiply(root, root))]);
            }
            default: {
                return new FSharpResult$2(0, [r, op_Subtraction(x, r2)]);
            }
        }
    }
}

export class Id$1 {
    constructor(v) {
        this.value = v;
    }
}

export function Id$1$reflection(gen0) {
    return class_type("FSharpPlus.Internals.Id`1", [gen0], Id$1);
}

export function Id$1_$ctor_2B595(v) {
    return new Id$1(v);
}

export function Id$1__get_getValue(_) {
    return _.value;
}

export function Id_run(x) {
    return Id$1__get_getValue(x);
}

export function Id_map(f, x) {
    return Id$1_$ctor_2B595(f(Id$1__get_getValue(x)));
}

export function Id_create(x) {
    return Id$1_$ctor_2B595(x);
}

export class Id0 {
    constructor(v) {
        this.value = v;
    }
}

export function Id0$reflection() {
    return class_type("FSharpPlus.Internals.Id0", void 0, Id0);
}

export function Id0_$ctor_Z721C83C5(v) {
    return new Id0(v);
}

export function Id0__get_getValue(_) {
    return _.value;
}

export class Either$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Left", "Right"];
    }
}

export function Either$2$reflection(gen0, gen1) {
    return union_type("FSharpPlus.Internals.Either`2", [gen0, gen1], Either$2, () => [[["Item", gen0]], [["Item", gen1]]]);
}

export class DmStruct extends Record {
    constructor() {
        super();
    }
}

export function DmStruct$reflection() {
    return class_type("FSharpPlus.Internals.DmStruct", void 0, DmStruct, class_type("System.ValueType"));
}

export class Set2$1 {
    constructor() {
    }
}

export function Set2$1$reflection(gen0) {
    return class_type("FSharpPlus.Internals.Set2`1", [gen0], Set2$1);
}

export function Set2$1_$ctor() {
    return new Set2$1();
}

export class BitConverter {
    constructor() {
    }
}

export function BitConverter$reflection() {
    return class_type("FSharpPlus.Internals.BitConverter", void 0, BitConverter);
}

export function BitConverter_GetBytes_Z1FBCCD16(value) {
    return singleton(value ? 1 : 0, Uint8Array);
}

function BitConverter_GetHexValue_Z524259A4(i) {
    if (i < 10) {
        return String.fromCharCode(String.fromCharCode(i).charCodeAt(0) + 48);
    }
    else {
        return String.fromCharCode(String.fromCharCode(i - 10).charCodeAt(0) + 65);
    }
}

export function BitConverter_ToString_1D3E19A2(value, startIndex, length) {
    if (value == null) {
        Operators_NullArg("value");
    }
    const arrayLen = value.length | 0;
    if (startIndex >= value.length) {
        const exn = new Error("startIndex", "ArgumentOutOfRange_StartIndex");
        throw exn;
    }
    const realLength = length | 0;
    if (realLength < 0) {
        const exn_1 = new Error("length", "ArgumentOutOfRange_GenericPositive");
        throw exn_1;
    }
    if (startIndex > (arrayLen - realLength)) {
        const exn_2 = new Error("Arg_ArrayPlusOffTooSmall");
        throw exn_2;
    }
    if (realLength === 0) {
        return "";
    }
    else {
        const chArray = fill(new Array(realLength * 3), 0, realLength * 3, "");
        let index = startIndex;
        const enumerator = getEnumerator(rangeDouble(0, 3, (3 * realLength) - 1));
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const i = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]() | 0;
                const b = (~(~value[index])) | 0;
                index = ((index + 1) | 0);
                chArray[i] = BitConverter_GetHexValue_Z524259A4(~(~(b / 16)));
                chArray[i + 1] = BitConverter_GetHexValue_Z524259A4(b % 16);
                chArray[i + 2] = "-";
            }
        }
        finally {
            disposeSafe(enumerator);
        }
        return chArray.join('').substr(0, (chArray.length - 1));
    }
}

export function BitConverter_ToString_6C95DA22(value) {
    if (value == null) {
        Operators_NullArg("value");
    }
    return BitConverter_ToString_1D3E19A2(value, 0, value.length);
}

export function BitConverter_ToString_52ECB83E(value, startIndex) {
    if (value == null) {
        Operators_NullArg("value");
    }
    return BitConverter_ToString_1D3E19A2(value, startIndex, value.length - startIndex);
}

export function FindSliceIndex_sequenceEqual(a, b) {
    return compareWith(compare_1, a, b) === 0;
}

export class FindSliceIndex_Q_queue$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Queue"];
    }
}

export function FindSliceIndex_Q_queue$1$reflection(gen0) {
    return union_type("FSharpPlus.Internals.FindSliceIndex.Q.queue`1", [gen0], FindSliceIndex_Q_queue$1, () => [[["Item1", list_type(gen0)], ["Item2", list_type(gen0)]]]);
}

export function FindSliceIndex_Q_empty() {
    return new FindSliceIndex_Q_queue$1(0, empty(), empty());
}

export function FindSliceIndex_Q_enqueue(q, e) {
    return new FindSliceIndex_Q_queue$1(0, cons(e, q.fields[0]), q.fields[1]);
}

export function FindSliceIndex_Q_dequeue(_arg) {
    let pattern_matching_result, b, bs, fs;
    if (isEmpty(_arg.fields[0])) {
        if (!isEmpty(_arg.fields[1])) {
            pattern_matching_result = 1;
            b = head(_arg.fields[1]);
            bs = tail(_arg.fields[1]);
            fs = _arg.fields[0];
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (isEmpty(_arg.fields[1])) {
        pattern_matching_result = 2;
    }
    else {
        pattern_matching_result = 1;
        b = head(_arg.fields[1]);
        bs = tail(_arg.fields[1]);
        fs = _arg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return [void 0, _arg];
        }
        case 1: {
            return [some(b), new FindSliceIndex_Q_queue$1(0, fs, bs)];
        }
        case 2: {
            const bs_1 = reverse(_arg.fields[0]);
            return [some(head(bs_1)), new FindSliceIndex_Q_queue$1(0, empty(), tail(bs_1))];
        }
    }
}

export function FindSliceIndex_Q_toSeq(_arg) {
    let pattern_matching_result, bs, fs;
    if (isEmpty(_arg.fields[0])) {
        if (isEmpty(_arg.fields[1])) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
            bs = _arg.fields[1];
            fs = _arg.fields[0];
        }
    }
    else {
        pattern_matching_result = 1;
        bs = _arg.fields[1];
        fs = _arg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return empty_1();
        }
        case 1: {
            return append(bs, reverse(fs));
        }
    }
}

export function FindSliceIndex_Q_length(_arg) {
    let pattern_matching_result, bs, fs;
    if (isEmpty(_arg.fields[0])) {
        if (isEmpty(_arg.fields[1])) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
            bs = _arg.fields[1];
            fs = _arg.fields[0];
        }
    }
    else {
        pattern_matching_result = 1;
        bs = _arg.fields[1];
        fs = _arg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return 0;
        }
        case 1: {
            return (length_1(bs) + length_1(fs)) | 0;
        }
    }
}

export class FindSliceIndex_Queue$1 {
    constructor() {
        this.q = FindSliceIndex_Q_empty();
    }
    GetEnumerator() {
        const _ = this;
        return getEnumerator(FindSliceIndex_Q_toSeq(_.q));
    }
    [Symbol.iterator]() {
        return toIterator(this.GetEnumerator());
    }
    ["System.Collections.IEnumerable.GetEnumerator"]() {
        const _ = this;
        return getEnumerator(FindSliceIndex_Q_toSeq(_.q));
    }
}

export function FindSliceIndex_Queue$1$reflection(gen0) {
    return class_type("FSharpPlus.Internals.FindSliceIndex.Queue`1", [gen0], FindSliceIndex_Queue$1);
}

export function FindSliceIndex_Queue$1_$ctor() {
    return new FindSliceIndex_Queue$1();
}

export function FindSliceIndex_Queue$1__Enqueue_2B595(_, v) {
    _.q = FindSliceIndex_Q_enqueue(_.q, v);
}

export function FindSliceIndex_Queue$1__Dequeue(_) {
    const patternInput = FindSliceIndex_Q_dequeue(_.q);
    const dequeued = patternInput[0];
    _.q = patternInput[1];
    if (dequeued == null) {
        throw (new Error("Empty queue!"));
    }
    else {
        return value_1(dequeued);
    }
}

export function FindSliceIndex_Queue$1__get_Count(_) {
    return FindSliceIndex_Q_length(_.q);
}

export function FindSliceIndex_listImpl(slice, source) {
    const cache = FindSliceIndex_Queue$1_$ctor();
    const sliceLength = length_1(slice) | 0;
    const go = (index_mut, source_1_mut) => {
        go:
        while (true) {
            const index = index_mut, source_1 = source_1_mut;
            if (isEmpty(source_1)) {
                return -1;
            }
            else {
                const t = tail(source_1);
                FindSliceIndex_Queue$1__Enqueue_2B595(cache, head(source_1));
                if (FindSliceIndex_Queue$1__get_Count(cache) === sliceLength) {
                    if (FindSliceIndex_sequenceEqual(cache, slice)) {
                        return ((index - sliceLength) + 1) | 0;
                    }
                    else {
                        FindSliceIndex_Queue$1__Dequeue(cache);
                        index_mut = (index + 1);
                        source_1_mut = t;
                        continue go;
                    }
                }
                else {
                    index_mut = (index + 1);
                    source_1_mut = t;
                    continue go;
                }
            }
            break;
        }
    };
    return go(0, source) | 0;
}

export function FindSliceIndex_arrayImpl(slice, source) {
    const cache = FindSliceIndex_Queue$1_$ctor();
    const go = (index_mut) => {
        go:
        while (true) {
            const index = index_mut;
            if (index < source.length) {
                FindSliceIndex_Queue$1__Enqueue_2B595(cache, source[index]);
                if (FindSliceIndex_Queue$1__get_Count(cache) === slice.length) {
                    if (FindSliceIndex_sequenceEqual(cache, slice)) {
                        return ((index - slice.length) + 1) | 0;
                    }
                    else {
                        FindSliceIndex_Queue$1__Dequeue(cache);
                        index_mut = (index + 1);
                        continue go;
                    }
                }
                else {
                    index_mut = (index + 1);
                    continue go;
                }
            }
            else {
                return -1;
            }
            break;
        }
    };
    return go(0) | 0;
}

export class AggregateException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function AggregateException$reflection() {
    return class_type("FSharpPlus.Internals.AggregateException", void 0, AggregateException, class_type("System.Exception"));
}

function AggregateException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof AggregateException) {
                return equals(this$.Data0, obj.Data0);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

