import { toString, Union } from "../fable-library.3.7.17/Types.js";
import { ImportantMaster, MasterTypeHelpers_stringifyICounterValue, CombinatorMaster__Unwrap, MasterTypeHelpers_stringifyICssValue, NameLabel, CombinatorMaster, Pseudo, Property_CssProperty$reflection } from "./Types/MasterTypes.fs.js";
import { union_type } from "../fable-library.3.7.17/Reflection.js";
import { Media_MediaQuery } from "./Types/Media.fs.js";
import { toList } from "../fable-library.3.7.17/Seq.js";
import { printf, toText, join, split } from "../fable-library.3.7.17/String.js";
import { fold, tryHead, tryFind, empty, reverse, append, collect, singleton, filter, map, head, tail, isEmpty } from "../fable-library.3.7.17/List.js";
import { equalArrays, equals } from "../fable-library.3.7.17/Util.js";
import { FNV_1A_hash } from "./Utilities.fs.js";
import { FontFaceClasses_FontFamily__string_Z721C83C5 } from "./Types/FontFace.fs.js";
import { FontFamily } from "./css/FontFace.fs.js";
import { colorHelpers_hex, Color_Color } from "./Types/Color.fs.js";
import { Fraction, Time, Percent, Angle, Length } from "./Types/Units.fs.js";
import { Font_SettingSwitch } from "./Types/Font.fs.js";

class PropertyType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Main", "Pseudo", "Media", "Combinator"];
    }
}

function PropertyType$reflection() {
    return union_type("Fss.Functions.PropertyType", [], PropertyType, () => [[], [["Item", Property_CssProperty$reflection()]], [["Item", Property_CssProperty$reflection()]], [["Item", Property_CssProperty$reflection()]]]);
}

function isSecondary(_arg, value) {
    if (value instanceof Pseudo) {
        return true;
    }
    else if (value instanceof CombinatorMaster) {
        return true;
    }
    else if (value instanceof Media_MediaQuery) {
        return true;
    }
    else {
        return false;
    }
}

function isCombinator(_arg, value) {
    if (value instanceof CombinatorMaster) {
        return true;
    }
    else {
        return false;
    }
}

function isMedia(_arg, value) {
    if (value instanceof Media_MediaQuery) {
        return true;
    }
    else {
        return false;
    }
}

function isPseudo(_arg, value) {
    if (value instanceof Pseudo) {
        return true;
    }
    else {
        return false;
    }
}

function isLabel(_arg, value) {
    if (value instanceof NameLabel) {
        return true;
    }
    else {
        return false;
    }
}

function isCounterLabel(_arg, value) {
    if (value instanceof NameLabel) {
        return true;
    }
    else {
        return false;
    }
}

function addBrackets(string) {
    return `{ ${string} }`;
}

function addClassName(className, css, value) {
    if (css.indexOf("@media") >= 0) {
        const patternInput = toList(split(css, ["@"], null, 0));
        let pattern_matching_result, css_1, name;
        if (!isEmpty(patternInput)) {
            if (!isEmpty(tail(patternInput))) {
                pattern_matching_result = 0;
                css_1 = head(tail(patternInput));
                name = head(patternInput);
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [`@${css_1}`, `{ ${className}${name} ${value.slice(1, value.length).trim()}`];
            }
            case 1: {
                throw (new Error("Match failure: Microsoft.FSharp.Collections.FSharpList`1"));
            }
        }
    }
    else {
        return [`${className}${css}`, value];
    }
}

function createMainCssString(propertyName, propertyValue) {
    return [MasterTypeHelpers_stringifyICssValue(propertyName), MasterTypeHelpers_stringifyICssValue(propertyValue)];
}

function createMainCSS(properties) {
    let arg_1;
    return addBrackets((arg_1 = join(";", map((tupledArg_2) => (`${tupledArg_2[0]}: ${tupledArg_2[1]}`), map((tupledArg_1) => createMainCssString(tupledArg_1[0], tupledArg_1[1]), filter((arg) => {
        let tupledArg;
        return !((tupledArg = arg, isLabel(tupledArg[0], tupledArg[1])));
    }, properties)))), toText(printf("%s;"))(arg_1)));
}

function createPseudoCssString(propertyName, propertyValue) {
    const colons = (propertyValue instanceof Pseudo) ? ((propertyValue.tag === 1) ? "::" : ":") : "";
    return [`${colons}${MasterTypeHelpers_stringifyICssValue(propertyName)}`, `{ ${MasterTypeHelpers_stringifyICssValue(propertyValue)}; }`];
}

function createPseudoCss(properties) {
    return map((tupledArg_1) => [`${tupledArg_1[0]}`, `${tupledArg_1[1]}`], map((tupledArg) => createPseudoCssString(tupledArg[0], tupledArg[1]), properties));
}

function createMediaCssString(className, _arg, propertyValue) {
    const stringifyMedia = (features, rules) => [join(" and ", map((x) => {
        let copyOfStruct;
        return `(${((copyOfStruct = x, toString(copyOfStruct)))})`;
    }, features)), addBrackets(join("", map((tupledArg) => (`${tupledArg[0]} ${tupledArg[1]}`), createFssInternal(className, rules)[1])))];
    if (propertyValue instanceof Media_MediaQuery) {
        if (propertyValue.tag === 1) {
            const features_4 = propertyValue.fields[1];
            const device_1 = MasterTypeHelpers_stringifyICssValue(propertyValue.fields[0]);
            const patternInput_2 = stringifyMedia(features_4, propertyValue.fields[2]);
            const featureString_1 = (!isEmpty(features_4)) ? (`and ${patternInput_2[0]}`) : "";
            return [`@media ${device_1} ${featureString_1}`, patternInput_2[1]];
        }
        else {
            const patternInput_1 = stringifyMedia(propertyValue.fields[0], propertyValue.fields[1]);
            return [`@media ${patternInput_1[0]}`, patternInput_1[1]];
        }
    }
    else {
        return ["", ""];
    }
}

export function createMediaCss(className, properties) {
    return map((m) => createMediaCssString(className, m[0], m[1]), properties);
}

function createCombinatorCssString(propertyName, propertyValue) {
    if (propertyValue instanceof CombinatorMaster) {
        return map((tupledArg) => [`${MasterTypeHelpers_stringifyICssValue(propertyName)}${tupledArg[0]}`, tupledArg[1]], createFssInternal("", CombinatorMaster__Unwrap(propertyValue))[1]);
    }
    else {
        return singleton(["", ""]);
    }
}

function createCombinatorCss(properties) {
    return collect((m) => createCombinatorCssString(m[0], m[1]), properties);
}

function arrangePropertyLists(properties) {
    const arrangePropertyLists_1 = (currentProperties_mut, previousPropertyType_mut, currentPropertyTypes_mut) => {
        arrangePropertyLists_1:
        while (true) {
            const currentProperties = currentProperties_mut, previousPropertyType = previousPropertyType_mut, currentPropertyTypes = currentPropertyTypes_mut;
            if (isEmpty(currentProperties)) {
                return currentPropertyTypes;
            }
            else {
                const currentProperty = head(currentProperties);
                let newPropertyType;
                const propertyTypeIdentifier = currentProperty[0];
                newPropertyType = ((!isSecondary(currentProperty[0], currentProperty[1])) ? [new PropertyType(0), singleton(currentProperty)] : (isPseudo(currentProperty[0], currentProperty[1]) ? [new PropertyType(1, propertyTypeIdentifier), singleton(currentProperty)] : (isMedia(currentProperty[0], currentProperty[1]) ? [new PropertyType(2, propertyTypeIdentifier), singleton(currentProperty)] : [new PropertyType(3, propertyTypeIdentifier), singleton(currentProperty)])));
                let newPropertyTypes;
                if (previousPropertyType == null) {
                    newPropertyTypes = singleton(newPropertyType);
                }
                else {
                    const previousPropertyType_1 = previousPropertyType;
                    if (equals(newPropertyType[0], previousPropertyType_1[0])) {
                        const newPropertyToInsert = [newPropertyType[0], append(previousPropertyType_1[1], newPropertyType[1])];
                        newPropertyTypes = map((x) => (equalArrays(x, previousPropertyType_1) ? newPropertyToInsert : x), currentPropertyTypes);
                    }
                    else {
                        newPropertyTypes = append(currentPropertyTypes, singleton(newPropertyType));
                    }
                }
                const previousPropertyType_2 = head(reverse(newPropertyTypes));
                currentProperties_mut = tail(currentProperties);
                previousPropertyType_mut = previousPropertyType_2;
                currentPropertyTypes_mut = newPropertyTypes;
                continue arrangePropertyLists_1;
            }
            break;
        }
    };
    return arrangePropertyLists_1(properties, void 0, empty());
}

function createFssInternal(name, properties) {
    let label;
    const _arg = tryFind((tupledArg) => isLabel(tupledArg[0], tupledArg[1]), properties);
    label = ((_arg == null) ? "" : MasterTypeHelpers_stringifyICssValue(_arg[1]));
    const properties_1 = filter((arg) => {
        let tupledArg_1;
        return !((tupledArg_1 = arg, isLabel(tupledArg_1[0], tupledArg_1[1])));
    }, properties);
    let className;
    if (name != null) {
        className = name;
    }
    else {
        const fullCssString = join(";", map((tupledArg_2) => (`${MasterTypeHelpers_stringifyICssValue(tupledArg_2[0])}-${MasterTypeHelpers_stringifyICssValue(tupledArg_2[1])}`), properties_1));
        className = (`.css${FNV_1A_hash(fullCssString)}${label}`);
    }
    const addClassName_1 = (cssPair) => addClassName(className, cssPair[0], cssPair[1]);
    const arrangedCss = collect((tupledArg_3) => {
        const propertyType = tupledArg_3[0];
        const rules = tupledArg_3[1];
        switch (propertyType.tag) {
            case 1: {
                return map(addClassName_1, createPseudoCss(rules));
            }
            case 2: {
                return createMediaCss(className, rules);
            }
            case 3: {
                return map(addClassName_1, createCombinatorCss(rules));
            }
            default: {
                return singleton([className, createMainCSS(rules)]);
            }
        }
    }, arrangePropertyLists(properties_1));
    return [className.slice(1, className.length), arrangedCss];
}

export function createFss(properties) {
    return createFssInternal(void 0, properties);
}

export function createFssWithClassname(className, properties) {
    return createFssInternal(className, properties);
}

export function createGlobal(properties) {
    return createFssInternal("*", properties)[1];
}

function stringifyCounterProperty(property_, property__1) {
    const property = [property_, property__1];
    return `${MasterTypeHelpers_stringifyICssValue(property[0])}: ${property[1].StringifyCounter()};`;
}

function createCounterStyleInternal(name, properties) {
    const label = tryHead(reverse(map((tupledArg_1) => tupledArg_1[1], filter((tupledArg) => isCounterLabel(tupledArg[0], tupledArg[1]), properties))));
    const propertyString = join("", map((tupledArg_3) => stringifyCounterProperty(tupledArg_3[0], tupledArg_3[1]), filter((arg) => {
        let tupledArg_2;
        return !((tupledArg_2 = arg, isCounterLabel(tupledArg_2[0], tupledArg_2[1])));
    }, properties)));
    let label_1;
    if (label == null) {
        label_1 = "";
    }
    else {
        const l = label;
        label_1 = (`counter-${MasterTypeHelpers_stringifyICounterValue(l)}`);
    }
    return [(name != null) ? name : (`counter${FNV_1A_hash(propertyString)}${label_1}`), addBrackets(propertyString)];
}

export function createCounterStyle(properties) {
    return createCounterStyleInternal(void 0, properties);
}

export function createCounterStyleWithName(className, properties) {
    return createCounterStyleInternal(className, properties);
}

function stringifyFontFaceProperty(property_, property__1) {
    const property = [property_, property__1];
    return `${MasterTypeHelpers_stringifyICssValue(property[0])}: ${property[1].StringifyFontFace()};`;
}

export function createFontFace(name, properties) {
    return [name, addBrackets(join("", map((tupledArg) => stringifyFontFaceProperty(tupledArg[0], tupledArg[1]), append(singleton(FontFaceClasses_FontFamily__string_Z721C83C5(FontFamily, name)), properties))))];
}

export function createFontFaces(name, properties) {
    const fontFace = map((ruleList) => createFontFace(name, ruleList), properties);
    return [head(fontFace)[0], join("\n", map((tupledArg) => tupledArg[1], fontFace))];
}

function createAnimationInternal(name, attributeList) {
    const animationStyles = fold((acc, x) => {
        if (x.tag === 1) {
            const patternInput_1 = head(createFss(x.fields[1])[1]);
            const frameNumbers = join(",", map((n) => (`${n}%`), x.fields[0]));
            return `${acc} ${frameNumbers} ${patternInput_1[1]}`;
        }
        else {
            const patternInput = head(createFss(x.fields[1])[1]);
            return `${acc} ${x.fields[0]}% ${patternInput[1]}`;
        }
    }, "", attributeList);
    if (name != null) {
        return [name, addBrackets(animationStyles)];
    }
    else {
        return [`animation-${FNV_1A_hash(animationStyles)}`, addBrackets(animationStyles)];
    }
}

export function createAnimation(attributeList) {
    return createAnimationInternal(void 0, attributeList);
}

export function createAnimationWithName(name, attributeList) {
    return createAnimationInternal(name, attributeList);
}

export function important(propertyName, propertyValue) {
    const tupledArg = [propertyName, new ImportantMaster(0, propertyValue)];
    return [tupledArg[0], tupledArg[1]];
}

export function combine(styles, stylesPred) {
    return join(" ", map((tuple_1) => tuple_1[0], filter((tuple) => tuple[1], append(map((s) => [s, true], styles), stylesPred))));
}

export function rgb(red, green, blue) {
    return new Color_Color(145, red, green, blue);
}

export function rgba(red, green, blue, alpha) {
    return new Color_Color(146, red, green, blue, alpha);
}

export function hex(value) {
    return colorHelpers_hex(value);
}

export function hsl(hue, saturation, lightness) {
    return new Color_Color(148, hue, saturation, lightness);
}

export function hsla(hue, saturation, lightness, alpha) {
    return new Color_Color(149, hue, saturation, lightness, alpha);
}

export function px(v) {
    return new Length(0, v);
}

export function inc(v) {
    return new Length(1, v);
}

export function cm(v) {
    return new Length(2, v);
}

export function mm(v) {
    return new Length(3, v);
}

export function pt(v) {
    return new Length(4, v);
}

export function pc(v) {
    return new Length(5, v);
}

export function em(v) {
    return new Length(6, v);
}

export function rem(v) {
    return new Length(7, v);
}

export function ex(v) {
    return new Length(8, v);
}

export function ch(v) {
    return new Length(9, v);
}

export function vw(v) {
    return new Length(10, v);
}

export function vh(v) {
    return new Length(11, v);
}

export function vmax(v) {
    return new Length(12, v);
}

export function vmin(v) {
    return new Length(13, v);
}

export function deg(v) {
    return new Angle(0, v);
}

export function grad(v) {
    return new Angle(1, v);
}

export function rad(v) {
    return new Angle(2, v);
}

export function turn(v) {
    return new Angle(3, v);
}

export function pct(v) {
    return new Percent(0, v);
}

export function sec(v) {
    return new Time(0, v);
}

export function ms(v) {
    return new Time(1, v);
}

export function fr(v) {
    return new Fraction(0, v);
}

export const On = new Font_SettingSwitch(0);

export const Off = new Font_SettingSwitch(1);

