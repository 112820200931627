import { Union } from "../../fable-library.3.7.17/Types.js";
import { class_type, union_type } from "../../fable-library.3.7.17/Reflection.js";
import { String$, UrlMaster, CssRuleWithAutoNone$reflection, CssRuleWithAutoNone } from "./MasterTypes.fs.js";

export class Cursor_Cursor extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Default", "ContextMenu", "Help", "Pointer", "Progress", "Wait", "Cell", "Crosshair", "Text", "VerticalText", "Alias", "Copy", "Move", "NoDrop", "NotAllowed", "Grab", "Grabbing", "AllScroll", "ColResize", "RowResize", "NResize", "EResize", "SResize", "WResize", "NsResize", "EwResize", "NeResize", "NwResize", "SeResize", "SwResize", "NeswResize", "NwseResize", "ZoomIn", "ZoomOut"];
    }
    StringifyCss() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "context-menu";
            }
            case 2: {
                return "help";
            }
            case 3: {
                return "pointer";
            }
            case 4: {
                return "progress";
            }
            case 5: {
                return "wait";
            }
            case 6: {
                return "cell";
            }
            case 7: {
                return "crosshair";
            }
            case 8: {
                return "text";
            }
            case 9: {
                return "vertical-text";
            }
            case 10: {
                return "alias";
            }
            case 11: {
                return "copy";
            }
            case 12: {
                return "move";
            }
            case 13: {
                return "no-drop";
            }
            case 14: {
                return "not-allowed";
            }
            case 15: {
                return "grab";
            }
            case 16: {
                return "grabbing";
            }
            case 17: {
                return "all-scroll";
            }
            case 18: {
                return "col-resize";
            }
            case 19: {
                return "row-resize";
            }
            case 20: {
                return "n-resize";
            }
            case 21: {
                return "e-resize";
            }
            case 22: {
                return "s-resize";
            }
            case 23: {
                return "w-resize";
            }
            case 24: {
                return "ns-resize";
            }
            case 25: {
                return "ew-resize";
            }
            case 26: {
                return "ne-resize";
            }
            case 27: {
                return "nw-resize";
            }
            case 28: {
                return "se-resize";
            }
            case 29: {
                return "sw-resize";
            }
            case 30: {
                return "nesw-resize";
            }
            case 31: {
                return "nwse-resize";
            }
            case 32: {
                return "zoom-in";
            }
            case 33: {
                return "zoom-out";
            }
            default: {
                return "default";
            }
        }
    }
}

export function Cursor_Cursor$reflection() {
    return union_type("Fss.Types.Cursor.Cursor", [], Cursor_Cursor, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class CursorClasses_CursorParent extends CssRuleWithAutoNone {
    constructor(property) {
        super(property);
        this.property_2 = property;
    }
}

export function CursorClasses_CursorParent$reflection() {
    return class_type("Fss.Types.CursorClasses.CursorParent", void 0, CursorClasses_CursorParent, CssRuleWithAutoNone$reflection());
}

export function CursorClasses_CursorParent_$ctor_Z207A3CFB(property) {
    return new CursorClasses_CursorParent(property);
}

export function CursorClasses_CursorParent__value_Z721C83C5(this$, url) {
    const tupledArg = [this$.property_2, new UrlMaster(0, url)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__value_ZF6CC49A(this$, cursor) {
    const tupledArg = [this$.property_2, cursor];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__url_Z721C83C5(this$, url) {
    const tupledArg = [this$.property_2, new UrlMaster(0, url)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__url_487EF8FB(this$, url, x, y) {
    const tupledArg = [this$.property_2, new String$(0, `url(${url}) ${x} ${y}`)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_default$0027(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(0)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_contextMenu(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(1)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_help(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(2)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_pointer(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(3)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_progress(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(4)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_wait(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(5)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_cell(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(6)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_crosshair(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(7)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_text(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(8)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_verticalText(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(9)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_alias(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(10)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_copy(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(11)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_move(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(12)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_noDrop(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(13)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_notAllowed(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(14)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_grab(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(15)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_grabbing(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(16)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_allScroll(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(17)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_colResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(18)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_rowResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(19)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_nResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(20)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_eResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(21)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_sResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(22)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_wResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(23)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_nsResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(24)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_ewResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(25)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_neResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(26)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_nwResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(27)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_seResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(28)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_swResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(29)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_neswResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(30)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_nwseResize(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(31)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_zoomIn(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(32)];
    return [tupledArg[0], tupledArg[1]];
}

export function CursorClasses_CursorParent__get_zoomOut(this$) {
    const tupledArg = [this$.property_2, new Cursor_Cursor(33)];
    return [tupledArg[0], tupledArg[1]];
}

