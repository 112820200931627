import { Property_CssProperty, Property_CssProperty$reflection, MasterTypeHelpers_stringifyICssValue } from "./MasterTypes.fs.js";
import { toString, Union } from "../../fable-library.3.7.17/Types.js";
import { tuple_type, class_type, list_type, int32_type, bool_type, union_type } from "../../fable-library.3.7.17/Reflection.js";
import { Resolution$reflection, Length$reflection, unitHelpers_lengthPercentageString } from "./Units.fs.js";

export class Media_Device extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Screen", "Speech", "Print", "All", "Not", "Only"];
    }
    StringifyCss() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "speech";
            }
            case 2: {
                return "print";
            }
            case 3: {
                return "all";
            }
            case 4: {
                return `not ${MasterTypeHelpers_stringifyICssValue(this$.fields[0])}`;
            }
            case 5: {
                return `only ${MasterTypeHelpers_stringifyICssValue(this$.fields[0])}`;
            }
            default: {
                return "screen";
            }
        }
    }
}

export function Media_Device$reflection() {
    return union_type("Fss.Types.Media.Device", [], Media_Device, () => [[], [], [], [], [["Item", Media_Device$reflection()]], [["Item", Media_Device$reflection()]]]);
}

export class Media_Pointer extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Course", "Fine", "None"];
    }
}

export function Media_Pointer$reflection() {
    return union_type("Fss.Types.Media.Pointer", [], Media_Pointer, () => [[], [], []]);
}

export function Media_Pointer__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "fine";
        }
        case 2: {
            return "none";
        }
        default: {
            return "course";
        }
    }
}

export class Media_ColorGamut extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SRGB", "P3", "REC2020"];
    }
}

export function Media_ColorGamut$reflection() {
    return union_type("Fss.Types.Media.ColorGamut", [], Media_ColorGamut, () => [[], [], []]);
}

export function Media_ColorGamut__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "p3";
        }
        case 2: {
            return "rec2020";
        }
        default: {
            return "srgb";
        }
    }
}

export class Media_DisplayMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Fullscreen", "Standalone", "MinimalUi", "Browser"];
    }
}

export function Media_DisplayMode$reflection() {
    return union_type("Fss.Types.Media.DisplayMode", [], Media_DisplayMode, () => [[], [], [], []]);
}

export function Media_DisplayMode__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "standalone";
        }
        case 2: {
            return "minimal-ui";
        }
        case 3: {
            return "browser";
        }
        default: {
            return "fullscreen";
        }
    }
}

export class Media_LightLevel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Dim", "Washed"];
    }
}

export function Media_LightLevel$reflection() {
    return union_type("Fss.Types.Media.LightLevel", [], Media_LightLevel, () => [[], []]);
}

export function Media_LightLevel__Stringify(this$) {
    if (this$.tag === 1) {
        return "washed";
    }
    else {
        return "dim";
    }
}

export class Media_Orientation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Landscape", "Portrait"];
    }
}

export function Media_Orientation$reflection() {
    return union_type("Fss.Types.Media.Orientation", [], Media_Orientation, () => [[], []]);
}

export function Media_Orientation__Stringify(this$) {
    if (this$.tag === 1) {
        return "portrait";
    }
    else {
        return "landscape";
    }
}

export class Media_OverflowBlock extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["None", "Scrolled", "OptionalPaged", "Paged"];
    }
}

export function Media_OverflowBlock$reflection() {
    return union_type("Fss.Types.Media.OverflowBlock", [], Media_OverflowBlock, () => [[], [], [], []]);
}

export function Media_OverflowBlock__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "scrolled";
        }
        case 2: {
            return "optional-paged";
        }
        case 3: {
            return "paged";
        }
        default: {
            return "none";
        }
    }
}

export class Media_ColorScheme extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Light", "Dark"];
    }
}

export function Media_ColorScheme$reflection() {
    return union_type("Fss.Types.Media.ColorScheme", [], Media_ColorScheme, () => [[], []]);
}

export function Media_ColorScheme__Stringify(this$) {
    if (this$.tag === 1) {
        return "dark";
    }
    else {
        return "light";
    }
}

export class Media_Contrast extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoPreference", "High", "Low"];
    }
}

export function Media_Contrast$reflection() {
    return union_type("Fss.Types.Media.Contrast", [], Media_Contrast, () => [[], [], []]);
}

export function Media_Contrast__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "high";
        }
        case 2: {
            return "low";
        }
        default: {
            return "no-preference";
        }
    }
}

export class Media_Scan extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Interlace", "Progressive"];
    }
}

export function Media_Scan$reflection() {
    return union_type("Fss.Types.Media.Scan", [], Media_Scan, () => [[], []]);
}

export function Media_Scan__Stringify(this$) {
    if (this$.tag === 1) {
        return "progressive";
    }
    else {
        return "interlace";
    }
}

export class Media_Scripting extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["None", "InitialOnly", "Enabled"];
    }
}

export function Media_Scripting$reflection() {
    return union_type("Fss.Types.Media.Scripting", [], Media_Scripting, () => [[], [], []]);
}

export function Media_Scripting__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "initial-only";
        }
        case 2: {
            return "enabled";
        }
        default: {
            return "none";
        }
    }
}

export class Media_Update extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["None", "Slow", "Fast"];
    }
}

export function Media_Update$reflection() {
    return union_type("Fss.Types.Media.Update", [], Media_Update, () => [[], [], []]);
}

export function Media_Update__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "slow";
        }
        case 2: {
            return "fast";
        }
        default: {
            return "none";
        }
    }
}

export class Media_Feature extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AnyHover", "AnyPointer", "AspectRatio", "MinAspectRatio", "MaxAspectRatio", "Color", "MinColor", "MaxColor", "ColorGamut", "ColorIndex", "MinColorIndex", "MaxColorIndex", "DisplayMode", "ForcedColors", "Grid", "Height", "MinHeight", "MaxHeight", "Width", "MinWidth", "MaxWidth", "Hover", "InvertedColors", "LightLevel", "Monochrome", "MinMonochrome", "MaxMonochrome", "Orientation", "OverflowBlock", "OverflowInline", "Pointer", "PrefersColorScheme", "PrefersContrast", "PrefersReducedMotion", "PrefersReducedTransparency", "Resolution", "MinResolution", "MaxResolution", "Scan", "Scripting", "Update"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return `any-pointer: ${Media_Pointer__Stringify(this$.fields[0])}`;
            }
            case 2: {
                return `aspect-ratio: ${this$.fields[0]}/${this$.fields[1]}`;
            }
            case 3: {
                return `min-aspect-ratio: ${this$.fields[0]}/${this$.fields[1]}`;
            }
            case 4: {
                return `max-aspect-ratio ${this$.fields[0]}/${this$.fields[1]}`;
            }
            case 5: {
                return "color";
            }
            case 6: {
                return `min-color: ${this$.fields[0]}`;
            }
            case 7: {
                return `max-color: ${this$.fields[0]}`;
            }
            case 8: {
                return `color-gamut: ${Media_ColorGamut__Stringify(this$.fields[0])}`;
            }
            case 9: {
                return `color-index: ${this$.fields[0]}`;
            }
            case 10: {
                return `min-color-index: ${this$.fields[0]}`;
            }
            case 11: {
                return `max-color-index: ${this$.fields[0]}`;
            }
            case 12: {
                return `display-mode: ${this$.fields[0]}`;
            }
            case 13: {
                return `forced-colors: ${this$.fields[0]}`;
            }
            case 14: {
                return `grid: ${toString(this$.fields[0]).toLocaleLowerCase()}`;
            }
            case 15: {
                return `height: ${unitHelpers_lengthPercentageString(this$.fields[0])}`;
            }
            case 16: {
                return `min-height: ${unitHelpers_lengthPercentageString(this$.fields[0])}`;
            }
            case 17: {
                return `max-height: ${unitHelpers_lengthPercentageString(this$.fields[0])}`;
            }
            case 18: {
                return `width: ${unitHelpers_lengthPercentageString(this$.fields[0])}`;
            }
            case 19: {
                return `min-width: ${unitHelpers_lengthPercentageString(this$.fields[0])}`;
            }
            case 20: {
                return `max-width: ${unitHelpers_lengthPercentageString(this$.fields[0])}`;
            }
            case 21: {
                return `hover: ${this$.fields[0]}`;
            }
            case 22: {
                return `inverted-colors: ${this$.fields[0]}`;
            }
            case 23: {
                return `light-level: ${Media_LightLevel__Stringify(this$.fields[0])}`;
            }
            case 24: {
                return `monochrome: ${this$.fields[0]}`;
            }
            case 25: {
                return `min-monochrome: ${this$.fields[0]}`;
            }
            case 26: {
                return `max-monochrome: ${this$.fields[0]}`;
            }
            case 27: {
                return `orientation: ${Media_Orientation__Stringify(this$.fields[0])}`;
            }
            case 28: {
                return `overflow-block: ${Media_OverflowBlock__Stringify(this$.fields[0])}`;
            }
            case 29: {
                return `overflow-inline: ${this$.fields[0]}`;
            }
            case 30: {
                return `pointer: ${Media_Pointer__Stringify(this$.fields[0])}`;
            }
            case 31: {
                return `preferred-color-scheme: ${Media_ColorScheme__Stringify(this$.fields[0])}`;
            }
            case 32: {
                return `contrast: ${(() => Media_Contrast__Stringify(this$.fields[0]))}`;
            }
            case 33: {
                return `prefers-reduced-motion: ${this$.fields[0]}`;
            }
            case 34: {
                return `prefers-reduced-transparency: ${this$.fields[0]}`;
            }
            case 35: {
                return `resolution: ${MasterTypeHelpers_stringifyICssValue(this$.fields[0])}`;
            }
            case 36: {
                return `min-resolution: ${MasterTypeHelpers_stringifyICssValue(this$.fields[0])}`;
            }
            case 37: {
                return `max-resolution: ${MasterTypeHelpers_stringifyICssValue(this$.fields[0])}`;
            }
            case 38: {
                return `scan: ${Media_Scan__Stringify(this$.fields[0])}`;
            }
            case 39: {
                return `scripting: ${Media_Scripting__Stringify(this$.fields[0])}`;
            }
            case 40: {
                return `update: ${Media_Update__Stringify(this$.fields[0])}`;
            }
            default: {
                return `any-hover: ${this$.fields[0]}`;
            }
        }
    }
}

export function Media_Feature$reflection() {
    return union_type("Fss.Types.Media.Feature", [], Media_Feature, () => [[["Item", bool_type]], [["Item", Media_Pointer$reflection()]], [["Item1", int32_type], ["Item2", int32_type]], [["Item1", int32_type], ["Item2", int32_type]], [["Item1", int32_type], ["Item2", int32_type]], [], [["Item", int32_type]], [["Item", int32_type]], [["Item", Media_ColorGamut$reflection()]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", Media_DisplayMode$reflection()]], [["Item", bool_type]], [["Item", bool_type]], [["Item", Length$reflection()]], [["Item", Length$reflection()]], [["Item", Length$reflection()]], [["Item", Length$reflection()]], [["Item", Length$reflection()]], [["Item", Length$reflection()]], [["Item", bool_type]], [["Item", bool_type]], [["Item", Media_LightLevel$reflection()]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", Media_Orientation$reflection()]], [["Item", Media_OverflowBlock$reflection()]], [["Item", bool_type]], [["Item", Media_Pointer$reflection()]], [["Item", Media_ColorScheme$reflection()]], [["Item", Media_Contrast$reflection()]], [["Item", bool_type]], [["Item", bool_type]], [["Item", Resolution$reflection()]], [["Item", Resolution$reflection()]], [["Item", Resolution$reflection()]], [["Item", Media_Scan$reflection()]], [["Item", Media_Scripting$reflection()]], [["Item", Media_Update$reflection()]]]);
}

export class Media_MediaQuery extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MediaQuery", "MediaQueryFor"];
    }
    StringifyCss() {
        return "";
    }
}

export function Media_MediaQuery$reflection() {
    return union_type("Fss.Types.Media.MediaQuery", [], Media_MediaQuery, () => [[["Item1", list_type(Media_Feature$reflection())], ["Item2", list_type(tuple_type(Property_CssProperty$reflection(), class_type("Fss.Types.ICssValue")))]], [["Item1", Media_Device$reflection()], ["Item2", list_type(Media_Feature$reflection())], ["Item3", list_type(tuple_type(Property_CssProperty$reflection(), class_type("Fss.Types.ICssValue")))]]]);
}

export class MediaClasses_Media {
    constructor() {
    }
}

export function MediaClasses_Media$reflection() {
    return class_type("Fss.Types.MediaClasses.Media", void 0, MediaClasses_Media);
}

export function MediaClasses_Media_$ctor() {
    return new MediaClasses_Media();
}

export function MediaClasses_Media__query(this$, features, rules) {
    const tupledArg = [new Property_CssProperty(397), new Media_MediaQuery(0, features, rules)];
    return [tupledArg[0], tupledArg[1]];
}

export function MediaClasses_Media__queryFor(this$, device, features, rules) {
    const tupledArg = [new Property_CssProperty(397), new Media_MediaQuery(1, device, features, rules)];
    return [tupledArg[0], tupledArg[1]];
}

