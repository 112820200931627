import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { AdiId, AdiRoleModule_string, BankAccount___UnsafeUnwrap_Z2C38F894, VatNumber___UnsafeUnwrap_18C04ACB, PersonIdOvam___UnsafeUnwrap_ZCAC45BA, NationalInsuranceNumber___UnsafeUnwrap_Z19EB54D4, PhoneNumber___UnsafeUnwrap_23E45174, Email___UnsafeUnwrap_Z27D11339, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import { map as map_1 } from "../fable_modules/fable-library.3.7.17/Set.js";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal, flexExpand, Gap, BkgColor } from "../../../Styles/Utils.fs.js";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { MaxWidth } from "../fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import Chip from "@material-ui/core/Chip";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow } from "../fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import { DisplayData, InfoTitle } from "../../../DesignSystems/DesignSystem.fs.js";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { adis, adiEdit } from "../AppRoutes.fs.js";
import Edit from "@material-ui/icons/Edit";
import CheckBox from "@material-ui/icons/CheckBox";
import Delete from "@material-ui/icons/Delete";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function DisplayAdiInfoOld(adi) {
    let elems_12;
    return createElement("div", createObj(singleton((elems_12 = toList(delay(() => append(singleton_1(createElement("h2", {
        children: ["Algemeen"],
    })), delay(() => {
        let children_1, children;
        return append(singleton_1((children_1 = singleton((children = ofArray([createElement(Info, {
            secondary: "Naam",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Voornaam",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Emailadres",
            primary: Email___UnsafeUnwrap_Z27D11339(adi.Email),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Email Asbest Consulting",
            primary: Email___UnsafeUnwrap_Z27D11339(adi.EmailAsbestConsulting),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Telefoon",
            primary: PhoneNumber___UnsafeUnwrap_23E45174(adi.PhoneNumber),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Rijksregisternummer",
            primary: NationalInsuranceNumber___UnsafeUnwrap_Z19EB54D4(adi.NationalInsuranceNumber),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Persoons-id OVAM",
            primary: PersonIdOvam___UnsafeUnwrap_ZCAC45BA(adi.PersonIdOvam),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Persoonscertificatie",
            primary: "",
        })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), delay(() => append(singleton_1(createElement("h2", {
            children: ["Adres"],
        })), delay(() => {
            let children_3, children_2;
            return append(singleton_1((children_3 = singleton((children_2 = ofArray([createElement(Info, {
                secondary: "Straat",
                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.Street),
            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                secondary: "Nummer",
                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.Number),
            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                secondary: "Bus",
                primary: defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, adi.Address.Bus), "--"),
            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                secondary: "Postcode",
                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.PostalCode),
            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                secondary: "Gemeente",
                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.City),
            })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), delay(() => append(singleton_1(createElement("h2", {
                children: ["Regio(s) waar de ADI werkzaam is"],
            })), delay(() => {
                let children_5, children_4;
                return append(singleton_1((children_5 = singleton((children_4 = toList(delay(() => {
                    const regions = map_1((r) => {
                        switch (r.tag) {
                            case 1: {
                                return "East Flanders";
                            }
                            case 2: {
                                return "Antwerp";
                            }
                            case 3: {
                                return "Flemish Brabant";
                            }
                            case 4: {
                                return "Limburg";
                            }
                            default: {
                                return "West Flanders";
                            }
                        }
                    }, adi.Regions, {
                        Compare: comparePrimitives,
                    });
                    return singleton_1(createElement(Info, {
                        secondary: "Regio(s) waar de ADI werkzaam is",
                        primary: join(", ", regions),
                    }));
                })), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), delay(() => append(singleton_1(createElement("h2", {
                    children: ["Bedrijfsgegevens"],
                })), delay(() => {
                    let children_7, children_6;
                    return append(singleton_1((children_7 = singleton((children_6 = ofArray([createElement(Info, {
                        secondary: "Ondernemingsnummer",
                        primary: VatNumber___UnsafeUnwrap_18C04ACB(adi.AdiCompany.VatNumber),
                    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                        secondary: "Zichtrekening",
                        primary: BankAccount___UnsafeUnwrap_Z2C38F894(adi.AdiCompany.BankAccount),
                    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                        secondary: "Bedrijfsform",
                        primary: "",
                    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                        secondary: "Betalingstermijn",
                        primary: `${adi.AdiCompany.TermOfPayment}`,
                    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                        secondary: "BTW-plichtig",
                        primary: adi.AdiCompany.VatLiable ? "YES" : "NO",
                    })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), delay(() => {
                        let matchValue, co;
                        return append((matchValue = adi.AdiCompany.CompanyFormat, (matchValue.tag === 0) ? singleton_1(null) : ((co = matchValue.fields[0], append(singleton_1(createElement("h2", {
                            children: ["Gegevens vennootschap"],
                        })), delay(() => {
                            let children_9, children_8, ctype;
                            return singleton_1((children_9 = singleton((children_8 = ofArray([createElement(Info, {
                                secondary: "Naam vennootschap",
                                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(co.Name),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Vennootschapsvorm",
                                primary: (ctype = co.TypeOfCorporation, (ctype.tag === 1) ? "BV" : ((ctype.tag === 2) ? "CV" : ((ctype.tag === 3) ? "VOF" : ((ctype.tag === 4) ? "Single Proprietor" : "NV")))),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Telefoon",
                                primary: PhoneNumber___UnsafeUnwrap_23E45174(co.PhoneNumber),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Emailadres",
                                primary: Email___UnsafeUnwrap_Z27D11339(co.Email),
                            })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]])));
                        }))))), delay(() => append(singleton_1(createElement("h2", {
                            children: ["Adres zaak"],
                        })), delay(() => {
                            let children_11, children_10;
                            return singleton_1((children_11 = singleton((children_10 = ofArray([createElement(Info, {
                                secondary: "Straat",
                                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.Street),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Nummer",
                                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.Number),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Bus",
                                primary: defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, adi.AdiCompany.Address.Bus), "--"),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Postcode",
                                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.PostalCode),
                            }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                                secondary: "Gemeente",
                                primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.City),
                            })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]])));
                        }))));
                    }));
                }))));
            }))));
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]))));
}

export function ActiveStatusChip(adi) {
    let elems;
    toConsole(printf("%A"))(adi.IsActive);
    const patternInput = adi.IsActive ? ["Activeer", BkgColor.Primary] : ["Inactief", BkgColor.Danger];
    return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MaxWidth, px(300)))), (elems = [MuiHelpers_createElement(Chip, [["label", `${patternInput[0]}`], ["clickable", false], ["classes.label", patternInput[1]]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function DisplayAdiInfo(adi) {
    let elems, elems_1, elems_2;
    const regions = map_1((r) => {
        switch (r.tag) {
            case 1: {
                return "East Flanders";
            }
            case 2: {
                return "Antwerp";
            }
            case 3: {
                return "Flemish Brabant";
            }
            case 4: {
                return "Limburg";
            }
            default: {
                return "West Flanders";
            }
        }
    }, adi.Regions, {
        Compare: comparePrimitives,
    });
    return createElement(Col, {
        classes: singleton(Gap.L),
        children: ofArray([createElement(ActiveStatusChip, adi), createElement(Row, {
            classes: singleton(Gap.L),
            children: ofArray([MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(InfoTitle, {
                    text: "Algemeen",
                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                    data: ofArray([["Naam", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)], ["Voornaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)], ["Emailadres", Email___UnsafeUnwrap_Z27D11339(adi.Email)], ["Email Asbest Consulting", Email___UnsafeUnwrap_Z27D11339(adi.EmailAsbestConsulting)], ["Telefoon", PhoneNumber___UnsafeUnwrap_23E45174(adi.PhoneNumber)], ["Rijksregisternummer", NationalInsuranceNumber___UnsafeUnwrap_Z19EB54D4(adi.NationalInsuranceNumber)], ["Persoons-id OVAM", PersonIdOvam___UnsafeUnwrap_ZCAC45BA(adi.PersonIdOvam)], ["Persoonscertificatie", ""], ["Role", defaultArg(map(AdiRoleModule_string, adi.Role), "-")]]),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement(Col, {
                classes: ofArray([Gap.L, flexExpand]),
                children: ofArray([MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems_1 = [createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement(InfoTitle, {
                        text: "Adres",
                    }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                        data: ofArray([["Straat", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.Street)], ["Nummer", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.Number)], ["Bus", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, adi.Address.Bus), "--")], ["Postcode", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.PostalCode)], ["Gemeente", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.City)]]),
                    })]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems_2 = [createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement(InfoTitle, {
                        text: "Regio(s) waar de ADI werkzaam is",
                    }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                        data: singleton(["Regio(s) waar de ADI werkzaam is", join(", ", regions)]),
                    })]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])]),
            })]),
        }), createElement(Row, {
            classes: singleton(Gap.L),
            children: toList(delay(() => {
                let elems_3;
                return append(singleton_1(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems_3 = [createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement(InfoTitle, {
                        text: "Bedrijfsgegevens",
                    }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                        data: ofArray([["Ondernemingsnummer", VatNumber___UnsafeUnwrap_18C04ACB(adi.AdiCompany.VatNumber)], ["Zichtrekening", BankAccount___UnsafeUnwrap_Z2C38F894(adi.AdiCompany.BankAccount)], ["Bedrijfsvorm", ""], ["Betalingstermijn", `${adi.AdiCompany.TermOfPayment}`], ["BTW-plichtig", adi.AdiCompany.VatLiable ? "Ja" : "Nee"]]),
                    })]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])), delay(() => {
                    let matchValue, co, elems_4, ctype;
                    return append((matchValue = adi.AdiCompany.CompanyFormat, (matchValue.tag === 0) ? ((empty_1())) : ((co = matchValue.fields[0], singleton_1(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems_4 = [createElement(Col, {
                        classes: empty(),
                        children: ofArray([createElement(InfoTitle, {
                            text: "Gegevens vennootschap",
                        }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                            data: ofArray([["Naam vennootschap", NonEmptyString___UnsafeUnwrap_Z73AD07C(co.Name)], ["Vennootschapsvorm", (ctype = co.TypeOfCorporation, (ctype.tag === 1) ? "BV" : ((ctype.tag === 2) ? "CV" : ((ctype.tag === 3) ? "VOF" : ((ctype.tag === 4) ? "Single Proprietor" : "NV"))))], ["Telefoon", PhoneNumber___UnsafeUnwrap_23E45174(co.PhoneNumber)], ["Emailadres", Email___UnsafeUnwrap_Z27D11339(co.Email)]]),
                        })]),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))))), delay(() => {
                        let elems_5;
                        return singleton_1(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems_5 = [createElement(Col, {
                            classes: empty(),
                            children: ofArray([createElement(InfoTitle, {
                                text: "Adres zaak",
                            }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                                data: ofArray([["Straat", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.Street)], ["Nummer", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.Number)], ["Bus", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, adi.AdiCompany.Address.Bus), "--")], ["Postcode", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.PostalCode)], ["Gemeente", NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.City)]]),
                            })]),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]));
                    }));
                }));
            })),
        })]),
    });
}

export function ActivateStatusConfirmationModal(activateStatusConfirmationModalInputProps) {
    let elems_1;
    const onClick = activateStatusConfirmationModalInputProps.onClick;
    const setShowActivateStatusModal = activateStatusConfirmationModalInputProps.setShowActivateStatusModal;
    const showActivateStatusModal = activateStatusConfirmationModalInputProps.showActivateStatusModal;
    const label = activateStatusConfirmationModalInputProps.label;
    const adiId = activateStatusConfirmationModalInputProps.adiId;
    return MuiHelpers_createElement(Modal, [["open", showActivateStatusModal], ["onClose", (_arg_1, v) => {
        setShowActivateStatusModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", {
        className: join(" ", [MarginBottom.M]),
        children: Interop_reactApi.Children.toArray([label]),
    }), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onClick(adiId);
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowActivateStatusModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function AdiDetail(adiDetailInputProps) {
    let props, props_2, props_4;
    const adiId = adiDetailInputProps.adiId;
    const api = adiDetailInputProps.api;
    const currentPage = adiDetailInputProps.currentPage;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowDeactivateModal = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowActivateModal = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const auth = useAuth0();
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const editButton = MuiHelpers_createElement(Button, [["onClick", (_arg_4) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(adiEdit, adiId)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Edit, props))], ["children", "ADI Aanpassen"]]);
    const activateButton = (!patternInput_3[0]) ? MuiHelpers_createElement(Button, [["onClick", (_arg_5) => {
        setShowActivateModal(true);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_2 = {}, react.createElement(CheckBox, props_2))], ["children", "Activeer"]]) : MuiHelpers_createElement(Button, [["className", join(" ", [MarginLeft.S])], ["onClick", (_arg_6) => {
        setShowDeactivateModal(true);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_4 = {}, react.createElement(Delete, props_4))], ["children", "Deactiveer"]]);
    const data_1 = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdi(new AdiId(adiId)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "ADI persoonlijke gegevens",
        actions: [editButton, activateButton],
        children: [createElement(DisplayDeferred, {
            data: data_1,
            view: (adi) => {
                let elems;
                if (adi == null) {
                    return `No Adi found with ID ${adiId}`;
                }
                else {
                    const adi_1 = adi;
                    patternInput_3[1](adi_1.IsActive);
                    return createElement("div", createObj(singleton((elems = [createElement(DisplayAdiInfo, adi_1), createElement(ActivateStatusConfirmationModal, {
                        adiId: adi_1.Id,
                        label: "SBent u zeker dat u deze ADI wilt deactiveren?",
                        showActivateStatusModal: patternInput[0],
                        setShowActivateStatusModal: setShowDeactivateModal,
                        onClick: (adiId_1) => {
                            startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.DeactivateAdi(adiId_1), (_arg) => {
                                const result = _arg;
                                if (result.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Adi deactivated", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(adis)), 1, 1);
                                    return singleton_2.Zero();
                                }
                                else {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${result.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton_2.Zero();
                                }
                            })), (_arg_1) => {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${_arg_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_2.Zero();
                            })));
                        },
                    }), createElement(ActivateStatusConfirmationModal, {
                        adiId: adi_1.Id,
                        label: "Bent u zeker dat u deze ADI wilt activeeren?",
                        showActivateStatusModal: patternInput_1[0],
                        setShowActivateStatusModal: setShowActivateModal,
                        onClick: (adiId_2) => {
                            startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.ActivateAdi(adiId_2), (_arg_2) => {
                                const result_1 = _arg_2;
                                if (result_1.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Adi Activated", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(adis)), 1, 1);
                                    return singleton_2.Zero();
                                }
                                else {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${result_1.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton_2.Zero();
                                }
                            })), (_arg_3) => {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${_arg_3.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_2.Zero();
                            })));
                        },
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
                }
            },
        })],
    });
}

