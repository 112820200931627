import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { ofArray, append as append_1, length, contains, filter, map, toArray, empty, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { AdiRoleModule_string, PreferredAdi__AdisWithMinimumRole_Z7F80284D, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { CalendarResource$1 } from "../lib/bindings/BigCalendar.fs.js";
import { createObj, safeHash, equals } from "../fable_modules/fable-library.3.7.17/Util.js";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { iterate, empty as empty_1, map as map_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Gap, fullWidth, MarginRight, MarginTop } from "../../../Styles/Utils.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Adi_nameAndRole } from "../../../shared/DomainHelpers.fs.js";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Card, Collapsible } from "../../../DesignSystems/DesignSystem.fs.js";
import { PlaceDescriptionAppointmentScheduler } from "../components/PlaceDescriptionScheduler.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { useSnackbar } from "notistack";
import { useForm } from "../../../CustomHooks/ComposedForm.fs.js";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { placeDescriptionAppointmentDetail, dossiers } from "../AppRoutes.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { PlaceDescriptionAppointmentData } from "../../../shared/Api.fs.js";
import { toArray as toArray_1 } from "../fable_modules/fable-library.3.7.17/Option.js";
import { Page } from "../components/Page.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function RenderAdisList(renderAdisListInputProps) {
    let role, role_1, elems;
    const partner = renderAdisListInputProps.partner;
    const setAppointment = renderAdisListInputProps.setAppointment;
    const adis = renderAdisListInputProps.adis;
    const api = renderAdisListInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedAdisList = patternInput[1];
    const selectedAdisList = patternInput[0];
    const resources = useReact_useMemo_CF4EA67(() => toArray(map((tupledArg_1) => {
        const adi = tupledArg_1[0];
        return new CalendarResource$1(adi.Id.Value, `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}`, "");
    }, filter((tupledArg) => tupledArg[1], selectedAdisList))), [selectedAdisList]);
    const normalAdis = (partner != null) ? ((partner.MinimumRole != null) ? ((role = partner.MinimumRole, filter((adi_1) => (!contains(adi_1, PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role), {
        Equals: equals,
        GetHashCode: safeHash,
    })), adis.NormalAdis))) : adis.NormalAdis) : adis.NormalAdis;
    const roleAdis = (partner != null) ? ((partner.MinimumRole != null) ? ((role_1 = partner.MinimumRole, PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role_1))) : empty()) : empty();
    const collapsePartnerAdis = useToggle(false);
    const collapseRoleAdis = useToggle(length(adis.PreferredAdis) > 0);
    const collapseNormalAdis = useToggle((length(adis.PreferredAdis) > 0) ? true : (length(roleAdis) > 0));
    return createElement("div", createObj(singleton((elems = [createElement(Col, {
        classes: empty(),
        children: toList(delay(() => {
            let matchValue, role_2, role_3;
            return append((matchValue = partner, (matchValue != null) ? ((matchValue.MinimumRole != null) ? (((role_2 = matchValue.MinimumRole, length(roleAdis) > 0)) ? ((role_3 = matchValue.MinimumRole, singleton_1(createElement(Col, {
                classes: singleton(MarginTop.M),
                children: toList(delay(() => {
                    const roles = createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Row, {
                            classes: empty(),
                            children: toList(delay(() => map_1((adi_2) => MuiHelpers_createElement(FormControlLabel, [["label", Adi_nameAndRole(adi_2)], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e) => {
                                const v = Browser_Types_Event__Event_get_Checked(e);
                                setSelectedAdisList(append_1(filter((tupledArg_2) => (!equals(tupledArg_2[0], adi_2)), selectedAdisList), singleton([adi_2, v])));
                            }]])]]), roleAdis))),
                        })),
                    });
                    return singleton_1(createElement(Collapsible, {
                        title: `ADIs (Minimum Role: ${AdiRoleModule_string(role_3)})`,
                        children: roles,
                        collapseToggle: collapseRoleAdis,
                        disabled: length(PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role_3)) === 0,
                    }));
                })),
            })))) : ((empty_1()))) : ((empty_1()))) : ((empty_1()))), delay(() => append(singleton_1(createElement(Col, {
                classes: singleton(MarginTop.M),
                children: toList(delay(() => {
                    const normal = createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Row, {
                            classes: empty(),
                            children: toList(delay(() => map_1((adi_4) => MuiHelpers_createElement(FormControlLabel, [["label", `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_4.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_4.LastName)}`], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e_1) => {
                                const v_1 = Browser_Types_Event__Event_get_Checked(e_1);
                                setSelectedAdisList(append_1(filter((tupledArg_3) => (!equals(tupledArg_3[0], adi_4)), selectedAdisList), singleton([adi_4, v_1])));
                            }]])]]), normalAdis))),
                        })),
                    });
                    return singleton_1(createElement(Collapsible, {
                        title: "ADIs (Alle)",
                        children: normal,
                        collapseToggle: collapseNormalAdis,
                        disabled: false,
                    }));
                })),
            })), delay(() => singleton_1(createElement(Row, {
                classes: singleton(MarginTop.L),
                children: singleton(createElement(Col, {
                    classes: empty(),
                    children: toList(delay(() => ((resources.length === 0) ? singleton_1(createElement("h4", {
                        children: ["Selecteer een ADI"],
                    })) : singleton_1(createElement(PlaceDescriptionAppointmentScheduler, {
                        api: api,
                        appointmentLengthInMinutes: 120,
                        resources: resources,
                        setAppointment: setAppointment,
                        readOnly: false,
                        enableOpenDossierLink: false,
                    }))))),
                })),
            }))))));
        })),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function AdisList(adisListInputProps) {
    const partner = adisListInputProps.partner;
    const setAppointment = adisListInputProps.setAppointment;
    const api = adisListInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPreferredAdis(void 0), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (adis) => createElement(RenderAdisList, {
            api: api,
            adis: adis,
            setAppointment: setAppointment,
            partner: partner,
        }),
    });
}

export function DossierCustomerInfo(dossierCustomerInfoInputProps) {
    const setAppointment = dossierCustomerInfoInputProps.setAppointment;
    const api = dossierCustomerInfoInputProps.api;
    return createElement(Row, {
        classes: empty(),
        children: singleton(createElement(Col, {
            classes: ofArray([MarginRight.Xl, fullWidth]),
            children: singleton(createElement(AdisList, {
                api: api,
                setAppointment: setAppointment,
                partner: void 0,
            })),
        })),
    });
}

export function PlaceDescriptionAppointmentCreateForm(placeDescriptionAppointmentCreateFormInputProps) {
    const onCreateAppointment = placeDescriptionAppointmentCreateFormInputProps.onCreateAppointment;
    const api = placeDescriptionAppointmentCreateFormInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const appointment = patternInput[0];
    const snackbar = useSnackbar();
    const form = useForm();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_1[1];
    const disable = patternInput_1[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: ofArray([createElement(DossierCustomerInfo, {
            api: api,
            setAppointment: patternInput[1],
        }), MuiHelpers_createElement(Divider, []), createElement(Row, {
            classes: ofArray([Gap.M, MarginTop.S]),
            children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
            }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                if (appointment == null) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Selecteer een tijdstip", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                }
                else {
                    const appointment_1 = appointment;
                    iterate(() => {
                        setDisable(true);
                        console.log(void 0);
                        onCreateAppointment(new PlaceDescriptionAppointmentData(appointment_1.Status, appointment_1.AdiId, appointment_1.StartTime, appointment_1.EndTime, appointment_1.Note))(() => {
                            setDisable(false);
                        });
                    }, toArray_1(form.Validate()));
                }
            }], ["children", Interop_reactApi.Children.toArray(["Plaatsbeschrijving maken"])]])]),
        })]),
    });
}

export function PlaceDescriptionAppointmentCreate(placeDescriptionAppointmentCreateInputProps) {
    const api = placeDescriptionAppointmentCreateInputProps.api;
    const currentPage = placeDescriptionAppointmentCreateInputProps.currentPage;
    const snackbar = useSnackbar();
    return createElement(Page, {
        currentPage: currentPage,
        title: "Plaatsbeschrijving aanmaken",
        actions: [],
        children: [Card([createElement(PlaceDescriptionAppointmentCreateForm, {
            api: api,
            onCreateAppointment: (placeDescriptionAptData) => ((onComplete) => {
                startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                    toConsole(printf("Creating appointment"));
                    return singleton_2.Bind(api.CreatePlaceDescriptionAppointment(placeDescriptionAptData), (_arg) => {
                        const result = _arg;
                        if (result.tag === 0) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuwe afspraak is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(placeDescriptionAppointmentDetail, result.fields[0].Value)), 1, 1);
                            return singleton_2.Zero();
                        }
                        else {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${result.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton_2.Zero();
                        }
                    });
                }), (_arg_1) => {
                    const e = _arg_1;
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    console.log(e);
                    return singleton_2.Zero();
                })), () => {
                    onComplete();
                })));
            }),
        })])],
    });
}

