import { useSnackbar } from "notistack";
import { getDayEnd, getDayStart, weekEndDate, weekStartDate } from "./AppointmentUtils.fs.js";
import { utcNow, fromDateTimeOffset, compare, now, today } from "../fable_modules/fable-library.3.7.17/Date.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { CalendarProp$2_OnRangeChange_Z33829B90, CalendarProp$2_Selectable_Z1FBCCD16, CalendarProp$2_DayLayoutAlgorithm_Z3A793B44, CalendarProp$2, CalendarEvent$1, DateRange } from "../lib/bindings/BigCalendar.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { map as map_1, toArray, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { last, head, append, map } from "../fable_modules/fable-library.3.7.17/Array.js";
import { DossierId, Appointment, AppointmentStatus, NonEmptyString___UnsafeUnwrap_Z73AD07C, AppointmentListType, DossierCode, AppointmentId, AdiId } from "../../../shared/Domain.fs.js";
import { fromDate } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { createElement } from "react";
import { isArrayLike, equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { appointmentCal } from "../../../Styles/Utils.fs.js";
import { Scheduler } from "./Scheduler.fs.js";
import { setHours, setMinutes } from "date-fns";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail } from "../AppRoutes.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";

export function ScheduleCalendar(scheduleCalendarInputProps) {
    let elems;
    const enableOpenDossierLink = scheduleCalendarInputProps.enableOpenDossierLink;
    const readOnly = scheduleCalendarInputProps.readOnly;
    const setAppointment = scheduleCalendarInputProps.setAppointment;
    const dossierId = scheduleCalendarInputProps.dossierId;
    const dossierCode = scheduleCalendarInputProps.dossierCode;
    const resources = scheduleCalendarInputProps.resources;
    const appointmentLengthInMinutes = scheduleCalendarInputProps.appointmentLengthInMinutes;
    const api = scheduleCalendarInputProps.api;
    const snackbar = useSnackbar();
    const startDate = weekStartDate(today());
    const endDate = weekEndDate(today());
    const patternInput = useFeliz_React__React_useState_Static_1505(new Array(0));
    const availabilities = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new DateRange(startDate, endDate));
    const setDateRange = patternInput_1[1];
    const dateRange = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Array(0));
    const setAppointments = patternInput_2[1];
    const appointments = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(new Array(0));
    const snackbar_1 = useSnackbar();
    console.log(`Current daterange: ${dateRange}`);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            const startDate_1 = getDayStart(dateRange.start);
            const endDate_1 = getDayEnd(dateRange.end);
            const adis = ofArray(map((x) => (new AdiId(x.resourceId)), resources));
            return singleton.Bind(api.GetAvailabilityCalendarEventsByAdi(adis, fromDate(startDate_1), fromDate(endDate_1)), (_arg) => {
                const data = _arg;
                const metadata_2 = [new AppointmentId(0), new DossierCode("")];
                if (data.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar_1, `Een onbekende fout is opgetreden bij het ophalen van de beschikbaarheden. Gelieve support te contacteren. [${data.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
                else {
                    patternInput[1](toArray(map_1((e_1) => (new CalendarEvent$1(false, "", e_1.StartTime, e_1.EndTime, e_1.AdiId.Value, metadata_2)), data.fields[0])));
                    return singleton.Zero();
                }
            });
        }));
    }, [dateRange, resources]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            const startDate_2 = getDayStart(dateRange.start);
            const endDate_2 = getDayEnd(dateRange.end);
            return singleton.Bind(api.GetAppointmentsAndPlaceDescriptionDetailsInRange(fromDate(startDate_2), fromDate(endDate_2), void 0, new AppointmentListType(3), new PagingQuery(0, 999), void 0), (_arg_1) => {
                const data_1 = _arg_1;
                if (data_1.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar_1, `Een onbekende fout is opgetreden bij het ophalen van de afspraken. Gelieve support te contacteren. [${data_1.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
                else {
                    setAppointments(toArray(map_1((e_2) => {
                        let address;
                        if (e_2.tag === 1) {
                            const e_4 = e_2.fields[0];
                            return new CalendarEvent$1(false, e_4.Appointment.Note, e_4.Appointment.StartTime, e_4.Appointment.EndTime, e_4.AdiId.Value, [new AppointmentId(e_4.Appointment.Id.Value), new DossierCode("0")]);
                        }
                        else {
                            const e_3 = e_2.fields[0];
                            return new CalendarEvent$1(false, (address = e_3.Dossier.Address, `${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.City)}-${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.PostalCode)}`), e_3.Appointment.StartTime, e_3.Appointment.EndTime, e_3.AdiId.Value, [e_3.Appointment.Id, e_3.Dossier.Code]);
                        }
                    }, data_1.fields[0].Data)));
                    return singleton.Zero();
                }
            });
        }));
    }, [dateRange, resources]);
    const combinedEvents = append(appointments, patternInput_3[0]);
    return createElement("div", createObj(ofArray([["className", join(" ", [appointmentCal])], (elems = [Scheduler([new CalendarProp$2(4, availabilities), new CalendarProp$2(3, combinedEvents), new CalendarProp$2(27, (event_1, start_4, end_3, isSelected) => {
        const baseStyle = {
            className: "",
        };
        const placeDescrStyle = {
            className: "place-descr-event",
        };
        const dossierCode_2 = event_1.metaData[1];
        if (isNullOrWhiteSpace(dossierCode_2.Value) ? true : (dossierCode_2.Value === "0")) {
            return placeDescrStyle;
        }
        else {
            return baseStyle;
        }
    }), new CalendarProp$2(29, true), new CalendarProp$2(24, 30), new CalendarProp$2(25, 2), new CalendarProp$2(30, setMinutes(setHours(now(), 7), 0)), new CalendarProp$2(31, setMinutes(setHours(now(), 22), 0)), CalendarProp$2_DayLayoutAlgorithm_Z3A793B44("no-overlap"), new CalendarProp$2(41, resources), new CalendarProp$2(42, (r) => r.resourceId), new CalendarProp$2(43, (r_1) => r_1.resourceTitle), new CalendarProp$2(9, (info) => {
        let array_1, metadata, array_3;
        if (!readOnly) {
            const endTime = info.end;
            if ((((array_1 = availabilities.filter((d) => {
                if (d.resourceId === info.resourceId) {
                    const date2 = [d.start, d.end];
                    const date1 = [info.start, endTime];
                    const date1Start = date1[0];
                    const date1End = date1[1];
                    const date2Start = date2[0];
                    const date2End = date2[1];
                    if (((compare(date1Start, date2End) <= 0) && (compare(date1Start, date2Start) >= 0)) && (compare(date1End, date2Start) >= 0)) {
                        return compare(date1End, date2End) <= 0;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }), array_1.length)) > 0) && (!((metadata = [new AppointmentId(0), dossierCode], ((array_3 = appointments.filter((e) => {
                if (((e.resourceId === info.resourceId) && (compare(info.start, e.start) >= 0)) && (compare(info.end, e.end) <= 0)) {
                    return !equals(e.metaData[1], dossierCode);
                }
                else {
                    return false;
                }
            }), array_3.length)) > 0)))) {
                const calEvent = new CalendarEvent$1(false, "Afspraak", info.start, endTime, info.resourceId, [new AppointmentId(0), dossierCode]);
                const appointment = new Appointment(new AppointmentId(0), new AppointmentStatus(0), dossierCode, dossierId, new AdiId(info.resourceId), calEvent.start, calEvent.end, void 0);
                if (compare(fromDateTimeOffset(info.start, 0), utcNow()) < 0) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar_1, "Opgepast: Je maakt afspraak in het verleden.", SnackbarProp_Variant_Z609E1E86("warning"), new EnqueueSnackbarOption(1, false));
                }
                setAppointment(appointment);
                setAppointments(appointments.filter((a) => (!equals(a.metaData[1], dossierCode))));
                patternInput_3[1]([calEvent]);
            }
        }
    }), new CalendarProp$2(11, (calendarEvent, event) => {
        if (enableOpenDossierLink) {
            const patternInput_4 = calendarEvent.metaData;
            const dossierCode_1 = patternInput_4[1];
            if ((patternInput_4[0].Value > 0) && (!isNullOrWhiteSpace(dossierCode_1.Value))) {
                const url = RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossierDetail, dossierCode_1.Value)), 1);
                window.open(url, "_blank", "", false);
            }
        }
    }), CalendarProp$2_Selectable_Z1FBCCD16(!readOnly), CalendarProp$2_OnRangeChange_Z33829B90((dates) => {
        if (isArrayLike(dates)) {
            const w = dates;
            setDateRange(new DateRange(weekStartDate(head(w)), weekEndDate(last(w))));
        }
        else {
            const st = dates;
            setDateRange(new DateRange(weekStartDate(st.start), weekEndDate(st.end)));
        }
    }), new CalendarProp$2(44, "week")])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ScheduleCalendarByCode(scheduleCalendarByCodeInputProps) {
    const enableOpenDossierLink = scheduleCalendarByCodeInputProps.enableOpenDossierLink;
    const readOnly = scheduleCalendarByCodeInputProps.readOnly;
    const setAppointment = scheduleCalendarByCodeInputProps.setAppointment;
    const dossierCode = scheduleCalendarByCodeInputProps.dossierCode;
    const resources = scheduleCalendarByCodeInputProps.resources;
    const appointmentLengthInMinutes = scheduleCalendarByCodeInputProps.appointmentLengthInMinutes;
    const api = scheduleCalendarByCodeInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossier(dossierCode), [dossierCode]);
    return createElement(DisplayDeferred, {
        data: data,
        view: (dossier) => {
            let patternInput;
            if (dossier == null) {
                patternInput = [new DossierCode(""), new DossierId(0)];
            }
            else {
                const dossier_1 = dossier;
                patternInput = [dossier_1.Code, dossier_1.Id];
            }
            return createElement(ScheduleCalendar, {
                api: api,
                appointmentLengthInMinutes: appointmentLengthInMinutes,
                resources: resources,
                dossierCode: patternInput[0],
                dossierId: patternInput[1],
                setAppointment: setAppointment,
                readOnly: readOnly,
                enableOpenDossierLink: enableOpenDossierLink,
            });
        },
    });
}

export function AppointmentScheduler(appointmentSchedulerInputProps) {
    const enableOpenDossierLink = appointmentSchedulerInputProps.enableOpenDossierLink;
    const readOnly = appointmentSchedulerInputProps.readOnly;
    const setAppointment = appointmentSchedulerInputProps.setAppointment;
    const resources = appointmentSchedulerInputProps.resources;
    const appointmentLengthInMinutes = appointmentSchedulerInputProps.appointmentLengthInMinutes;
    const dossierCode = appointmentSchedulerInputProps.dossierCode;
    const api = appointmentSchedulerInputProps.api;
    const startDate = weekStartDate(today());
    const endDate = weekEndDate(today());
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAvailabilityCalendarEventsByAdi(ofArray(map((x) => (new AdiId(x.resourceId)), resources)), fromDate(startDate), fromDate(endDate)), [resources]);
    return createElement(DisplayDeferred, {
        data: data,
        view: (events) => {
            const avialabilityList = toArray(map_1((e) => (new CalendarEvent$1(false, "", e.StartTime, e.EndTime, e.AdiId.Value, e.AdiId.Value)), events));
            return createElement(ScheduleCalendarByCode, {
                api: api,
                appointmentLengthInMinutes: appointmentLengthInMinutes,
                resources: resources,
                dossierCode: dossierCode,
                setAppointment: setAppointment,
                readOnly: readOnly,
                enableOpenDossierLink: enableOpenDossierLink,
            });
        },
    });
}

