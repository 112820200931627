import { collect, empty, singleton, append, delay, toList } from "../../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Row, Col } from "../../Components/UtilComponents.fs.js";
import { alignStart, hideInPrint, flexExpand, hideShadowInPrint, Gap, cursorPointer, TableHeaderCell, alignEnd, MarginBottom, MarginTop, MarginRight } from "../../Styles/Utils.fs.js";
import { empty as empty_1, sortByDescending, length, map as map_1, toArray, singleton as singleton_1, ofArray } from "../../client/src/fable_modules/fable-library.3.7.17/List.js";
import { MuiHelpers_createElement } from "../../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { isNullOrWhiteSpace, printf, toConsole, join } from "../../client/src/fable_modules/fable-library.3.7.17/String.js";
import { PagingQuery } from "../../shared/Paging.fs.js";
import { Msg, searchTerms } from "./DossierFilter.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { DisplayDiscount, CheckBox, DisplayData, InfoTitle, UrgentChip, CibFormChip, PartnerChip, DownloadLink, Link, CheckIcon, MultiSelect, TableHeader, FilterField } from "../DesignSystem.fs.js";
import { map, defaultArg } from "../../client/src/fable_modules/fable-library.3.7.17/Option.js";
import TableCell from "@material-ui/core/TableCell";
import { Interop_reactApi } from "../../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { FilterFieldDateRange, ToggleFilter } from "../FilterUtils.fs.js";
import { InspectionLocation_extra, InspectionLocation_type$0027, Compensation_compensation, GeneralFormats_dateTimeStampShort, Address_address, Customer2_name, GeneralFormats_dateTimeStamp, Dossier_status } from "../../shared/Format.fs.js";
import { CommentSource as CommentSource_1, InspectionLocationExtra, OwnerStatus__get_toString, KboNumber___UnsafeUnwrap_146F846E, PhoneNumber___UnsafeUnwrap_23E45174, Email___UnsafeUnwrap_Z27D11339, Customer2__get_Code, HomeVisitContactModule_string, InvoicePricingModule_string, NonEmptyString___UnsafeUnwrap_Z73AD07C, DossierStatus_get_list } from "../../shared/Domain.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "../DisplayDeferred.fs.js";
import { DossierStatusChip, noContentMessage } from "../DossierUtils.fs.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../../client/src/fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { year, fromDateTimeOffset } from "../../client/src/fable_modules/fable-library.3.7.17/Date.js";
import { Dossier_contact, Dossier_contactPhoneNumber } from "../../shared/DomainHelpers.fs.js";
import { comparePrimitives } from "../../client/src/fable_modules/fable-library.3.7.17/Util.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { useFeliz_React__React_useState_Static_1505 } from "../../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../../CustomHooks/CustomHooks.fs.js";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../shared/Auth0Constants.fs.js";
import Paper from "@material-ui/core/Paper";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../../client/src/fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B } from "../../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow } from "../../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import Divider from "@material-ui/core/Divider";
import { date as date_1 } from "../../client/src/fable_modules/fable-library.3.7.17/DateOffset.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import { QuoteInvoice } from "../quote/QuoteInvoiceDetails.fs.js";
import { CommentTypeTabs, CommentEditor, CommentList } from "../CommentEditor.fs.js";
import { CommentData } from "../../shared/Api.fs.js";
import Save from "@material-ui/icons/Save";

export function DossiersData(dossiersDataInputProps) {
    const klantNameHeader = dossiersDataInputProps.klantNameHeader;
    const isBackOffice = dossiersDataInputProps.isBackOffice;
    const showCibFilter = dossiersDataInputProps.showCibFilter;
    const optionalExportCsvFunction = dossiersDataInputProps.optionalExportCsvFunction;
    const showPartnerName = dossiersDataInputProps.showPartnerName;
    const url = dossiersDataInputProps.url;
    const dispatch = dossiersDataInputProps.dispatch;
    const state = dossiersDataInputProps.state;
    const data = dossiersDataInputProps.data;
    const klantNameHeader_1 = (klantNameHeader == null) ? "Klant Naam" : klantNameHeader;
    const xs_3 = toList(delay(() => {
        let matchValue, exportCsvFunction;
        return append((matchValue = optionalExportCsvFunction, (matchValue == null) ? singleton(null) : ((exportCsvFunction = matchValue, singleton(createElement(Col, {
            classes: ofArray([MarginRight.S, MarginTop.S, MarginBottom.M]),
            children: singleton_1(MuiHelpers_createElement(Button, [["className", join(" ", [alignEnd])], ["target", "_blank"], ["href", "#"], ["variant", "contained"], ["color", "secondary"], ["onClick", (e) => {
                e.preventDefault();
                e.stopPropagation();
                exportCsvFunction(new PagingQuery(state.PageNumber, state.PageSize))(searchTerms(state));
            }], ["children", "Exporteren naar csv-bestand"]])),
        }))))), delay(() => {
            let elems_29, elems_28, children_13, children_12;
            return append(singleton(MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_29 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_28 = [(children_13 = singleton_1((children_12 = toList(delay(() => {
                let children;
                return append(singleton((children = singleton_1(createElement(Col, {
                    classes: singleton_1(TableHeaderCell),
                    children: ofArray([FilterField("Filter", (arg_2) => {
                        dispatch(new Msg(0, arg_2));
                    }, defaultArg(map((d) => d.Value, state.DossierCode), "")), createElement(TableHeader, {
                        text: "Dossier nummer",
                    })]),
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), delay(() => {
                    let children_1;
                    return append(singleton((children_1 = singleton_1(createElement(Col, {
                        classes: singleton_1(TableHeaderCell),
                        children: ofArray([createElement(ToggleFilter, {
                            label: "",
                            value: state.Urgent,
                            onChange: (arg_4) => {
                                dispatch(new Msg(11, arg_4));
                            },
                            labelLeft: "Ja",
                            valueLeft: true,
                            labelRight: "Nee",
                            valueRight: false,
                        }), createElement(TableHeader, {
                            text: "Dringend",
                        })]),
                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), delay(() => {
                        let children_2;
                        return append(singleton((children_2 = singleton_1(createElement(Col, {
                            classes: singleton_1(TableHeaderCell),
                            children: ofArray([FilterFieldDateRange("Filter", (arg_6) => {
                                dispatch(new Msg(8, arg_6));
                            }, state.DateRange), createElement(TableHeader, {
                                text: "Datum en tijd",
                            })]),
                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), delay(() => {
                            let children_3;
                            return append(singleton((children_3 = singleton_1(createElement(Col, {
                                classes: singleton_1(TableHeaderCell),
                                children: ofArray([createElement(MultiSelect, {
                                    label: "Filter",
                                    items: toArray(map_1(Dossier_status, DossierStatus_get_list())),
                                    v: state.Status,
                                    onChange: (arg_8) => {
                                        dispatch(new Msg(6, arg_8));
                                    },
                                }), createElement(TableHeader, {
                                    text: "Status",
                                })]),
                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), delay(() => {
                                let children_4;
                                return append(showCibFilter ? singleton((children_4 = singleton_1(createElement(Col, {
                                    classes: singleton_1(TableHeaderCell),
                                    children: ofArray([createElement(ToggleFilter, {
                                        label: "",
                                        value: state.CibFilter,
                                        onChange: (arg_10) => {
                                            dispatch(new Msg(9, arg_10));
                                        },
                                        labelLeft: "Ja",
                                        valueLeft: true,
                                        labelRight: "Nee",
                                        valueRight: false,
                                    }), createElement(TableHeader, {
                                        text: "CIB",
                                    })]),
                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))) : empty(), delay(() => {
                                    let children_5;
                                    return append(showPartnerName ? singleton((children_5 = singleton_1(createElement(Col, {
                                        classes: singleton_1(TableHeaderCell),
                                        children: ofArray([FilterField("Filter", (arg_12) => {
                                            dispatch(new Msg(2, arg_12));
                                        }, defaultArg(state.PartnerName, "")), createElement(TableHeader, {
                                            text: "Partner Naam",
                                        })]),
                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))) : empty(), delay(() => {
                                        let children_6;
                                        return append(isBackOffice ? singleton((children_6 = singleton_1(createElement(Col, {
                                            classes: singleton_1(TableHeaderCell),
                                            children: ofArray([createElement(ToggleFilter, {
                                                label: "",
                                                value: state.PartnerPortal,
                                                onChange: (arg_14) => {
                                                    dispatch(new Msg(10, arg_14));
                                                },
                                                labelLeft: "Ja",
                                                valueLeft: true,
                                                labelRight: "Nee",
                                                valueRight: false,
                                            }), createElement(TableHeader, {
                                                text: "Partner Portaal",
                                            })]),
                                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))) : empty(), delay(() => {
                                            let children_7;
                                            return append(singleton((children_7 = singleton_1(createElement(Col, {
                                                classes: singleton_1(TableHeaderCell),
                                                children: ofArray([FilterField("Filter", (arg_16) => {
                                                    dispatch(new Msg(1, arg_16));
                                                }, defaultArg(state.AdiName, "")), createElement(TableHeader, {
                                                    text: "Adi Naam",
                                                })]),
                                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), delay(() => {
                                                let children_8;
                                                return append(singleton((children_8 = singleton_1(createElement(Col, {
                                                    classes: singleton_1(TableHeaderCell),
                                                    children: ofArray([FilterField("Filter", (arg_18) => {
                                                        dispatch(new Msg(3, arg_18));
                                                    }, defaultArg(state.CustomerName, "")), createElement(TableHeader, {
                                                        text: klantNameHeader_1,
                                                    })]),
                                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))), delay(() => {
                                                    let children_9;
                                                    return append(singleton((children_9 = singleton_1(createElement(Col, {
                                                        classes: singleton_1(TableHeaderCell),
                                                        children: ofArray([FilterField("Filter", (arg_20) => {
                                                            dispatch(new Msg(4, arg_20));
                                                        }, defaultArg(state.Address, "")), createElement(TableHeader, {
                                                            text: "Inspectielocatie",
                                                        })]),
                                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]]))), delay(() => {
                                                        let children_10;
                                                        return append(singleton((children_10 = singleton_1(createElement(Col, {
                                                            classes: singleton_1(TableHeaderCell),
                                                            children: ofArray([FilterField("Filter", (arg_22) => {
                                                                dispatch(new Msg(5, arg_22));
                                                            }, defaultArg(state.PhoneNumber, "")), createElement(TableHeader, {
                                                                text: "Tel contactpersoon",
                                                            })]),
                                                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]]))), delay(() => {
                                                            let children_11;
                                                            return isBackOffice ? singleton((children_11 = singleton_1(createElement(Col, {
                                                                classes: singleton_1(TableHeaderCell),
                                                                children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                                    text: "Particuliere Prijzen",
                                                                })]),
                                                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]]))) : empty();
                                                        }));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            })), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]])), createElement(DisplayDeferred, {
                data: data,
                view: (dossierDetails) => {
                    const xs_2 = toList(delay(() => {
                        let children_26;
                        return (length(dossierDetails.Data) === 0) ? singleton(noContentMessage(12, "Er zijn nog geen dossiers")) : singleton((children_26 = toList(delay(() => collect((detail) => {
                            let elems_26;
                            let adiName;
                            const matchValue_1 = [detail.AdiFirstName, detail.AdiLastName];
                            let pattern_matching_result, firstName, lastName;
                            if (matchValue_1[0] != null) {
                                if (matchValue_1[1] != null) {
                                    pattern_matching_result = 0;
                                    firstName = matchValue_1[0];
                                    lastName = matchValue_1[1];
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                            switch (pattern_matching_result) {
                                case 0: {
                                    const firstName_1 = NonEmptyString___UnsafeUnwrap_Z73AD07C(firstName);
                                    const lastName_1 = NonEmptyString___UnsafeUnwrap_Z73AD07C(lastName);
                                    adiName = (`${firstName_1} ${lastName_1}`);
                                    break;
                                }
                                case 1: {
                                    adiName = (void 0);
                                    break;
                                }
                            }
                            return singleton(MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
                                window.open(RouterModule_encodeParts(ofArray(url(detail.Dossier.Code)), 1), "_blank");
                            }], ["key", detail.Dossier.Id.Value], (elems_26 = toList(delay(() => append(singleton(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([detail.Dossier.Code.Value])]])), delay(() => {
                                let children_15;
                                return append(singleton((children_15 = singleton_1(createElement(CheckIcon, {
                                    props: [],
                                    value: detail.Dossier.IsUrgent,
                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_15))]]))), delay(() => {
                                    let children_16;
                                    return append(singleton((children_16 = toList(delay(() => {
                                        const matchValue_2 = detail.DossierCreationDate;
                                        return (matchValue_2 == null) ? singleton(" - ") : singleton(GeneralFormats_dateTimeStamp(fromDateTimeOffset(matchValue_2, 0)));
                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_16))]]))), delay(() => {
                                        let children_17;
                                        return append(singleton((children_17 = singleton_1(createElement(DossierStatusChip, {
                                            status: detail.Dossier.Status,
                                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_17))]]))), delay(() => {
                                            let children_18;
                                            return append(showCibFilter ? singleton((children_18 = singleton_1(createElement(CheckIcon, {
                                                props: [],
                                                value: detail.Dossier.IsCibDossier,
                                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_18))]]))) : empty(), delay(() => {
                                                let children_19;
                                                return append(showPartnerName ? singleton((children_19 = toList(delay(() => {
                                                    const matchValue_3 = detail.Partner;
                                                    return (matchValue_3 == null) ? singleton("-") : singleton(NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_3.CompanyName));
                                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_19))]]))) : empty(), delay(() => {
                                                    let children_20;
                                                    return append(isBackOffice ? singleton((children_20 = singleton_1(createElement(CheckIcon, {
                                                        props: [],
                                                        value: detail.Dossier.IsPartnerPortalDossier,
                                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_20))]]))) : empty(), delay(() => {
                                                        let children_21;
                                                        return append(singleton((children_21 = toList(delay(() => ((adiName == null) ? singleton("-") : singleton(adiName)))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_21))]]))), delay(() => {
                                                            let children_22;
                                                            return append(singleton((children_22 = singleton_1(Customer2_name(detail.Customer)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_22))]]))), delay(() => {
                                                                let children_23;
                                                                return append(singleton((children_23 = singleton_1(Address_address(detail.Dossier.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_23))]]))), delay(() => {
                                                                    let children_24;
                                                                    return append(singleton((children_24 = singleton_1(Dossier_contactPhoneNumber(detail.Dossier, detail.Partner)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_24))]]))), delay(() => {
                                                                        let children_25, value_63;
                                                                        return isBackOffice ? singleton((children_25 = singleton_1((value_63 = (detail.Dossier.InvoicePricing.tag === 0), createElement(CheckIcon, {
                                                                            props: [],
                                                                            value: value_63,
                                                                        }))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_25))]]))) : empty();
                                                                    }));
                                                                }));
                                                            }));
                                                        }));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]));
                        }, sortByDescending((s) => s.Dossier.Id.Value, dossierDetails.Data, {
                            Compare: comparePrimitives,
                        })))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_26))]])));
                    }));
                    return react.createElement(react.Fragment, {}, ...xs_2);
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])), delay(() => singleton(createElement(Col, {
                classes: empty_1(),
                children: singleton_1(createElement(DisplayDeferred, {
                    data: data,
                    view: (dossiers) => {
                        let values;
                        return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", state.PageSize], ["page", state.PageNumber], ["count", dossiers.TotalPages], ["onChangePage", (_arg_1, v) => {
                            dispatch(new Msg(7, v));
                        }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_78) => {
                        }]]);
                    },
                })),
            }))));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

export function DisplayDossierInfo(displayDossierInfoInputProps) {
    let appointment_2, appartmentUrl_1, appointment_1;
    const quoteDetails = displayDossierInfoInputProps.quoteDetails;
    const signatureink = displayDossierInfoInputProps.signatureink;
    const onSaveComment = displayDossierInfoInputProps.onSaveComment;
    const isPartner = displayDossierInfoInputProps.isPartner;
    const appartmentUrl = displayDossierInfoInputProps.appartmentUrl;
    const partnerUrl = displayDossierInfoInputProps.partnerUrl;
    const customerUrl = displayDossierInfoInputProps.customerUrl;
    const setCommentType = displayDossierInfoInputProps.setCommentType;
    const commentType = displayDossierInfoInputProps.commentType;
    const comments = displayDossierInfoInputProps.comments;
    const downloadFile = displayDossierInfoInputProps.downloadFile;
    const sampledUploaded = displayDossierInfoInputProps.sampledUploaded;
    const auth0User = displayDossierInfoInputProps.auth0User;
    const dossierDetails = displayDossierInfoInputProps.dossierDetails;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setComment = patternInput[1];
    const comment = patternInput[0];
    const commentButtonDisabled = useToggle(false);
    const appointmentId = map((appointment) => appointment.Id, dossierDetails.Appointment);
    let appointmentLink;
    const matchValue = [dossierDetails.Appointment, appartmentUrl];
    appointmentLink = ((matchValue[0] != null) ? ((matchValue[1] == null) ? ((appointment_2 = matchValue[0], `${GeneralFormats_dateTimeStamp(fromDateTimeOffset(appointment_2.StartTime, 0))}`)) : ((appartmentUrl_1 = matchValue[1], (appointment_1 = matchValue[0], createElement(Link, {
        text: `${GeneralFormats_dateTimeStamp(fromDateTimeOffset(appointment_1.StartTime, 0))}`,
        url: appartmentUrl_1(appointment_1.Id),
    }))))) : "-");
    let assignmentDocumentLink;
    const matchValue_1 = dossierDetails.Dossier.AssignmentConfirmationPhysicallySigned;
    if (matchValue_1 == null) {
        const matchValue_2 = dossierDetails.Dossier.AssigmentConfirmation;
        if (matchValue_2 == null) {
            assignmentDocumentLink = null;
        }
        else {
            const assignmentConfirmation = matchValue_2;
            assignmentDocumentLink = createElement(DownloadLink, {
                text: "Verzonden (niet getekend)",
                onDownload: () => {
                    downloadFile(assignmentConfirmation);
                },
            });
        }
    }
    else {
        const assignmentDocumentSigned = matchValue_1;
        assignmentDocumentLink = createElement(DownloadLink, {
            text: "Verzonden (digitaal getekend)",
            onDownload: () => {
                downloadFile(assignmentDocumentSigned);
            },
        });
    }
    const isAdmin = (auth0User == null) ? false : Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0));
    return createElement(Row, {
        classes: singleton_1(Gap.L),
        children: toList(delay(() => append(singleton(createElement(Col, {
            classes: singleton_1(Gap.L),
            children: toList(delay(() => append(singleton(createElement(Row, {
                classes: singleton_1(Gap.S),
                children: toList(delay(() => {
                    let matchValue_3, partner, url;
                    return append((matchValue_3 = [isPartner, dossierDetails.Partner, partnerUrl], matchValue_3[0] ? singleton(null) : ((matchValue_3[1] != null) ? ((matchValue_3[2] != null) ? ((partner = matchValue_3[1], (url = matchValue_3[2], append(singleton(createElement(Col, {
                        classes: empty_1(),
                        children: singleton_1(createElement(PartnerChip, {
                            partner: partner,
                            onClick: () => {
                                RouterModule_nav(ofArray(url(partner.Id)), 1, 1);
                            },
                        })),
                    })), delay(() => singleton(createElement(Col, {
                        classes: empty_1(),
                        children: singleton_1(createElement(CibFormChip, {
                            isCibDossier: dossierDetails.Dossier.IsCibDossier,
                            onClick: void 0,
                        })),
                    }))))))) : singleton(null)) : singleton(null))), delay(() => singleton(createElement(Col, {
                        classes: empty_1(),
                        children: singleton_1(createElement(UrgentChip, {
                            isUrgent: dossierDetails.Dossier.IsUrgent,
                        })),
                    }))));
                })),
            })), delay(() => append(singleton(createElement(Row, {
                classes: singleton_1(Gap.L),
                children: toList(delay(() => {
                    let elems;
                    return append(singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), ["className", join(" ", [hideShadowInPrint])], (elems = [createElement(Col, {
                        classes: empty_1(),
                        children: ofArray([createElement(InfoTitle, {
                            text: "Asbestattest",
                        }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                            data: toList(delay(() => append(singleton(["Status", createElement(DossierStatusChip, {
                                status: dossierDetails.Dossier.Status,
                            })]), delay(() => {
                                let invoicePaymentReceivedDate, status, dateStr;
                                return append(singleton(["Factuur", (invoicePaymentReceivedDate = dossierDetails.InvoicePaymentReceivedDate, (status = dossierDetails.Dossier.Status, (status.tag === 5) ? "Verzonden" : ((status.tag === 6) ? ((invoicePaymentReceivedDate == null) ? "Betaald" : ((dateStr = GeneralFormats_dateTimeStampShort(date_1(invoicePaymentReceivedDate)), `Betaald (${dateStr})`))) : ((status.tag === 7) ? ((invoicePaymentReceivedDate == null) ? "Betaald" : ((dateStr = GeneralFormats_dateTimeStampShort(date_1(invoicePaymentReceivedDate)), `Betaald (${dateStr})`))) : "-"))))]), delay(() => append(singleton(["Afspraak", appointmentLink]), delay(() => {
                                    let matchValue_4, ai;
                                    return append((matchValue_4 = dossierDetails.Dossier.TypeOfDossier, (matchValue_4.tag === 1) ? ((empty())) : singleton(["Aanleiding asbestinventaris", (ai = matchValue_4.fields[0], (ai.tag === 1) ? "Werken" : ((ai.tag === 2) ? "Actualisatie asbestinventaris-attest" : ((ai.tag === 3) ? "Sloop zonder SOP" : ((ai.tag === 4) ? "Sloop met SOP" : ((ai.tag === 5) ? "Werkgever" : ((ai.tag === 6) ? "Overdracht met vrijwillig, aanvullend onderzoek" : ((ai.tag === 7) ? "Generiek" : ((ai.tag === 8) ? "Generiek met vrijwillig, aanvullend onderzoek" : ((ai.tag === 9) ? "Gemene delen" : "Overdracht")))))))))])), delay(() => {
                                        let matchValue_6;
                                        return append((dossierDetails.Dossier.Status.tag === 8) ? append((matchValue_6 = dossierDetails.Dossier.InBehandelingUsername, (matchValue_6 == null) ? ((empty())) : singleton(["In behandeling door", matchValue_6])), delay(() => {
                                            if (dossierDetails.Dossier.InBehandelingAdiId == null) {
                                                return empty();
                                            }
                                            else {
                                                const adiFirstName = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.InBehandelingAdiFirstName), "");
                                                const adiLastName = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.InBehandelingAdiLastName), "");
                                                return singleton(["In behandeling Adi", `${adiFirstName} ${adiLastName}`]);
                                            }
                                        })) : ((empty())), delay(() => {
                                            let matchValue_9;
                                            return append((dossierDetails.Dossier.PartnerId == null) ? ((empty())) : singleton(["Partner Vergoedingen", (matchValue_9 = dossierDetails.Dossier.CompensationAmount, (matchValue_9 == null) ? "Geen Vergoedingen" : Compensation_compensation(matchValue_9))]), delay(() => append(singleton(["Tarieven van prijzen", InvoicePricingModule_string(dossierDetails.Dossier.InvoicePricing)]), delay(() => append(singleton(["Huisbezoek", HomeVisitContactModule_string(dossierDetails.Dossier.HomeVisitContact)]), delay(() => append((dossierDetails.Dossier.HomeVisitContact.tag === 3) ? append(singleton(["Huisbezoek Naam", defaultArg(map((n) => n, dossierDetails.Dossier.HomeVisitContactName), "")]), delay(() => singleton(["Huisbezoek Telefoon", defaultArg(map((n_1) => n_1, dossierDetails.Dossier.HomeVisitContactEmail), "")]))) : ((empty())), delay(() => {
                                                let copyOfStruct;
                                                const matchValue_11 = dossierDetails.Dossier.QuoteApprovalMeetingDate;
                                                let pattern_matching_result, meetingDate_1;
                                                if (matchValue_11 != null) {
                                                    if (((copyOfStruct = date_1(matchValue_11), year(copyOfStruct))) > 2000) {
                                                        pattern_matching_result = 0;
                                                        meetingDate_1 = matchValue_11;
                                                    }
                                                    else {
                                                        pattern_matching_result = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result = 1;
                                                }
                                                switch (pattern_matching_result) {
                                                    case 0: {
                                                        return singleton(["Goedkeuring algemene vergadering", GeneralFormats_dateTimeStampShort(fromDateTimeOffset(meetingDate_1, 0))]);
                                                    }
                                                    case 1: {
                                                        return empty();
                                                    }
                                                }
                                            }))))))));
                                        }));
                                    }));
                                }))));
                            })))),
                        })]),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])), delay(() => {
                        let elems_1;
                        return append(singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), ["className", join(" ", [hideShadowInPrint])], (elems_1 = [createElement(Col, {
                            classes: empty_1(),
                            children: ofArray([createElement(InfoTitle, {
                                text: "Algemeen",
                            }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                                data: toList(delay(() => append(singleton(["Dossier nummer", dossierDetails.Dossier.Code.Value]), delay(() => append(singleton(["Klant", createElement(Link, {
                                    text: Customer2_name(dossierDetails.Customer),
                                    url: customerUrl(Customer2__get_Code(dossierDetails.Customer)),
                                })]), delay(() => {
                                    let client;
                                    return append(singleton(["Opdrachtgever", (client = dossierDetails.Dossier.Client, (client.tag === 1) ? "Gevolmachtigde" : ((client.tag === 2) ? "Vereniging van mede-eigenaars" : "Eigenaar"))]), delay(() => append((!isPartner) ? singleton(["Geen standaard dossier", CheckBox(dossierDetails.Dossier.ManualInvoice)]) : empty(), delay(() => (isAdmin ? singleton(["Korting", DisplayDiscount(dossierDetails.Dossier.Discount)]) : empty())))));
                                })))))),
                            })]),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])), delay(() => {
                            let elems_2;
                            let vme;
                            const matchValue_12 = dossierDetails.Dossier.VME_OrganizationName;
                            if (matchValue_12 == null) {
                                vme = ["", null];
                            }
                            else {
                                const organizationName = matchValue_12;
                                vme = ["", ""];
                            }
                            const otherOwnerStatus = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.OwnerStatusOther), "");
                            return singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), ["className", join(" ", [hideShadowInPrint])], (elems_2 = [createElement(Col, {
                                classes: empty_1(),
                                children: ofArray([createElement(InfoTitle, {
                                    text: "Eigenaar",
                                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                                    data: toList(delay(() => {
                                        let matchValue_13;
                                        return append((matchValue_13 = dossierDetails.Dossier.Owner_CompanyName, (matchValue_13 == null) ? ((empty())) : singleton(["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_13)])), delay(() => {
                                            const ownerFirstname = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.Owner_FirstName), "");
                                            const ownerLastname = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.Owner_LastName), "");
                                            return append(singleton([dossierDetails.Dossier.Owner_IsVME ? "Beheerder" : "Beheerder", `${ownerFirstname} ${ownerLastname}`]), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(dossierDetails.Dossier.Owner_Email)]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, dossierDetails.Dossier.Owner_PhoneNumber), "-")]), delay(() => append(singleton(["Adres", Address_address(dossierDetails.Dossier.Owner_Address)]), delay(() => append((!isPartner) ? singleton(["VME KBO nummer", defaultArg(map(KboNumber___UnsafeUnwrap_146F846E, dossierDetails.Dossier.VME_KboNumber), "-")]) : empty(), delay(() => {
                                                let matchValue_14;
                                                return append((matchValue_14 = [isPartner, dossierDetails.Dossier.VME_OrganizationName], (matchValue_14[1] == null) ? (matchValue_14[0] ? singleton(["", null]) : singleton(["VME Organisatie Naam", "-"])) : singleton(["VME Organisatie Naam", matchValue_14[1].Value])), delay(() => singleton(["Statuut", (OwnerStatus__get_toString(dossierDetails.Dossier.OwnerStatus) + " ") + otherOwnerStatus])));
                                            }))))))))));
                                        }));
                                    })),
                                })]),
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]));
                        }));
                    }));
                })),
            })), delay(() => {
                let elems_4;
                return append(singleton(createElement(Row, {
                    classes: singleton_1(Gap.L),
                    children: ofArray([MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 3))), ["className", join(" ", [hideShadowInPrint])], (elems_4 = toList(delay(() => append(singleton(createElement(Col, {
                        classes: empty_1(),
                        children: ofArray([createElement(InfoTitle, {
                            text: "Inspectielocatie",
                        }), MuiHelpers_createElement(Divider, []), createElement(Row, {
                            classes: singleton_1(Gap.S),
                            children: ofArray([createElement(Col, {
                                classes: singleton_1(flexExpand),
                                children: singleton_1(createElement(DisplayData, {
                                    data: ofArray([["Type", InspectionLocation_type$0027(dossierDetails.Dossier.InspectionLocation)], ["Straat", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails.Dossier.Address.Street)], ["Nummer", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails.Dossier.Address.Number)], ["Bus", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.Address.Bus), "--")], ["Postcode", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails.Dossier.Address.PostalCode)], ["Gemeente", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails.Dossier.Address.City)]]),
                                })),
                            }), createElement(Col, {
                                classes: singleton_1(flexExpand),
                                children: singleton_1(createElement(DisplayData, {
                                    data: toList(delay(() => append(singleton(["Contact afspraak", Dossier_contact(dossierDetails.Dossier, dossierDetails.Partner)]), delay(() => append(singleton(["Telefoon contactpersoon", Dossier_contactPhoneNumber(dossierDetails.Dossier, dossierDetails.Partner)]), delay(() => {
                                        let matchValue_15;
                                        return append((matchValue_15 = dossierDetails.Dossier.InspectionLocation, (matchValue_15.tag === 14) ? append(singleton(["Berging", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.ResidentialBerging), "0")]), delay(() => singleton(["Garagebox", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.ResidentialGaragebox), "0")]))) : ((matchValue_15.tag === 16) ? append(singleton(["Berging", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.ResidentialBerging), "0")]), delay(() => singleton(["Garagebox", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.ResidentialGaragebox), "0")]))) : ((matchValue_15.tag === 15) ? append(singleton(["Berging", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.ResidentialBerging), "0")]), delay(() => singleton(["Garagebox", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails.Dossier.ResidentialGaragebox), "0")]))) : ((empty()))))), delay(() => {
                                            let matchValue_16;
                                            return append(singleton(["Gebouwdeel/Vleugel/Blok", (matchValue_16 = dossierDetails.Dossier.BuildingDetail, (matchValue_16 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_16))]), delay(() => {
                                                let matchValue_17;
                                                return append(singleton(["Wooneenheden", (matchValue_17 = dossierDetails.Dossier.HousingUnit, (matchValue_17 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_17))]), delay(() => {
                                                    let matchValue_18;
                                                    return append(singleton(["Jaar van constructie", (matchValue_18 = dossierDetails.Dossier.YearsOfConstruction, (matchValue_18 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_18))]), delay(() => {
                                                        let matchValue_19;
                                                        return append(singleton(["Jaar van renovatie", (matchValue_19 = dossierDetails.Dossier.YearsOfRenovation, (matchValue_19 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_19))]), delay(() => {
                                                            let matchValue_20;
                                                            return append(singleton(["Aantal ingangen in het gebouw", (matchValue_20 = dossierDetails.Dossier.NumberOfEntrances, (matchValue_20 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_20))]), delay(() => {
                                                                let matchValue_21;
                                                                return append(singleton(["Leidingisolatie (lopende meter)", (matchValue_21 = dossierDetails.Dossier.PipeIsolationMeters, (matchValue_21 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_21))]), delay(() => {
                                                                    let matchValue_22;
                                                                    return append(singleton(["Gangen per verdiep", (matchValue_22 = dossierDetails.Dossier.HallwaysPerLevel, (matchValue_22 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_22))]), delay(() => {
                                                                        let value_55;
                                                                        return append(singleton(["Stooklokaal", (value_55 = defaultArg(dossierDetails.Dossier.BoilerRoom, false), createElement(CheckIcon, {
                                                                            props: [],
                                                                            value: value_55,
                                                                        }))]), delay(() => {
                                                                            let matchValue_23;
                                                                            return append((matchValue_23 = dossierDetails.Dossier.SharedAreaOption1, (matchValue_23 == null) ? ((empty())) : singleton(["Tellerlokalen", matchValue_23.Value])), delay(() => {
                                                                                let matchValue_24;
                                                                                return append((matchValue_24 = dossierDetails.Dossier.SharedAreaOption2, (matchValue_24 == null) ? ((empty())) : singleton(["Vuilnislokaal", matchValue_24.Value])), delay(() => {
                                                                                    let matchValue_25;
                                                                                    return append((matchValue_25 = dossierDetails.Dossier.SharedAreaOption2, (matchValue_25 == null) ? ((empty())) : singleton(["Fietsberging", matchValue_25.Value])), delay(() => {
                                                                                        const matchValue_26 = dossierDetails.Dossier.SharedAreaOption2;
                                                                                        if (matchValue_26 == null) {
                                                                                            return empty();
                                                                                        }
                                                                                        else {
                                                                                            return singleton(["Gemene Delen Option 4", matchValue_26.Value]);
                                                                                        }
                                                                                    }));
                                                                                }));
                                                                            }));
                                                                        }));
                                                                    }));
                                                                }));
                                                            }));
                                                        }));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    })))))),
                                })),
                            })]),
                        })]),
                    })), delay(() => {
                        const readOnlyCheckboxField = (label, checkedR) => MuiHelpers_createElement(FormControlLabel, [["label", label], ["control", MuiHelpers_createElement(Checkbox, [["disabled", true], ["checked", checkedR]])]]);
                        return append(singleton(createElement(Row, {
                            classes: singleton_1(hideInPrint),
                            children: singleton_1(createElement(Col, {
                                classes: singleton_1(flexExpand),
                                children: toList(delay(() => {
                                    let elements, matchValue_28, matchValue_29, matchValue_30;
                                    const matchValue_27 = dossierDetails.Dossier.InspectionLocation;
                                    switch (matchValue_27.tag) {
                                        case 14:
                                        case 16:
                                        case 15: {
                                            let insData;
                                            const xs_1 = [MuiHelpers_createElement(RadioGroup, [["row", false], (elements = [readOnlyCheckboxField(InspectionLocation_extra(new InspectionLocationExtra(0)), (matchValue_28 = dossierDetails.Dossier.InspectionLocationBerging, (matchValue_28 == null) ? false : matchValue_28)), readOnlyCheckboxField(InspectionLocation_extra(new InspectionLocationExtra(1)), (matchValue_29 = dossierDetails.Dossier.InspectionLocationStaanplaats, (matchValue_29 == null) ? false : matchValue_29)), readOnlyCheckboxField(InspectionLocation_extra(new InspectionLocationExtra(2)), (matchValue_30 = dossierDetails.Dossier.InspectionLocationGaragebox, (matchValue_30 == null) ? false : matchValue_30))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])];
                                            insData = react.createElement(react.Fragment, {}, ...xs_1);
                                            return singleton(createElement(DisplayData, {
                                                data: singleton_1(["Extra ruimtes aanwezig", insData]),
                                            }));
                                        }
                                        default: {
                                            return singleton(null);
                                        }
                                    }
                                })),
                            })),
                        })), delay(() => append(singleton(createElement(InfoTitle, {
                            text: "Eigendom",
                        })), delay(() => append(singleton(MuiHelpers_createElement(Divider, [])), delay(() => {
                            let matchValue_31, file, matchValue_32, file_1, matchValue_33, file_2;
                            return singleton(createElement(Row, {
                                classes: empty_1(),
                                children: singleton_1(createElement(Col, {
                                    classes: singleton_1(flexExpand),
                                    children: singleton_1(createElement(DisplayData, {
                                        data: ofArray([["Kadastrale legger", (matchValue_31 = dossierDetails.Dossier.CadastralRecords, (matchValue_31 == null) ? "-" : ((file = matchValue_31, createElement(DownloadLink, {
                                            text: "Verzonden",
                                            onDownload: () => {
                                                downloadFile(file);
                                            },
                                        }))))], ["Grondplan", (matchValue_32 = dossierDetails.Dossier.Floorplan, (matchValue_32 == null) ? "-" : ((file_1 = matchValue_32, createElement(DownloadLink, {
                                            text: "Verzonden",
                                            onDownload: () => {
                                                downloadFile(file_1);
                                            },
                                        }))))], ["Andere", (matchValue_33 = dossierDetails.Dossier.PropertyOther, (matchValue_33 == null) ? "-" : ((file_2 = matchValue_33, createElement(DownloadLink, {
                                            text: "Verzonden",
                                            onDownload: () => {
                                                downloadFile(file_2);
                                            },
                                        }))))]]),
                                    })),
                                })),
                            }));
                        }))))));
                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement(Col, {
                        classes: singleton_1(Gap.L),
                        children: toList(delay(() => {
                            let copyOfStruct_1, elems_5;
                            let signedDate;
                            const matchValue_34 = dossierDetails.Dossier.AssignmentConfirmationDigitalSignature;
                            if (matchValue_34 == null) {
                                signedDate = "-";
                            }
                            else {
                                const matchValue_35 = matchValue_34.SignedAt;
                                let pattern_matching_result_1, a_2;
                                if (matchValue_35 != null) {
                                    if (((copyOfStruct_1 = fromDateTimeOffset(matchValue_35, 0), year(copyOfStruct_1))) > 1) {
                                        pattern_matching_result_1 = 0;
                                        a_2 = matchValue_35;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        const arg_25 = fromDateTimeOffset(a_2, 0);
                                        toConsole(printf("signed date %A"))(arg_25);
                                        signedDate = GeneralFormats_dateTimeStamp(fromDateTimeOffset(a_2, 0));
                                        break;
                                    }
                                    case 1: {
                                        signedDate = "-";
                                        break;
                                    }
                                }
                            }
                            return append(singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), ["className", join(" ", [hideShadowInPrint])], (elems_5 = [createElement(Col, {
                                classes: empty_1(),
                                children: toList(delay(() => append(singleton(createElement(InfoTitle, {
                                    text: "Opdracht",
                                })), delay(() => append(singleton(MuiHelpers_createElement(Divider, [])), delay(() => singleton(createElement(DisplayData, {
                                    data: toList(delay(() => append(singleton(["Opdrachtformulier", assignmentDocumentLink]), delay(() => append(singleton(["Signed Date", signedDate]), delay(() => {
                                        let matchValue_36, c;
                                        return append((matchValue_36 = dossierDetails.Dossier.Conclusion, (matchValue_36 == null) ? singleton(["Conclusie asbest", "-"]) : singleton(["Conclusie asbest", (c = matchValue_36, (c.tag === 1) ? "Onveilig" : "Veilig")])), delay(() => {
                                            let matchValue_37, file_3;
                                            return append(singleton(["Attest", (matchValue_37 = dossierDetails.Dossier.Certificate, (matchValue_37 == null) ? "-" : ((file_3 = matchValue_37, createElement(DownloadLink, {
                                                text: "Download",
                                                onDownload: () => {
                                                    downloadFile(file_3);
                                                },
                                            }))))]), delay(() => {
                                                const matchValue_38 = signatureink;
                                                if (matchValue_38 == null) {
                                                    return empty();
                                                }
                                                else {
                                                    return singleton(["Tekenen Link", matchValue_38]);
                                                }
                                            }));
                                        }));
                                    })))))),
                                })))))))),
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])), delay(() => {
                                let elems_6, matchValue_39, file_4;
                                return (!isPartner) ? singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), ["className", join(" ", [hideShadowInPrint])], (elems_6 = [createElement(Col, {
                                    classes: empty_1(),
                                    children: ofArray([createElement(InfoTitle, {
                                        text: "Labo",
                                    }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                                        data: ofArray([["Labo", (matchValue_39 = dossierDetails.Dossier.Lab, (matchValue_39 == null) ? "-" : ((file_4 = matchValue_39, createElement(DownloadLink, {
                                            text: "Download",
                                            onDownload: () => {
                                                downloadFile(file_4);
                                            },
                                        }))))], ["Monsters opgeladen", CheckBox(sampledUploaded)]]),
                                    })]),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])) : empty();
                            }));
                        })),
                    })]),
                })), delay(() => {
                    let matchValue_40, appointConfirmation, children_1;
                    return append((matchValue_40 = dossierDetails.AppointmentConfirmation, (matchValue_40 == null) ? ((empty())) : ((appointConfirmation = matchValue_40, singleton((children_1 = singleton_1(createElement(Col, {
                        classes: empty_1(),
                        children: ofArray([createElement(InfoTitle, {
                            text: "Afspraak bevestiging - Extra Monsters",
                        }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                            data: ofArray([["Puntmonster", `${appointConfirmation.PointSampleCount.Value}`], ["Mengmonster", `${appointConfirmation.MixedSampleCount.Value}`], ["Mengmonster leidingisolatie", `${appointConfirmation.MixedSamplePipeInsulationCount.Value}`]]),
                        })]),
                    })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])))))), delay(() => {
                        const matchValue_41 = quoteDetails;
                        return (matchValue_41 == null) ? singleton(null) : singleton(QuoteInvoice(matchValue_41));
                    }));
                }));
            })))))),
        })), delay(() => {
            const showPartnerComments = !(dossierDetails.Partner == null);
            const username_1 = (auth0User == null) ? (void 0) : auth0User.Username;
            return singleton(createElement(Col, {
                classes: empty_1(),
                children: toList(delay(() => {
                    let props_19;
                    const commentElement = createElement(Col, {
                        classes: singleton_1(Gap.S),
                        children: ofArray([createElement(CommentList, {
                            comments: comments,
                            onDeleteComment: void 0,
                        }), createElement(CommentEditor, {
                            comment: comment,
                            setComment: setComment,
                            commentType: commentType,
                        }), createElement(Col, {
                            classes: ofArray([MarginTop.S, MarginBottom.M, hideInPrint]),
                            children: singleton_1(MuiHelpers_createElement(Button, [["className", join(" ", [alignStart])], ["onClick", (_arg) => {
                                if (isNullOrWhiteSpace(comment)) {
                                }
                                else {
                                    const commentData = new CommentData(comment, username_1, isPartner ? (new CommentSource_1(4)) : (new CommentSource_1(1)), commentType);
                                    commentButtonDisabled.On();
                                    onSaveComment(commentData)(() => {
                                        commentButtonDisabled.Off();
                                        setComment("");
                                    });
                                }
                            }], ["disabled", commentButtonDisabled.State], ["variant", "contained"], ["color", "secondary"], ["size", "small"], ["startIcon", (props_19 = {}, react.createElement(Save, props_19))], ["children", "Save Comment"]])),
                        })]),
                    });
                    const showInternalComments = (auth0User == null) ? false : Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0));
                    return singleton(createElement(CommentTypeTabs, {
                        commentType: commentType,
                        setCommentType: setCommentType,
                        content: commentElement,
                        showInternal: showInternalComments,
                        showAdi: !isPartner,
                        showPartner: showPartnerComments,
                    }));
                })),
            }));
        })))),
    });
}

