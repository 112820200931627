import { CssRule$reflection, CssRule, String$, CssRuleWithNone$reflection, CssRuleWithNone, MasterTypeHelpers_stringifyICssValue } from "./MasterTypes.fs.js";
import { unitHelpers_CssRuleWithLength$reflection, unitHelpers_CssRuleWithLength, Angle$reflection, Length$reflection, unitHelpers_lengthPercentageString } from "./Units.fs.js";
import { Union } from "../../fable-library.3.7.17/Types.js";
import { union_type, class_type, int32_type, float64_type } from "../../fable-library.3.7.17/Reflection.js";
import { join } from "../../fable-library.3.7.17/String.js";
import { map } from "../../fable-library.3.7.17/List.js";

export class Transform_Transform extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Matrix", "Matrix3D", "Perspective", "Rotate", "Rotate3D", "RotateX", "RotateY", "RotateZ", "Translate", "Translate2", "Translate3D", "TranslateX", "TranslateY", "TranslateZ", "Scale", "Scale2", "Scale3D", "ScaleX", "ScaleY", "ScaleZ", "Skew", "Skew2", "SkewX", "SkewY"];
    }
    StringifyCss() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return `matrix3d(${this$.fields[0]}, ${this$.fields[1]}, ${this$.fields[2]}, ${this$.fields[3]}, ${this$.fields[4]}, ${this$.fields[5]}, ${this$.fields[6]}, ${this$.fields[7]}, ${this$.fields[8]}, ${this$.fields[9]}, ${this$.fields[10]}, ${this$.fields[11]}, ${this$.fields[12]}, ${this$.fields[13]}, ${this$.fields[14]}, ${this$.fields[15]})`;
            }
            case 2: {
                return `perspective(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 3: {
                return `rotate(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 4: {
                return `rotate3d(${this$.fields[0]}, ${this$.fields[1]}, ${this$.fields[2]}, ${MasterTypeHelpers_stringifyICssValue(this$.fields[3])})`;
            }
            case 5: {
                return `rotateX(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 6: {
                return `rotateY(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 7: {
                return `rotateZ(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 8: {
                return `translate(${unitHelpers_lengthPercentageString(this$.fields[0])})`;
            }
            case 9: {
                return `translate(${unitHelpers_lengthPercentageString(this$.fields[0])}, ${unitHelpers_lengthPercentageString(this$.fields[1])})`;
            }
            case 10: {
                return `translate3d(${unitHelpers_lengthPercentageString(this$.fields[0])}, ${unitHelpers_lengthPercentageString(this$.fields[1])}, ${unitHelpers_lengthPercentageString(this$.fields[2])})`;
            }
            case 11: {
                return `translateX(${unitHelpers_lengthPercentageString(this$.fields[0])})`;
            }
            case 12: {
                return `translateY(${unitHelpers_lengthPercentageString(this$.fields[0])})`;
            }
            case 13: {
                return `translateZ(${unitHelpers_lengthPercentageString(this$.fields[0])})`;
            }
            case 14: {
                return `scale(${this$.fields[0]})`;
            }
            case 15: {
                return `scale(${this$.fields[0]}, ${this$.fields[1]})`;
            }
            case 16: {
                return `scale3d(${this$.fields[0]}, ${this$.fields[1]}, ${this$.fields[2]})`;
            }
            case 17: {
                return `scaleX(${this$.fields[0]})`;
            }
            case 18: {
                return `scaleY(${this$.fields[0]})`;
            }
            case 19: {
                return `scaleZ(${this$.fields[0]})`;
            }
            case 20: {
                return `skew(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 21: {
                return `skew(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])}, ${MasterTypeHelpers_stringifyICssValue(this$.fields[1])})`;
            }
            case 22: {
                return `skewX(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            case 23: {
                return `skewY(${MasterTypeHelpers_stringifyICssValue(this$.fields[0])})`;
            }
            default: {
                return `matrix(${this$.fields[0]}, ${this$.fields[1]}, ${this$.fields[2]}, ${this$.fields[3]}, ${this$.fields[4]}, ${this$.fields[5]})`;
            }
        }
    }
}

export function Transform_Transform$reflection() {
    return union_type("Fss.Types.Transform.Transform", [], Transform_Transform, () => [[["Item1", float64_type], ["Item2", float64_type], ["Item3", float64_type], ["Item4", float64_type], ["Item5", float64_type], ["Item6", float64_type]], [["Item1", int32_type], ["Item2", int32_type], ["Item3", int32_type], ["Item4", int32_type], ["Item5", int32_type], ["Item6", int32_type], ["Item7", int32_type], ["Item8", int32_type], ["Item9", int32_type], ["Item10", int32_type], ["Item11", int32_type], ["Item12", int32_type], ["Item13", float64_type], ["Item14", float64_type], ["Item15", float64_type], ["Item16", float64_type]], [["Item", Length$reflection()]], [["Item", Angle$reflection()]], [["Item1", float64_type], ["Item2", float64_type], ["Item3", float64_type], ["Item4", Angle$reflection()]], [["Item", Angle$reflection()]], [["Item", Angle$reflection()]], [["Item", Angle$reflection()]], [["Item", class_type("Fss.Types.ILengthPercentage")]], [["Item1", class_type("Fss.Types.ILengthPercentage")], ["Item2", class_type("Fss.Types.ILengthPercentage")]], [["Item1", class_type("Fss.Types.ILengthPercentage")], ["Item2", class_type("Fss.Types.ILengthPercentage")], ["Item3", class_type("Fss.Types.ILengthPercentage")]], [["Item", class_type("Fss.Types.ILengthPercentage")]], [["Item", class_type("Fss.Types.ILengthPercentage")]], [["Item", class_type("Fss.Types.ILengthPercentage")]], [["Item", float64_type]], [["Item1", float64_type], ["Item2", float64_type]], [["Item1", float64_type], ["Item2", float64_type], ["Item3", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", Angle$reflection()]], [["Item1", Angle$reflection()], ["Item2", Angle$reflection()]], [["Item", Angle$reflection()]], [["Item", Angle$reflection()]]]);
}

export class Transform_Origin extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Top", "Left", "Right", "Bottom", "Center"];
    }
    StringifyCss() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "left";
            }
            case 2: {
                return "right";
            }
            case 3: {
                return "bottom";
            }
            case 4: {
                return "center";
            }
            default: {
                return "top";
            }
        }
    }
}

export function Transform_Origin$reflection() {
    return union_type("Fss.Types.Transform.Origin", [], Transform_Origin, () => [[], [], [], [], []]);
}

export class Transform_Style extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Flat", "Preserve3d"];
    }
    StringifyCss() {
        const this$ = this;
        return (this$.tag === 1) ? "preserve3d" : "flat";
    }
}

export function Transform_Style$reflection() {
    return union_type("Fss.Types.Transform.Style", [], Transform_Style, () => [[], []]);
}

export class TransformClasses_TransformClass extends CssRuleWithNone {
    constructor(property) {
        super(property);
        this.property_2 = property;
    }
}

export function TransformClasses_TransformClass$reflection() {
    return class_type("Fss.Types.TransformClasses.TransformClass", void 0, TransformClasses_TransformClass, CssRuleWithNone$reflection());
}

export function TransformClasses_TransformClass_$ctor_Z207A3CFB(property) {
    return new TransformClasses_TransformClass(property);
}

export function TransformClasses_TransformClass__value_ZF56B0F4(this$, transforms) {
    const tupledArg = [this$.property_2, new String$(0, join(" ", map(MasterTypeHelpers_stringifyICssValue, transforms)))];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformClass__matrix_76A78260(this$, a, b, c, d, tx, ty) {
    return new Transform_Transform(0, a, b, c, d, tx, ty);
}

export function TransformClasses_TransformClass__matrix3D_Z4F721840(this$, a1, b1, c1, d1, a2, b2, c2, d2, a3, b3, c3, d3, a4, b4, c4, d4) {
    return new Transform_Transform(1, a1, b1, c1, d1, a2, b2, c2, d2, a3, b3, c3, d3, a4, b4, c4, d4);
}

export function TransformClasses_TransformClass__perspective_Z5C52D00C(this$, length) {
    return new Transform_Transform(2, length);
}

export function TransformClasses_TransformClass__rotate_CEE3389(this$, angle) {
    return new Transform_Transform(3, angle);
}

export function TransformClasses_TransformClass__rotate3D_25075E72(this$, x, y, z, a) {
    return new Transform_Transform(4, x, y, z, a);
}

export function TransformClasses_TransformClass__rotateX_CEE3389(this$, angle) {
    return new Transform_Transform(5, angle);
}

export function TransformClasses_TransformClass__rotateY_CEE3389(this$, angle) {
    return new Transform_Transform(6, angle);
}

export function TransformClasses_TransformClass__rotateZ_CEE3389(this$, angle) {
    return new Transform_Transform(7, angle);
}

export function TransformClasses_TransformClass__translate_Z498FEB3B(this$, length) {
    return new Transform_Transform(8, length);
}

export function TransformClasses_TransformClass__translate_3202B9A0(this$, a, b) {
    return new Transform_Transform(9, a, b);
}

export function TransformClasses_TransformClass__translate3D_Z3BD6069B(this$, a, b, c) {
    return new Transform_Transform(10, a, b, c);
}

export function TransformClasses_TransformClass__translateX_Z498FEB3B(this$, length) {
    return new Transform_Transform(11, length);
}

export function TransformClasses_TransformClass__translateY_Z498FEB3B(this$, length) {
    return new Transform_Transform(12, length);
}

export function TransformClasses_TransformClass__translateZ_Z498FEB3B(this$, length) {
    return new Transform_Transform(13, length);
}

export function TransformClasses_TransformClass__scale_5E38073B(this$, scale) {
    return new Transform_Transform(14, scale);
}

export function TransformClasses_TransformClass__scale_7B00E9A0(this$, a, b) {
    return new Transform_Transform(15, a, b);
}

export function TransformClasses_TransformClass__scale3D_Z7AD9E565(this$, a, b, c) {
    return new Transform_Transform(16, a, b, c);
}

export function TransformClasses_TransformClass__scaleX_5E38073B(this$, scale) {
    return new Transform_Transform(17, scale);
}

export function TransformClasses_TransformClass__scaleY_5E38073B(this$, scale) {
    return new Transform_Transform(18, scale);
}

export function TransformClasses_TransformClass__scaleZ_5E38073B(this$, scale) {
    return new Transform_Transform(19, scale);
}

export function TransformClasses_TransformClass__skew_CEE3389(this$, angle) {
    return new Transform_Transform(20, angle);
}

export function TransformClasses_TransformClass__skew_Z59A568E0(this$, a, b) {
    return new Transform_Transform(21, a, b);
}

export function TransformClasses_TransformClass__skewX_CEE3389(this$, angle) {
    return new Transform_Transform(22, angle);
}

export function TransformClasses_TransformClass__skewY_CEE3389(this$, angle) {
    return new Transform_Transform(23, angle);
}

export class TransformClasses_TransformOrigin extends unitHelpers_CssRuleWithLength {
    constructor(property) {
        super(property);
        this.property_2 = property;
    }
}

export function TransformClasses_TransformOrigin$reflection() {
    return class_type("Fss.Types.TransformClasses.TransformOrigin", void 0, TransformClasses_TransformOrigin, unitHelpers_CssRuleWithLength$reflection());
}

export function TransformClasses_TransformOrigin_$ctor_Z207A3CFB(property) {
    return new TransformClasses_TransformOrigin(property);
}

export function TransformClasses_TransformOrigin__value_3202B9A0(this$, x, y) {
    const tupledArg = [this$.property_2, new String$(0, `${unitHelpers_lengthPercentageString(x)} ${unitHelpers_lengthPercentageString(y)}`)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformOrigin__value_Z3BD6069B(this$, x, y, z) {
    const tupledArg = [this$.property_2, new String$(0, `${unitHelpers_lengthPercentageString(x)} ${unitHelpers_lengthPercentageString(y)} ${unitHelpers_lengthPercentageString(z)}`)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformOrigin__get_top(this$) {
    const tupledArg = [this$.property_2, new Transform_Origin(0)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformOrigin__get_left(this$) {
    const tupledArg = [this$.property_2, new Transform_Origin(1)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformOrigin__get_right(this$) {
    const tupledArg = [this$.property_2, new Transform_Origin(2)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformOrigin__get_bottom(this$) {
    const tupledArg = [this$.property_2, new Transform_Origin(3)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformOrigin__get_center(this$) {
    const tupledArg = [this$.property_2, new Transform_Origin(4)];
    return [tupledArg[0], tupledArg[1]];
}

export class TransformClasses_TransformStyle extends CssRule {
    constructor(property) {
        super(property);
        this.property_1 = property;
    }
}

export function TransformClasses_TransformStyle$reflection() {
    return class_type("Fss.Types.TransformClasses.TransformStyle", void 0, TransformClasses_TransformStyle, CssRule$reflection());
}

export function TransformClasses_TransformStyle_$ctor_Z207A3CFB(property) {
    return new TransformClasses_TransformStyle(property);
}

export function TransformClasses_TransformStyle__get_flat(this$) {
    const tupledArg = [this$.property_1, new Transform_Style(0)];
    return [tupledArg[0], tupledArg[1]];
}

export function TransformClasses_TransformStyle__get_preserve3d(this$) {
    const tupledArg = [this$.property_1, new Transform_Style(1)];
    return [tupledArg[0], tupledArg[1]];
}

