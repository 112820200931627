import { Union } from "../../fable-library.3.7.17/Types.js";
import { union_type } from "../../fable-library.3.7.17/Reflection.js";

export class Html extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["A", "All", "Abbr", "Acronym", "Address", "Applet", "Area", "Article", "Aside", "Audio", "B", "Base", "Basefont", "Bdo", "Big", "Blockquote", "Body", "Br", "Button", "Canvas", "Caption", "Center", "Cite", "Code", "Col", "Colgroup", "Datalist", "Dd", "Del", "Dfn", "Div", "Dl", "Dt", "Em", "Embed", "Fieldset", "Figcaption", "Figure", "Font", "Footer", "Form", "Frame", "Frameset", "Head", "Header", "H1", "H2", "H3", "H4", "H5", "H6", "Hr", "Html", "I", "Iframe", "Img", "Input", "Ins", "Kbd", "Label", "Legend", "Li", "Link", "Main", "Map", "Mark", "Meta", "Meter", "Nav", "Noscript", "Object", "Ol", "Optgroup", "Option", "P", "Param", "Pre", "Progress", "Q", "S", "Samp", "Script", "Section", "Select", "Small", "Source", "Span", "Strike", "Strong", "Style", "Sub", "Sup", "Table", "Tbody", "Td", "Textarea", "Tfoot", "Th", "Thead", "Time", "Title", "Tr", "U", "Ul", "Var", "Video", "Wbr"];
    }
}

export function Html$reflection() {
    return union_type("Fss.Types.Html.Html", [], Html, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function Html__Stringify(this$) {
    switch (this$.tag) {
        case 1: {
            return "*";
        }
        case 2: {
            return "abbr";
        }
        case 3: {
            return "acronym";
        }
        case 4: {
            return "address";
        }
        case 5: {
            return "applet";
        }
        case 6: {
            return "area";
        }
        case 7: {
            return "article";
        }
        case 8: {
            return "aside";
        }
        case 9: {
            return "audio";
        }
        case 10: {
            return "b";
        }
        case 11: {
            return "base";
        }
        case 12: {
            return "basefront";
        }
        case 13: {
            return "bdo";
        }
        case 14: {
            return "big";
        }
        case 15: {
            return "blockquote";
        }
        case 16: {
            return "body";
        }
        case 17: {
            return "br";
        }
        case 18: {
            return "button";
        }
        case 19: {
            return "canvas";
        }
        case 20: {
            return "caption";
        }
        case 21: {
            return "center";
        }
        case 22: {
            return "cite";
        }
        case 23: {
            return "code";
        }
        case 24: {
            return "col";
        }
        case 25: {
            return "colgroup";
        }
        case 26: {
            return "datalist";
        }
        case 27: {
            return "dd";
        }
        case 28: {
            return "del";
        }
        case 29: {
            return "dfn";
        }
        case 30: {
            return "div";
        }
        case 31: {
            return "dl";
        }
        case 32: {
            return "dt";
        }
        case 33: {
            return "em";
        }
        case 34: {
            return "embed";
        }
        case 35: {
            return "fieldset";
        }
        case 36: {
            return "figcaption";
        }
        case 37: {
            return "figure";
        }
        case 38: {
            return "font";
        }
        case 39: {
            return "footer";
        }
        case 40: {
            return "form";
        }
        case 41: {
            return "frame";
        }
        case 42: {
            return "frameset";
        }
        case 43: {
            return "head";
        }
        case 44: {
            return "header";
        }
        case 45: {
            return "h1";
        }
        case 46: {
            return "h2";
        }
        case 47: {
            return "h3";
        }
        case 48: {
            return "h4";
        }
        case 49: {
            return "h5";
        }
        case 50: {
            return "h6";
        }
        case 51: {
            return "hr";
        }
        case 52: {
            return "html";
        }
        case 53: {
            return "i";
        }
        case 54: {
            return "iframe";
        }
        case 55: {
            return "img";
        }
        case 56: {
            return "input";
        }
        case 57: {
            return "ins";
        }
        case 58: {
            return "kdb";
        }
        case 59: {
            return "label";
        }
        case 60: {
            return "legend";
        }
        case 61: {
            return "li";
        }
        case 62: {
            return "link";
        }
        case 63: {
            return "main";
        }
        case 64: {
            return "map";
        }
        case 65: {
            return "mark";
        }
        case 66: {
            return "meta";
        }
        case 67: {
            return "meter";
        }
        case 68: {
            return "nav";
        }
        case 69: {
            return "noscript";
        }
        case 70: {
            return "object";
        }
        case 71: {
            return "ol";
        }
        case 72: {
            return "optgroup";
        }
        case 73: {
            return "option";
        }
        case 74: {
            return "p";
        }
        case 75: {
            return "param";
        }
        case 76: {
            return "pre";
        }
        case 77: {
            return "progress";
        }
        case 78: {
            return "q";
        }
        case 79: {
            return "s";
        }
        case 80: {
            return "samp";
        }
        case 81: {
            return "script";
        }
        case 82: {
            return "section";
        }
        case 83: {
            return "select";
        }
        case 84: {
            return "small";
        }
        case 85: {
            return "source";
        }
        case 86: {
            return "span";
        }
        case 87: {
            return "strike";
        }
        case 88: {
            return "strong";
        }
        case 89: {
            return "style";
        }
        case 90: {
            return "sub";
        }
        case 91: {
            return "sup";
        }
        case 92: {
            return "table";
        }
        case 93: {
            return "tbody";
        }
        case 94: {
            return "td";
        }
        case 95: {
            return "textarea";
        }
        case 96: {
            return "tfoot";
        }
        case 97: {
            return "th";
        }
        case 98: {
            return "thead";
        }
        case 99: {
            return "time";
        }
        case 100: {
            return "title";
        }
        case 101: {
            return "tr";
        }
        case 102: {
            return "u";
        }
        case 103: {
            return "ul";
        }
        case 104: {
            return "var";
        }
        case 105: {
            return "video";
        }
        case 106: {
            return "wbr";
        }
        default: {
            return "a";
        }
    }
}

