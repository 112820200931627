import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { main } from "../../Styles/Theme.fs.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createElement } from "react";
import * as react from "react";
import { MainRouter } from "./Routing.fs.js";
import { SnackbarProvider } from "notistack";
import { Interop_reactApi } from "./fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function Root() {
    let elems_1, elements, children, props_2;
    return MuiHelpers_createElement(StylesProvider, [["injectFirst", true], (elems_1 = [MuiHelpers_createElement(ThemeProvider, [["theme", main], (elements = [(children = [MuiHelpers_createElement(CssBaseline, []), createElement(MainRouter, null)], (props_2 = {}, react.createElement(SnackbarProvider, props_2, ...children)))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
}

