import { Union } from "../../fable-library.3.7.17/Types.js";
import { class_type, union_type } from "../../fable-library.3.7.17/Reflection.js";
import { CssRuleWithNone$reflection, CssRuleWithNone } from "./MasterTypes.fs.js";

export class Display_Display extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Inline", "InlineBlock", "Block", "RunIn", "Flex", "Grid", "FlowRoot", "Table", "TableCell", "TableColumn", "TableColumnGroup", "TableHeaderGroup", "TableRowGroup", "TableFooterGroup", "TableRow", "TableCaption"];
    }
    StringifyCss() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "inline-block";
            }
            case 2: {
                return "block";
            }
            case 3: {
                return "run-in";
            }
            case 4: {
                return "flex";
            }
            case 5: {
                return "grid";
            }
            case 6: {
                return "flow-root";
            }
            case 7: {
                return "table";
            }
            case 8: {
                return "table-cell";
            }
            case 9: {
                return "table-column";
            }
            case 10: {
                return "table-column-group";
            }
            case 11: {
                return "table-header-group";
            }
            case 12: {
                return "table-row-group";
            }
            case 13: {
                return "table-footer-group";
            }
            case 14: {
                return "table-row";
            }
            case 15: {
                return "table-caption";
            }
            default: {
                return "inline";
            }
        }
    }
}

export function Display_Display$reflection() {
    return union_type("Fss.Types.Display.Display", [], Display_Display, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class DisplayClasses_DisplayClass extends CssRuleWithNone {
    constructor(property) {
        super(property);
        this.property_2 = property;
    }
}

export function DisplayClasses_DisplayClass$reflection() {
    return class_type("Fss.Types.DisplayClasses.DisplayClass", void 0, DisplayClasses_DisplayClass, CssRuleWithNone$reflection());
}

export function DisplayClasses_DisplayClass_$ctor_Z207A3CFB(property) {
    return new DisplayClasses_DisplayClass(property);
}

export function DisplayClasses_DisplayClass__value_Z5164641A(this$, display) {
    const tupledArg = [this$.property_2, display];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_inline$0027(this$) {
    const tupledArg = [this$.property_2, new Display_Display(0)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_inlineBlock(this$) {
    const tupledArg = [this$.property_2, new Display_Display(1)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_block(this$) {
    const tupledArg = [this$.property_2, new Display_Display(2)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_runIn(this$) {
    const tupledArg = [this$.property_2, new Display_Display(3)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_flex(this$) {
    const tupledArg = [this$.property_2, new Display_Display(4)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_grid(this$) {
    const tupledArg = [this$.property_2, new Display_Display(5)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_flowRoot(this$) {
    const tupledArg = [this$.property_2, new Display_Display(6)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_table(this$) {
    const tupledArg = [this$.property_2, new Display_Display(7)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableCell(this$) {
    const tupledArg = [this$.property_2, new Display_Display(8)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableColumn(this$) {
    const tupledArg = [this$.property_2, new Display_Display(9)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableColumnGroup(this$) {
    const tupledArg = [this$.property_2, new Display_Display(10)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableHeaderGroup(this$) {
    const tupledArg = [this$.property_2, new Display_Display(11)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableRowGroup(this$) {
    const tupledArg = [this$.property_2, new Display_Display(12)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableFooterGroup(this$) {
    const tupledArg = [this$.property_2, new Display_Display(13)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableRow(this$) {
    const tupledArg = [this$.property_2, new Display_Display(14)];
    return [tupledArg[0], tupledArg[1]];
}

export function DisplayClasses_DisplayClass__get_tableCaption(this$) {
    const tupledArg = [this$.property_2, new Display_Display(15)];
    return [tupledArg[0], tupledArg[1]];
}

