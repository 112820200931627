import { OverflowClasses_OverflowWrap_$ctor_Z207A3CFB, OverflowClasses_OverflowClass_$ctor_Z207A3CFB } from "../Types/Overflow.fs.js";
import { Property_CssProperty } from "../Types/MasterTypes.fs.js";

export const OverflowX = OverflowClasses_OverflowClass_$ctor_Z207A3CFB(new Property_CssProperty(198));

export const OverflowY = OverflowClasses_OverflowClass_$ctor_Z207A3CFB(new Property_CssProperty(199));

export const OverflowWrap = OverflowClasses_OverflowWrap_$ctor_Z207A3CFB(new Property_CssProperty(197));

export const Overflow = OverflowClasses_OverflowClass_$ctor_Z207A3CFB(new Property_CssProperty(196));

