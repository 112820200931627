import { useForm, useFormValidator, useFormField } from "../CustomHooks/ComposedForm.fs.js";
import { Customer2__get_CustomerOrCompanyName, CustomerCode as CustomerCode_2, CustomerCode$reflection, Customer2__get_Code, Customer2__get_Id, Customer2__get_Address, VatNumber___UnsafeUnwrap_18C04ACB, Customer2__get_PhoneNumber, PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_Email, Email___UnsafeUnwrap_Z27D11339, NonEmptyString___UnsafeUnwrap_Z73AD07C, CustomerCompanyData, CustomerData2, CustomerPersonData, TypeOfCustomerModule_string, TypeOfCustomer as TypeOfCustomer_2, KboNumber___UnsafeCreate_Z721C83C5, VatNumber___UnsafeCreate_Z721C83C5, NonEmptyString___UnsafeCreate_Z721C83C5, SelectOptionData$1_Create_Z510A3DC0, KboNumber_get_Create, VatNumber_get_Create, PhoneNumber_get_Create, Email_get_Create, NonEmptyString_get_Create } from "../shared/Domain.fs.js";
import { toArray, map, defaultArg, some } from "../client/src/fable_modules/fable-library.3.7.17/Option.js";
import { CreateAddressForm, useAddressForm } from "./AddressHook.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../CustomHooks/CustomHooks.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Row, Col } from "../Components/UtilComponents.fs.js";
import { alignEnd, cursorPointer, TableHeaderCell, flexExpand, MarginTop, Padding, TextSize, Gap } from "../Styles/Utils.fs.js";
import { empty, ofArray, singleton } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import { map as map_1, iterate, singleton as singleton_1, append, delay, toList } from "../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { printf, toConsole, isNullOrWhiteSpace, join } from "../client/src/fable_modules/fable-library.3.7.17/String.js";
import { MuiHelpers_createElement } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { TableHeader, FilterField, CheckBox, DisplayData, PartnerChip, EmailField, TextField, OptionField } from "./DesignSystem.fs.js";
import { Interop_reactApi } from "../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { VmeSocialToggles } from "./Contacts.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../client/src/fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import Paper from "@material-ui/core/Paper";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../client/src/fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { MaxWidth } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Union, Record } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { union_type, record_type, int32_type, option_type, string_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { CustomerSearchTerms } from "../shared/DomainHelpers.fs.js";
import { toOption } from "./String.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "./DisplayDeferred.fs.js";
import { Email_email, Address_address } from "../shared/Format.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

export function CreateCustomerForm(createCustomerFormInputProps) {
    let f1, f1_1, f1_2, f1_3, f1_4;
    const onBack = createCustomerFormInputProps.onBack;
    const isCibCustomer = createCustomerFormInputProps.isCibCustomer;
    const backButtonText = createCustomerFormInputProps.backButtonText;
    const saveButtonText = createCustomerFormInputProps.saveButtonText;
    const onCreateCustomer = createCustomerFormInputProps.onCreateCustomer;
    const companyName = useFormField("", NonEmptyString_get_Create());
    const companyType = useFormField("", (f1 = NonEmptyString_get_Create(), (arg_1) => some(f1(arg_1))));
    const firstName = useFormField("", NonEmptyString_get_Create());
    const lastName = useFormField("", NonEmptyString_get_Create());
    const companyFirstname = useFormField("", (f1_1 = NonEmptyString_get_Create(), (arg_3) => some(f1_1(arg_3))));
    const companyLastname = useFormField("", (f1_2 = NonEmptyString_get_Create(), (arg_5) => some(f1_2(arg_5))));
    const email = useFormField("", Email_get_Create());
    const phoneNumber = useFormField("", (f1_3 = PhoneNumber_get_Create(), (arg_7) => some(f1_3(arg_7))));
    const vatNumber = useFormField("", VatNumber_get_Create());
    const kboNumber = useFormField("", (f1_4 = KboNumber_get_Create(), (arg_9) => some(f1_4(arg_9))));
    const address = useAddressForm("", "", "", "", "");
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const saveButtonText_1 = defaultArg(saveButtonText, "Bewaren");
    const backButtonText_1 = defaultArg(backButtonText, "Annuleren");
    const isVme = useToggle(false);
    const isSocialHousingCompany = useToggle(false);
    const typeOfCustomer = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const personForm = useFormValidator(phoneNumber.Validator, (r_4, x_5) => ({
        Address: r_4.Address,
        CompanyFirstName: r_4.CompanyFirstName,
        CompanyLastName: r_4.CompanyLastName,
        CompanyName: r_4.CompanyName,
        Email: r_4.Email,
        FirstName: r_4.FirstName,
        IsSocialHousingCompany: r_4.IsSocialHousingCompany,
        IsVme: r_4.IsVme,
        KboNumber: r_4.KboNumber,
        LastName: r_4.LastName,
        PhoneNumber: x_5,
        TypeOfCustomer: r_4.TypeOfCustomer,
        VatNumber: r_4.VatNumber,
    }), useFormValidator(email.Validator, (r_3, x_4) => ({
        Address: r_3.Address,
        CompanyFirstName: r_3.CompanyFirstName,
        CompanyLastName: r_3.CompanyLastName,
        CompanyName: r_3.CompanyName,
        Email: x_4,
        FirstName: r_3.FirstName,
        IsSocialHousingCompany: r_3.IsSocialHousingCompany,
        IsVme: r_3.IsVme,
        KboNumber: r_3.KboNumber,
        LastName: r_3.LastName,
        TypeOfCustomer: r_3.TypeOfCustomer,
        VatNumber: r_3.VatNumber,
    }), useFormValidator(address.Validator, (r_2, x_3) => ({
        Address: x_3,
        CompanyFirstName: r_2.CompanyFirstName,
        CompanyLastName: r_2.CompanyLastName,
        CompanyName: r_2.CompanyName,
        FirstName: r_2.FirstName,
        IsSocialHousingCompany: r_2.IsSocialHousingCompany,
        IsVme: r_2.IsVme,
        KboNumber: r_2.KboNumber,
        LastName: r_2.LastName,
        TypeOfCustomer: r_2.TypeOfCustomer,
        VatNumber: r_2.VatNumber,
    }), useFormValidator(lastName.Validator, (r_1, x_2) => ({
        CompanyFirstName: r_1.CompanyFirstName,
        CompanyLastName: r_1.CompanyLastName,
        CompanyName: r_1.CompanyName,
        FirstName: r_1.FirstName,
        IsSocialHousingCompany: r_1.IsSocialHousingCompany,
        IsVme: r_1.IsVme,
        KboNumber: r_1.KboNumber,
        LastName: x_2,
        TypeOfCustomer: r_1.TypeOfCustomer,
        VatNumber: r_1.VatNumber,
    }), useFormValidator(firstName.Validator, (r, x_1) => ({
        CompanyFirstName: r.CompanyFirstName,
        CompanyLastName: r.CompanyLastName,
        CompanyName: r.CompanyName,
        FirstName: x_1,
        IsSocialHousingCompany: r.IsSocialHousingCompany,
        IsVme: r.IsVme,
        KboNumber: r.KboNumber,
        TypeOfCustomer: r.TypeOfCustomer,
        VatNumber: r.VatNumber,
    }), useFormValidator(address.Validator, (unitVar, x) => {
        const CompanyName = NonEmptyString___UnsafeCreate_Z721C83C5("");
        const VatNumber = VatNumber___UnsafeCreate_Z721C83C5("");
        return {
            CompanyFirstName: void 0,
            CompanyLastName: void 0,
            CompanyName: CompanyName,
            IsSocialHousingCompany: false,
            IsVme: false,
            KboNumber: KboNumber___UnsafeCreate_Z721C83C5(""),
            TypeOfCustomer: new TypeOfCustomer_2(0),
            VatNumber: VatNumber,
        };
    }, useForm()))))));
    const companyForm = useFormValidator(companyLastname.Validator, (r_12, x_14) => ({
        Address: r_12.Address,
        CompanyFirstName: r_12.CompanyFirstName,
        CompanyLastName: x_14,
        CompanyName: r_12.CompanyName,
        Email: r_12.Email,
        FirstName: r_12.FirstName,
        IsSocialHousingCompany: r_12.IsSocialHousingCompany,
        IsVme: r_12.IsVme,
        KboNumber: r_12.KboNumber,
        LastName: r_12.LastName,
        PhoneNumber: r_12.PhoneNumber,
        TypeOfCustomer: r_12.TypeOfCustomer,
        VatNumber: r_12.VatNumber,
    }), useFormValidator(companyFirstname.Validator, (r_11, x_13) => ({
        Address: r_11.Address,
        CompanyFirstName: x_13,
        CompanyName: r_11.CompanyName,
        Email: r_11.Email,
        FirstName: r_11.FirstName,
        IsSocialHousingCompany: r_11.IsSocialHousingCompany,
        IsVme: r_11.IsVme,
        KboNumber: r_11.KboNumber,
        LastName: r_11.LastName,
        PhoneNumber: r_11.PhoneNumber,
        TypeOfCustomer: r_11.TypeOfCustomer,
        VatNumber: r_11.VatNumber,
    }), useFormValidator(kboNumber.Validator, (r_10, x_12) => ({
        Address: r_10.Address,
        CompanyName: r_10.CompanyName,
        Email: r_10.Email,
        FirstName: r_10.FirstName,
        IsSocialHousingCompany: r_10.IsSocialHousingCompany,
        IsVme: r_10.IsVme,
        KboNumber: x_12,
        LastName: r_10.LastName,
        PhoneNumber: r_10.PhoneNumber,
        TypeOfCustomer: r_10.TypeOfCustomer,
        VatNumber: r_10.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_9, x_11) => ({
        Address: r_9.Address,
        CompanyName: r_9.CompanyName,
        Email: r_9.Email,
        FirstName: r_9.FirstName,
        IsSocialHousingCompany: r_9.IsSocialHousingCompany,
        IsVme: r_9.IsVme,
        LastName: r_9.LastName,
        PhoneNumber: r_9.PhoneNumber,
        TypeOfCustomer: r_9.TypeOfCustomer,
        VatNumber: x_11,
    }), useFormValidator(phoneNumber.Validator, (r_8, x_10) => ({
        Address: r_8.Address,
        CompanyName: r_8.CompanyName,
        Email: r_8.Email,
        FirstName: r_8.FirstName,
        IsSocialHousingCompany: r_8.IsSocialHousingCompany,
        IsVme: r_8.IsVme,
        LastName: r_8.LastName,
        PhoneNumber: x_10,
        TypeOfCustomer: r_8.TypeOfCustomer,
    }), useFormValidator(email.Validator, (r_7, x_9) => ({
        Address: r_7.Address,
        CompanyName: r_7.CompanyName,
        Email: x_9,
        FirstName: r_7.FirstName,
        IsSocialHousingCompany: r_7.IsSocialHousingCompany,
        IsVme: r_7.IsVme,
        LastName: r_7.LastName,
        TypeOfCustomer: r_7.TypeOfCustomer,
    }), useFormValidator(address.Validator, (r_6, x_8) => ({
        Address: x_8,
        CompanyName: r_6.CompanyName,
        FirstName: r_6.FirstName,
        IsSocialHousingCompany: r_6.IsSocialHousingCompany,
        IsVme: r_6.IsVme,
        LastName: r_6.LastName,
        TypeOfCustomer: r_6.TypeOfCustomer,
    }), useFormValidator(companyName.Validator, (r_5, x_7) => ({
        CompanyName: x_7,
        FirstName: r_5.FirstName,
        IsSocialHousingCompany: isSocialHousingCompany.State,
        IsVme: isVme.State,
        LastName: r_5.LastName,
        TypeOfCustomer: r_5.TypeOfCustomer,
    }), useFormValidator(address.Validator, (unitVar_1, x_6) => ({
        FirstName: NonEmptyString___UnsafeCreate_Z721C83C5(""),
        LastName: NonEmptyString___UnsafeCreate_Z721C83C5(""),
        TypeOfCustomer: new TypeOfCustomer_2(1),
    }), useForm())))))))));
    const form = useFormValidator(typeOfCustomer.Validator, (unitVar_2, x_15) => {
        if (x_15.Value.tag === 1) {
            return map((n_1) => n_1, companyForm.Validate());
        }
        else {
            return map((n) => n, personForm.Validate());
        }
    }, useForm());
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_1[1];
    const disable = patternInput_1[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => append(singleton_1(createElement("div", {
            className: join(" ", [TextSize.M, Padding.Xxs]),
            children: "Klant aanmaken",
        })), delay(() => {
            let elements;
            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(typeOfCustomer, new TypeOfCustomer_2(0), TypeOfCustomerModule_string(new TypeOfCustomer_2(0)), "", true), OptionField(typeOfCustomer, new TypeOfCustomer_2(1), TypeOfCustomerModule_string(new TypeOfCustomer_2(1)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
                let matchValue_2, typeOfCustomer_1;
                return append((matchValue_2 = typeOfCustomer.FieldValue, (matchValue_2 == null) ? singleton_1(null) : ((typeOfCustomer_1 = matchValue_2, (typeOfCustomer_1.tag === 1) ? append(singleton_1(createElement(VmeSocialToggles, {
                    vme: isVme,
                    social: isSocialHousingCompany,
                    disabled: false,
                    companyTypeField: companyType,
                })), delay(() => append(singleton_1(TextField(vatNumber, "BTW", "", false)), delay(() => append(singleton_1(TextField(companyName, "Bedrijfsnaam", "", true)), delay(() => append(singleton_1(TextField(companyType, "Bedrijfstype", "", false)), delay(() => append(singleton_1(EmailField(email, "Email adres", "", true)), delay(() => singleton_1(TextField(phoneNumber, "Telefoon", "", false)))))))))))) : append(singleton_1(TextField(firstName, "Voornaam", "", true)), delay(() => append(singleton_1(TextField(lastName, "Naam", "", true)), delay(() => append(singleton_1(EmailField(email, "Email adres", "", true)), delay(() => singleton_1(TextField(phoneNumber, "Telefoon", "", false))))))))))), delay(() => append(singleton_1(createElement(CreateAddressForm, {
                    addressForm: address,
                    heading: void 0,
                    disabled: false,
                })), delay(() => singleton_1(createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.L]),
                    children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                        onBack();
                    }], ["children", Interop_reactApi.Children.toArray([backButtonText_1])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                        iterate((data) => {
                            iterate((data_1) => {
                                const customerData = (data_1.TypeOfCustomer.tag === 0) ? (new CustomerData2(0, new CustomerPersonData(data_1.LastName, data_1.FirstName, data_1.Address, data_1.Email, data_1.PhoneNumber, isCibCustomer))) : (new CustomerData2(1, new CustomerCompanyData(data_1.CompanyName, void 0, data_1.Address, data_1.Email, data_1.PhoneNumber, data_1.VatNumber, isCibCustomer, data_1.IsVme, data_1.IsSocialHousingCompany, data_1.CompanyLastName, data_1.CompanyFirstName)));
                                console.log(data_1);
                                setDisable(true);
                                onCreateCustomer(customerData)(() => {
                                    setDisable(false);
                                });
                            }, toArray(data));
                        }, toArray(form.Validate()));
                    }], ["children", Interop_reactApi.Children.toArray([saveButtonText_1])]])]),
                }))))));
            }));
        })))),
    });
}

export function EditCustomerForm(editCustomerFormInputProps) {
    let f1, f1_1, f1_2, f1_3, f1_4, f1_5;
    const url = editCustomerFormInputProps.url;
    const onEditCustomer = editCustomerFormInputProps.onEditCustomer;
    const customer = editCustomerFormInputProps.customer;
    const companyName = useFormField((customer.tag === 1) ? customer.fields[0].CompanyName.Value : "", NonEmptyString_get_Create());
    const companyType = useFormField((customer.tag === 1) ? defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, customer.fields[0].CompanyType), "") : "", (f1 = NonEmptyString_get_Create(), (arg_2) => some(f1(arg_2))));
    const firstName = useFormField((customer.tag === 1) ? "" : customer.fields[0].FirstName.Value, NonEmptyString_get_Create());
    const lastName = useFormField((customer.tag === 1) ? "" : customer.fields[0].LastName.Value, NonEmptyString_get_Create());
    const email = useFormField(Email___UnsafeUnwrap_Z27D11339(Customer2__get_Email(customer)), Email_get_Create());
    const phoneNumber = useFormField(defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber(customer)), ""), (f1_1 = PhoneNumber_get_Create(), (arg_6) => some(f1_1(arg_6))));
    const companyFirstname = useFormField("", (f1_2 = NonEmptyString_get_Create(), (arg_8) => some(f1_2(arg_8))));
    const companyLastname = useFormField("", (f1_3 = NonEmptyString_get_Create(), (arg_10) => some(f1_3(arg_10))));
    let patternInput_1;
    let patternInput;
    if (customer.tag === 1) {
        const company_2 = customer.fields[0];
        patternInput = [VatNumber___UnsafeUnwrap_18C04ACB(company_2.VatNumber), defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company_2.FirstName), ""), defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company_2.LastName), "")];
    }
    else {
        patternInput = ["", "", ""];
    }
    patternInput_1 = [useFormField(patternInput[0], VatNumber_get_Create()), useFormField(patternInput[1], (f1_4 = NonEmptyString_get_Create(), (arg_15) => some(f1_4(arg_15)))), useFormField(patternInput[2], (f1_5 = NonEmptyString_get_Create(), (arg_17) => some(f1_5(arg_17))))];
    const vatNumber = patternInput_1[0];
    const isVme = useToggle((customer.tag === 1) && customer.fields[0].IsVME);
    const isSocialHousingCompany = useToggle((customer.tag === 1) && customer.fields[0].IsSocialHousingCompany);
    let bus_1;
    const matchValue = Customer2__get_Address(customer).Bus;
    bus_1 = ((matchValue == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue));
    const address = useAddressForm(NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).Street), NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).Number), bus_1, NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).PostalCode), NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).City));
    const typeOfCustomer = useFormField((customer.tag === 1) ? (new TypeOfCustomer_2(1)) : (new TypeOfCustomer_2(0)), SelectOptionData$1_Create_Z510A3DC0);
    const personForm = useFormValidator(phoneNumber.Validator, (r_4, x_5) => ({
        Address: r_4.Address,
        CompanyFirstName: r_4.CompanyFirstName,
        CompanyLastName: r_4.CompanyLastName,
        CompanyName: r_4.CompanyName,
        CompanyType: r_4.CompanyType,
        Email: r_4.Email,
        FirstName: r_4.FirstName,
        IsSocialHousingCompany: r_4.IsSocialHousingCompany,
        IsVme: r_4.IsVme,
        LastName: r_4.LastName,
        PhoneNumber: x_5,
        TypeOfCustomer: r_4.TypeOfCustomer,
        VatNumber: r_4.VatNumber,
    }), useFormValidator(email.Validator, (r_3, x_4) => ({
        Address: r_3.Address,
        CompanyFirstName: r_3.CompanyFirstName,
        CompanyLastName: r_3.CompanyLastName,
        CompanyName: r_3.CompanyName,
        CompanyType: r_3.CompanyType,
        Email: x_4,
        FirstName: r_3.FirstName,
        IsSocialHousingCompany: r_3.IsSocialHousingCompany,
        IsVme: r_3.IsVme,
        LastName: r_3.LastName,
        TypeOfCustomer: r_3.TypeOfCustomer,
        VatNumber: r_3.VatNumber,
    }), useFormValidator(address.Validator, (r_2, x_3) => ({
        Address: x_3,
        CompanyFirstName: r_2.CompanyFirstName,
        CompanyLastName: r_2.CompanyLastName,
        CompanyName: r_2.CompanyName,
        CompanyType: r_2.CompanyType,
        FirstName: r_2.FirstName,
        IsSocialHousingCompany: r_2.IsSocialHousingCompany,
        IsVme: r_2.IsVme,
        LastName: r_2.LastName,
        TypeOfCustomer: r_2.TypeOfCustomer,
        VatNumber: r_2.VatNumber,
    }), useFormValidator(lastName.Validator, (r_1, x_2) => ({
        CompanyFirstName: r_1.CompanyFirstName,
        CompanyLastName: r_1.CompanyLastName,
        CompanyName: r_1.CompanyName,
        CompanyType: r_1.CompanyType,
        FirstName: r_1.FirstName,
        IsSocialHousingCompany: r_1.IsSocialHousingCompany,
        IsVme: r_1.IsVme,
        LastName: x_2,
        TypeOfCustomer: r_1.TypeOfCustomer,
        VatNumber: r_1.VatNumber,
    }), useFormValidator(firstName.Validator, (r, x_1) => ({
        CompanyFirstName: r.CompanyFirstName,
        CompanyLastName: r.CompanyLastName,
        CompanyName: r.CompanyName,
        CompanyType: r.CompanyType,
        FirstName: x_1,
        IsSocialHousingCompany: r.IsSocialHousingCompany,
        IsVme: r.IsVme,
        TypeOfCustomer: r.TypeOfCustomer,
        VatNumber: r.VatNumber,
    }), useFormValidator(address.Validator, (unitVar, x) => ({
        CompanyFirstName: void 0,
        CompanyLastName: void 0,
        CompanyName: NonEmptyString___UnsafeCreate_Z721C83C5(""),
        CompanyType: void 0,
        IsSocialHousingCompany: false,
        IsVme: false,
        TypeOfCustomer: new TypeOfCustomer_2(0),
        VatNumber: VatNumber___UnsafeCreate_Z721C83C5(""),
    }), useForm()))))));
    const companyForm = useFormValidator(patternInput_1[2].Validator, (r_12, x_14) => ({
        Address: r_12.Address,
        CompanyFirstName: r_12.CompanyFirstName,
        CompanyLastName: x_14,
        CompanyName: r_12.CompanyName,
        CompanyType: r_12.CompanyType,
        Email: r_12.Email,
        FirstName: r_12.FirstName,
        IsSocialHousingCompany: r_12.IsSocialHousingCompany,
        IsVme: r_12.IsVme,
        LastName: r_12.LastName,
        PhoneNumber: r_12.PhoneNumber,
        TypeOfCustomer: r_12.TypeOfCustomer,
        VatNumber: r_12.VatNumber,
    }), useFormValidator(patternInput_1[1].Validator, (r_11, x_13) => ({
        Address: r_11.Address,
        CompanyFirstName: x_13,
        CompanyName: r_11.CompanyName,
        CompanyType: r_11.CompanyType,
        Email: r_11.Email,
        FirstName: r_11.FirstName,
        IsSocialHousingCompany: r_11.IsSocialHousingCompany,
        IsVme: r_11.IsVme,
        LastName: r_11.LastName,
        PhoneNumber: r_11.PhoneNumber,
        TypeOfCustomer: r_11.TypeOfCustomer,
        VatNumber: r_11.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_10, x_12) => ({
        Address: r_10.Address,
        CompanyName: r_10.CompanyName,
        CompanyType: r_10.CompanyType,
        Email: r_10.Email,
        FirstName: r_10.FirstName,
        IsSocialHousingCompany: r_10.IsSocialHousingCompany,
        IsVme: r_10.IsVme,
        LastName: r_10.LastName,
        PhoneNumber: r_10.PhoneNumber,
        TypeOfCustomer: r_10.TypeOfCustomer,
        VatNumber: x_12,
    }), useFormValidator(phoneNumber.Validator, (r_9, x_11) => ({
        Address: r_9.Address,
        CompanyName: r_9.CompanyName,
        CompanyType: r_9.CompanyType,
        Email: r_9.Email,
        FirstName: r_9.FirstName,
        IsSocialHousingCompany: r_9.IsSocialHousingCompany,
        IsVme: r_9.IsVme,
        LastName: r_9.LastName,
        PhoneNumber: x_11,
        TypeOfCustomer: r_9.TypeOfCustomer,
    }), useFormValidator(email.Validator, (r_8, x_10) => ({
        Address: r_8.Address,
        CompanyName: r_8.CompanyName,
        CompanyType: r_8.CompanyType,
        Email: x_10,
        FirstName: r_8.FirstName,
        IsSocialHousingCompany: r_8.IsSocialHousingCompany,
        IsVme: r_8.IsVme,
        LastName: r_8.LastName,
        TypeOfCustomer: r_8.TypeOfCustomer,
    }), useFormValidator(address.Validator, (r_7, x_9) => ({
        Address: x_9,
        CompanyName: r_7.CompanyName,
        CompanyType: r_7.CompanyType,
        FirstName: r_7.FirstName,
        IsSocialHousingCompany: r_7.IsSocialHousingCompany,
        IsVme: r_7.IsVme,
        LastName: r_7.LastName,
        TypeOfCustomer: r_7.TypeOfCustomer,
    }), useFormValidator(companyType.Validator, (r_6, x_8) => ({
        CompanyName: r_6.CompanyName,
        CompanyType: x_8,
        FirstName: r_6.FirstName,
        IsSocialHousingCompany: r_6.IsSocialHousingCompany,
        IsVme: r_6.IsVme,
        LastName: r_6.LastName,
        TypeOfCustomer: r_6.TypeOfCustomer,
    }), useFormValidator(companyName.Validator, (r_5, x_7) => ({
        CompanyName: x_7,
        FirstName: r_5.FirstName,
        IsSocialHousingCompany: isSocialHousingCompany.State,
        IsVme: isVme.State,
        LastName: r_5.LastName,
        TypeOfCustomer: r_5.TypeOfCustomer,
    }), useFormValidator(address.Validator, (unitVar_1, x_6) => ({
        FirstName: NonEmptyString___UnsafeCreate_Z721C83C5(""),
        LastName: NonEmptyString___UnsafeCreate_Z721C83C5(""),
        TypeOfCustomer: new TypeOfCustomer_2(1),
    }), useForm())))))))));
    const form = useFormValidator(typeOfCustomer.Validator, (unitVar_2, x_15) => {
        if (x_15.Value.tag === 1) {
            return map((n_1) => n_1, companyForm.Validate());
        }
        else {
            return map((n) => n, personForm.Validate());
        }
    }, useForm());
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_2[1];
    const disable = patternInput_2[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => {
            let elements;
            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(typeOfCustomer, new TypeOfCustomer_2(0), TypeOfCustomerModule_string(new TypeOfCustomer_2(0)), "", true), OptionField(typeOfCustomer, new TypeOfCustomer_2(1), TypeOfCustomerModule_string(new TypeOfCustomer_2(1)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
                let matchValue_3, typeOfCustomer_1;
                return append((matchValue_3 = typeOfCustomer.FieldValue, (matchValue_3 == null) ? singleton_1(null) : ((typeOfCustomer_1 = matchValue_3, (typeOfCustomer_1.tag === 1) ? append(singleton_1(createElement(VmeSocialToggles, {
                    vme: isVme,
                    social: isSocialHousingCompany,
                    disabled: false,
                    companyTypeField: companyType,
                })), delay(() => append(singleton_1(TextField(vatNumber, "BTW", "", false)), delay(() => append(singleton_1(TextField(companyName, "Bedrijfsnaam", "", true)), delay(() => append(singleton_1(TextField(companyType, "Bedrijfstype", "", false)), delay(() => append(singleton_1(EmailField(email, "Email adres", "", true)), delay(() => singleton_1(TextField(phoneNumber, "Telefoon", "", false)))))))))))) : append(singleton_1(TextField(firstName, "Voornaam", "", true)), delay(() => append(singleton_1(TextField(lastName, "Naam", "", true)), delay(() => append(singleton_1(EmailField(email, "Email adres", "", true)), delay(() => singleton_1(TextField(phoneNumber, "Telefoon", "", false))))))))))), delay(() => append(singleton_1(createElement(CreateAddressForm, {
                    addressForm: address,
                    heading: void 0,
                    disabled: false,
                })), delay(() => singleton_1(createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.L]),
                    children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                        RouterModule_nav(ofArray(url), 1, 1);
                    }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                        iterate((data) => {
                            iterate((data_1) => {
                                setDisable(true);
                                console.log(data_1);
                                const customerData = (data_1.TypeOfCustomer.tag === 0) ? (new CustomerData2(0, new CustomerPersonData(data_1.LastName, data_1.FirstName, data_1.Address, data_1.Email, data_1.PhoneNumber, false))) : (new CustomerData2(1, new CustomerCompanyData(data_1.CompanyName, data_1.CompanyType, data_1.Address, data_1.Email, data_1.PhoneNumber, data_1.VatNumber, false, data_1.IsVme, data_1.IsSocialHousingCompany, data_1.CompanyLastName, data_1.CompanyFirstName)));
                                onEditCustomer(() => {
                                    setDisable(false);
                                })(Customer2__get_Id(customer))(customerData);
                            }, toArray(data));
                        }, toArray(form.Validate()));
                    }], ["children", Interop_reactApi.Children.toArray(["Bewaren"])]])]),
                }))))));
            }));
        })),
    });
}

export function DisplayCustomerInfo(displayCustomerInfoInputProps) {
    const onPartnerClick = displayCustomerInfoInputProps.onPartnerClick;
    const partner = displayCustomerInfoInputProps.partner;
    const customer = displayCustomerInfoInputProps.customer;
    const xs = toList(delay(() => {
        let matchValue, partner_1;
        return append((matchValue = partner, (matchValue == null) ? singleton_1(null) : ((partner_1 = matchValue, append(singleton_1(createElement(PartnerChip, {
            partner: partner_1,
            onClick: onPartnerClick,
        })), delay(() => singleton_1(createElement(Row, {
            classes: singleton(MarginTop.M),
            children: empty(),
        }))))))), delay(() => {
            let elems;
            return singleton_1(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MaxWidth, px(900)))), (elems = [createElement(Row, {
                classes: empty(),
                children: ofArray([createElement(Col, {
                    classes: singleton(flexExpand),
                    children: singleton(createElement(DisplayData, {
                        data: toList(delay(() => append(singleton_1(["Klant nummer", Customer2__get_Code(customer).Value]), delay(() => {
                            const matchValue_1 = customer;
                            if (matchValue_1.tag === 1) {
                                const company = matchValue_1.fields[0];
                                return append(singleton_1(["Klant type", "Rechtspersoon"]), delay(() => append(singleton_1(["BTW", VatNumber___UnsafeUnwrap_18C04ACB(company.VatNumber)]), delay(() => append(singleton_1(["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(company.CompanyName)]), delay(() => append(singleton_1(["Bedrijfstype", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.CompanyType), "--")]), delay(() => append(singleton_1(["Email", Email___UnsafeUnwrap_Z27D11339(company.Email)]), delay(() => append(singleton_1(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, company.PhoneNumber), "--")]), delay(() => append(singleton_1(["VME", CheckBox(company.IsVME)]), delay(() => singleton_1(["Sociale huisvestigingsmaatschappij", CheckBox(company.IsSocialHousingCompany)])))))))))))))));
                            }
                            else {
                                const person = matchValue_1.fields[0];
                                return append(singleton_1(["Klant type", "Natuurlijk persoon"]), delay(() => append(singleton_1(["Voornaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person.FirstName)]), delay(() => append(singleton_1(["Naam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person.LastName)]), delay(() => append(singleton_1(["Email", Email___UnsafeUnwrap_Z27D11339(Customer2__get_Email(customer))]), delay(() => singleton_1(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber(customer)), "--")])))))))));
                            }
                        })))),
                    })),
                }), createElement(Col, {
                    classes: singleton(flexExpand),
                    children: singleton(createElement(DisplayData, {
                        data: ofArray([["Straat", NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).Street)], ["Nummer", NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).Number)], ["Bus", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, Customer2__get_Address(customer).Bus), "--")], ["Postcode", NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).PostalCode)], ["Gemeente", NonEmptyString___UnsafeUnwrap_Z73AD07C(Customer2__get_Address(customer).City)]]),
                    })),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs);
}

export function CopyCustomerAddress(copyCustomerAddressInputProps) {
    const label = copyCustomerAddressInputProps.label;
    const setIsSameAddress = copyCustomerAddressInputProps.setIsSameAddress;
    const isSameAddress = copyCustomerAddressInputProps.isSameAddress;
    const target = copyCustomerAddressInputProps.target;
    const source = copyCustomerAddressInputProps.source;
    return MuiHelpers_createElement(FormControlLabel, [["label", label], ["control", MuiHelpers_createElement(Checkbox, [["checked", isSameAddress]])], ["onChange", (e) => {
        const s = Browser_Types_Event__Event_get_Checked(e);
        setIsSameAddress(s);
        if (s === true) {
            target.StreetField.SetFieldValue(source.Street.Value);
            target.NumberField.SetFieldValue(source.Number.Value);
            target.BusField.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, source.Bus), ""));
            target.PostalCodeField.SetFieldValue(source.PostalCode.Value);
            target.City.SetFieldValue(source.City.Value);
        }
        else {
            target.StreetField.SetFieldValue("");
            target.NumberField.SetFieldValue("");
            target.BusField.SetFieldValue("");
            target.PostalCodeField.SetFieldValue("");
            target.City.SetFieldValue("");
        }
    }]]);
}

export class CustomerFilter_State extends Record {
    constructor(Name, Address, PhoneNumber, Email, CustomerCode, PageNumber, PageSize) {
        super();
        this.Name = Name;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.Email = Email;
        this.CustomerCode = CustomerCode;
        this.PageNumber = (PageNumber | 0);
        this.PageSize = (PageSize | 0);
    }
}

export function CustomerFilter_State$reflection() {
    return record_type("CustomerForms.CustomerFilter.State", [], CustomerFilter_State, () => [["Name", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["Email", option_type(string_type)], ["CustomerCode", option_type(CustomerCode$reflection())], ["PageNumber", int32_type], ["PageSize", int32_type]]);
}

export function CustomerFilter_searchTerms(state) {
    return new CustomerSearchTerms(state.CustomerCode, state.Name, state.Address, state.PhoneNumber, state.Email);
}

export class CustomerFilter_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetCustomerCode", "SetAddress", "SetPhoneNumber", "SetEmail", "SetPageNumber"];
    }
}

export function CustomerFilter_Msg$reflection() {
    return union_type("CustomerForms.CustomerFilter.Msg", [], CustomerFilter_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]]]);
}

export const CustomerFilter_initialState = new CustomerFilter_State(void 0, void 0, void 0, void 0, void 0, 0, 25);

export function CustomerFilter_update(state, _arg) {
    switch (_arg.tag) {
        case 1: {
            const customerCode = _arg.fields[0];
            if (isNullOrWhiteSpace(customerCode)) {
                return new CustomerFilter_State(state.Name, state.Address, state.PhoneNumber, state.Email, void 0, state.PageNumber, state.PageSize);
            }
            else {
                return new CustomerFilter_State(state.Name, state.Address, state.PhoneNumber, state.Email, new CustomerCode_2(customerCode), state.PageNumber, state.PageSize);
            }
        }
        case 2: {
            return new CustomerFilter_State(state.Name, toOption(_arg.fields[0]), state.PhoneNumber, state.Email, state.CustomerCode, state.PageNumber, state.PageSize);
        }
        case 3: {
            return new CustomerFilter_State(state.Name, state.Address, toOption(_arg.fields[0]), state.Email, state.CustomerCode, state.PageNumber, state.PageSize);
        }
        case 4: {
            return new CustomerFilter_State(state.Name, state.Address, state.PhoneNumber, toOption(_arg.fields[0]), state.CustomerCode, state.PageNumber, state.PageSize);
        }
        case 5: {
            return new CustomerFilter_State(state.Name, state.Address, state.PhoneNumber, state.Email, state.CustomerCode, _arg.fields[0], state.PageSize);
        }
        default: {
            return new CustomerFilter_State(toOption(_arg.fields[0]), state.Address, state.PhoneNumber, state.Email, state.CustomerCode, state.PageNumber, state.PageSize);
        }
    }
}

export function CustomersData(customersDataInputProps) {
    let elems_15, elems_14, children_6, children_5, children, children_1, children_2, children_3, children_4, children_13;
    const url = customersDataInputProps.url;
    const dispatch = customersDataInputProps.dispatch;
    const state = customersDataInputProps.state;
    const data = customersDataInputProps.data;
    const xs_2 = [MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_15 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_14 = [(children_6 = singleton((children_5 = ofArray([(children = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_1) => {
            dispatch(new CustomerFilter_Msg(1, arg_1));
        }, defaultArg(map((d) => d.Value, state.CustomerCode), "")), createElement(TableHeader, {
            text: "Klant code",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_3) => {
            dispatch(new CustomerFilter_Msg(0, arg_3));
        }, defaultArg(state.Name, "")), createElement(TableHeader, {
            text: "Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_5) => {
            dispatch(new CustomerFilter_Msg(2, arg_5));
        }, defaultArg(state.Address, "")), createElement(TableHeader, {
            text: "Adres",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_7) => {
            dispatch(new CustomerFilter_Msg(3, arg_7));
        }, defaultArg(state.PhoneNumber, "")), createElement(TableHeader, {
            text: "Tel",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_4 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_9) => {
            dispatch(new CustomerFilter_Msg(4, arg_9));
        }, defaultArg(state.Email, "")), createElement(TableHeader, {
            text: "Email",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]])), (children_13 = singleton(createElement(DisplayDeferred, {
        data: data,
        view: (customers) => {
            const xs_1 = toList(delay(() => map_1((customer) => {
                let elems_12, children_7, children_8, children_9, children_10, children_11;
                return MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
                    const arg_10 = Customer2__get_Code(customer);
                    toConsole(printf("customer code %A"))(arg_10);
                    RouterModule_nav(ofArray(url(Customer2__get_Code(customer))), 1, 1);
                }], ["key", Customer2__get_Id(customer).Value], (elems_12 = [(children_7 = singleton(Customer2__get_Code(customer).Value), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]])), (children_8 = singleton(Customer2__get_CustomerOrCompanyName(customer)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]])), (children_9 = singleton(Address_address(Customer2__get_Address(customer))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]])), (children_10 = toList(delay(() => {
                    const matchValue = Customer2__get_PhoneNumber(customer);
                    return (matchValue == null) ? singleton_1("-") : singleton_1(PhoneNumber___UnsafeUnwrap_23E45174(matchValue));
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]])), (children_11 = singleton(Email_email(Customer2__get_Email(customer))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]);
            }, customers.Data)));
            return react.createElement(react.Fragment, {}, ...xs_1);
        },
    })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]), createElement(Col, {
        classes: empty(),
        children: singleton(createElement(DisplayDeferred, {
            data: data,
            view: (customers_1) => {
                let values;
                return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", state.PageSize], ["page", state.PageNumber], ["count", customers_1.TotalPages], ["onChangePage", (_arg_1, v) => {
                    dispatch(new CustomerFilter_Msg(5, v));
                }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_45) => {
                }]]);
            },
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

