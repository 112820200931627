import { Union } from "./fable_modules/fable-library.3.7.17/Types.js";
import { union_type, int32_type, string_type } from "./fable_modules/fable-library.3.7.17/Reflection.js";
import { Match_routes, R_get_Int, R_get_String, R__param_Z2F4DFF34, R$1__page_6CB7246, R_create, R__segment_Z721C83C5, R__page_1505 } from "../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.7.17/List.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Login", "Dashboard", "Customers", "CustomerDetail", "CustomerEdit", "CustomerCreate", "Partners", "PartnersDetail", "PartnersEdit", "PartnersCreate", "Adis", "AdiDetail", "AdiEdit", "AdiCreate", "Dossiers", "MyDossiers", "DossierDetail", "DossierEdit", "DossierCreate", "Availability", "CreateAppointment", "CreateAppointmentDossier", "EditAppointmentDossier", "PlaceDescriptionAppointments", "CreatePlaceDescriptionAppointment", "PlaceDescriptionAppointmentDetail", "Appointments", "AppointmentDetail", "AppointmentConfirm", "AttestenFinaliseren", "Beschikbaarheden", "DigitalSignaturePage", "AdminTools", "Syndics", "SyndicsDetail", "SyndicsEdit", "SyndicsCreate", "QuoteCreate", "Quotes", "QuoteDetail", "QuoteEdit"];
    }
}

export function Page$reflection() {
    return union_type("AppRoutes.Page", [], Page, () => [[], [], [], [["code", string_type]], [["code", string_type]], [], [], [["id", int32_type]], [["id", int32_type]], [], [], [["id", int32_type]], [["id", int32_type]], [], [], [], [["code", string_type]], [["code", string_type]], [], [], [], [["dossierCode", string_type]], [["id", int32_type]], [], [], [["id", int32_type]], [], [["id", int32_type]], [["id", int32_type]], [], [], [["code", string_type]], [], [], [["id", int32_type]], [["id", int32_type]], [], [], [], [["code", string_type]], [["code", string_type]]]);
}

export const login = R__page_1505(R__segment_Z721C83C5(R_create(), "login"), new Page(0));

export const dashboard = R__page_1505(R_create(), new Page(1));

export const customers = R__page_1505(R__segment_Z721C83C5(R_create(), "klanten"), new Page(2));

export const customerDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "klanten"), "detail"), R_get_String()), (arg) => (new Page(3, arg)));

export const customerEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "klanten"), "bewerken"), R_get_String()), (arg) => (new Page(4, arg)));

export const customerCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "klanten"), "aanmaken"), new Page(5));

export const partners = R__page_1505(R__segment_Z721C83C5(R_create(), "partners"), new Page(6));

export const partnersDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "partners"), "detail"), R_get_Int()), (arg) => (new Page(7, arg)));

export const partnersEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "partners"), "bewerken"), R_get_Int()), (arg) => (new Page(8, arg)));

export const partnersCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "partners"), "aanmaken"), new Page(9));

export const syndics = R__page_1505(R__segment_Z721C83C5(R_create(), "syndics"), new Page(33));

export const syndicsDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "syndics"), "detail"), R_get_Int()), (arg) => (new Page(34, arg)));

export const syndicsEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "syndics"), "bewerken"), R_get_Int()), (arg) => (new Page(35, arg)));

export const syndicsCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "syndics"), "aanmaken"), new Page(36));

export const adis = R__page_1505(R__segment_Z721C83C5(R_create(), "adi"), new Page(10));

export const adiCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "adi"), "aanmaken"), new Page(13));

export const adiDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "adi"), "detail"), R_get_Int()), (arg) => (new Page(11, arg)));

export const adiEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "adi"), "bewerken"), R_get_Int()), (arg) => (new Page(12, arg)));

export const dossiers = R__page_1505(R__segment_Z721C83C5(R_create(), "dossiers"), new Page(14));

export const myDossiers = R__page_1505(R__segment_Z721C83C5(R_create(), "mydossiers"), new Page(15));

export const dossierCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "dossiers"), "aanmaken"), new Page(18));

export const dossierDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "dossiers"), "detail"), R_get_String()), (arg) => (new Page(16, arg)));

export const dossierEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "dossiers"), "bewerken"), R_get_String()), (arg) => (new Page(17, arg)));

export const availability = R__page_1505(R__segment_Z721C83C5(R_create(), "beschikbaarheid"), new Page(19));

export const createAppointment = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "afspraken"), "aanmaken"), new Page(20));

export const appointments = R__page_1505(R__segment_Z721C83C5(R_create(), "afspraken"), new Page(26));

export const dossierAppointmentCreate = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "afspraken"), "aanmaken"), R_get_String()), (arg) => (new Page(21, arg)));

export const dossierAppointmentEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "afspraken"), "bewerken"), R_get_Int()), (arg) => (new Page(22, arg)));

export const appointmentDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "afspraken"), "detail"), R_get_Int()), (arg) => (new Page(27, arg)));

export const appointmentConfirm = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "afspraken"), "bevestig"), R_get_Int()), (arg) => (new Page(28, arg)));

export const createPlaceDescriptionAppointment = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "plaatsbeschrijving"), "aanmaken"), new Page(24));

export const placeDescriptionAppointments = R__page_1505(R__segment_Z721C83C5(R_create(), "plaatsbeschrijving"), new Page(23));

export const placeDescriptionAppointmentDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "plaatsbeschrijving"), "detail"), R_get_Int()), (arg) => (new Page(25, arg)));

export const attestenFinaliseren = R__page_1505(R__segment_Z721C83C5(R_create(), "attesten_finaliseren"), new Page(29));

export const beschikbaarheden = R__page_1505(R__segment_Z721C83C5(R_create(), "beschikbaarheden"), new Page(30));

export const digitalSignaturePage = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R_create(), "sign"), R_get_String()), (arg) => (new Page(31, arg)));

export const adminTools = R__page_1505(R__segment_Z721C83C5(R_create(), "admin"), new Page(32));

export const quotes = R__page_1505(R__segment_Z721C83C5(R_create(), "quotes"), new Page(38));

export const quoteCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "quotes"), "aanmaken"), new Page(37));

export const quoteDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "quotes"), "detail"), R_get_String()), (arg) => (new Page(39, arg)));

export const quoteEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "quotes"), "bewerken"), R_get_String()), (arg) => (new Page(40, arg)));

export function mainRouter(url) {
    return Match_routes(ofArray([login, digitalSignaturePage]), url);
}

export function appRouter(url) {
    return Match_routes(ofArray([dashboard, customers, customerDetail, customerEdit, customerCreate, partners, partnersDetail, partnersEdit, partnersCreate, adis, adiDetail, adiEdit, adiCreate, dossiers, myDossiers, dossierDetail, dossierEdit, dossierCreate, availability, createAppointment, appointmentDetail, appointmentConfirm, dossierAppointmentCreate, dossierAppointmentEdit, appointments, createPlaceDescriptionAppointment, placeDescriptionAppointments, placeDescriptionAppointmentDetail, attestenFinaliseren, beschikbaarheden, digitalSignaturePage, adminTools, syndics, syndicsDetail, syndicsEdit, syndicsCreate, quotes, quoteCreate, quoteDetail, quoteEdit]), url);
}

