import { indexed, choose, toList, singleton, empty, enumerateWhile, append, enumerateUsing, delay, toArray, allPairs, map, collect } from "../../fable-library.3.7.17/Seq.js";
import { addRangeInPlace, foldBack as foldBack_1 } from "../../fable-library.3.7.17/Array.js";
import { clear, comparePrimitives, equals, getEnumerator } from "../../fable-library.3.7.17/Util.js";
import { exists, item as item_1, filter, length, map as map_1, max, isEmpty } from "../../fable-library.3.7.17/List.js";
import { FindSliceIndex_arrayImpl } from "../Internals.fs.js";

export function bind(mapping, source) {
    return collect(mapping, source);
}

export function apply(f, x) {
    return bind((f_1) => map(f_1, x), f);
}

export function lift2(f, x1, x2) {
    return map((tupledArg) => f(tupledArg[0], tupledArg[1]), allPairs(x1, x2));
}

export function lift3(f, x1, x2, x3) {
    return map((tupledArg) => f(tupledArg[0], tupledArg[1], tupledArg[2]), map((x) => [x[1][0], x[1][1], x[0]], allPairs(x1, allPairs(x2, x3))));
}

export function foldBack(folder, source, state) {
    return foldBack_1(folder, toArray(source), state);
}

export function chunkBy(projection, source) {
    return delay(() => enumerateUsing(getEnumerator(source), (e) => {
        if (e["System.Collections.IEnumerator.MoveNext"]()) {
            let g = projection(e["System.Collections.Generic.IEnumerator`1.get_Current"]());
            let members = [];
            void (members.push(e["System.Collections.Generic.IEnumerator`1.get_Current"]()));
            return append(enumerateWhile(() => e["System.Collections.IEnumerator.MoveNext"](), delay(() => {
                const key = projection(e["System.Collections.Generic.IEnumerator`1.get_Current"]());
                if (equals(g, key)) {
                    void (members.push(e["System.Collections.Generic.IEnumerator`1.get_Current"]()));
                    return empty();
                }
                else {
                    return append(singleton([g, members]), delay(() => {
                        g = key;
                        members = [];
                        void (members.push(e["System.Collections.Generic.IEnumerator`1.get_Current"]()));
                        return empty();
                    }));
                }
            })), delay(() => singleton([g, members])));
        }
        else {
            return empty();
        }
    }));
}

export function intersperse(sep, list) {
    return delay(() => {
        let notFirst = false;
        return collect((element) => append(notFirst ? singleton(sep) : empty(), delay(() => append(singleton(element), delay(() => {
            notFirst = true;
            return empty();
        })))), list);
    });
}

export function intercalate(separator, source) {
    return delay(() => {
        let notFirst = false;
        return collect((element) => append(notFirst ? separator : empty(), delay(() => append(element, delay(() => {
            notFirst = true;
            return empty();
        })))), source);
    });
}

export function split(separators, source) {
    const options = 0;
    return delay(() => {
        const matchValue = toList(map(toList, separators));
        if (isEmpty(matchValue)) {
            return singleton(source);
        }
        else {
            const separators_1 = matchValue;
            const buffer = [];
            let candidate;
            const arg = max(map_1(length, separators_1), {
                Compare: comparePrimitives,
            }) | 0;
            candidate = [];
            let i = 0;
            return append(collect((item) => {
                void (candidate.push(item));
                const matchValue_1 = filter((sep) => {
                    if (length(sep) > i) {
                        return equals(item, item_1(i, sep));
                    }
                    else {
                        return false;
                    }
                }, separators_1);
                if (isEmpty(matchValue_1)) {
                    i = 0;
                    addRangeInPlace(candidate, buffer);
                    clear(candidate);
                    return empty();
                }
                else if (exists((sep_1) => (length(sep_1) === (i + 1)), matchValue_1)) {
                    i = 0;
                    return append(((options === 0) ? true : (buffer.length > 0)) ? singleton(buffer.slice()) : empty(), delay(() => {
                        clear(buffer);
                        clear(candidate);
                        return empty();
                    }));
                }
                else {
                    i = ((i + 1) | 0);
                    return empty();
                }
            }, source), delay(() => append((candidate.length > 0) ? ((addRangeInPlace(candidate, buffer), empty())) : empty(), delay(() => (((options === 0) ? true : (buffer.length > 0)) ? singleton(buffer) : empty())))));
        }
    });
}

export function replace(oldValue, newValue, source) {
    return delay(() => {
        const old = toList(oldValue);
        if (length(old) === 0) {
            return source;
        }
        else {
            const candidate = [];
            let sindex = 0;
            return append(collect((item) => {
                void (candidate.push(item));
                if (equals(item, item_1(sindex, old))) {
                    sindex = ((sindex + 1) | 0);
                    if (sindex >= length(old)) {
                        sindex = 0;
                        return append(newValue, delay(() => {
                            clear(candidate);
                            return empty();
                        }));
                    }
                    else {
                        return empty();
                    }
                }
                else {
                    sindex = 0;
                    return append(candidate, delay(() => {
                        clear(candidate);
                        return empty();
                    }));
                }
            }, source), delay(() => candidate));
        }
    });
}

export function drop(count, source) {
    return delay(() => {
        let i = count;
        return collect((x) => {
            if (i > 0) {
                i = ((i - 1) | 0);
                return empty();
            }
            else {
                return singleton(x);
            }
        }, source);
    });
}

export function findSliceIndex(slice, source) {
    const index = FindSliceIndex_arrayImpl(toArray(slice), toArray(source)) | 0;
    if (index === -1) {
        const exn = new Error("The specified slice was not found in the sequence.");
        throw exn;
    }
    else {
        return index | 0;
    }
}

export function tryFindSliceIndex(slice, source) {
    const index = FindSliceIndex_arrayImpl(toArray(slice), toArray(source)) | 0;
    if (index === -1) {
        return void 0;
    }
    else {
        return index;
    }
}

export function choosei(mapping, source) {
    return choose((tupledArg) => mapping(tupledArg[0], tupledArg[1]), indexed(source));
}

